<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
          <!-- <div class="pb-1" v-if="tutor"><span>Tutor time zone : {{tutor.timezone}}.You are booking as per {{tutor.timezone}} time.</span></div> -->
          <div class="card">
            <div class="card-body" v-if="package_type == 'hourly-package'">
              <div v-for="index in numberOfDays" :key="index">
                <div class="form-group form-primary">
                  <label for="birthday">Select Day</label>
                  <input type="date" v-model="schedules[index-1].startDay" @input="addDaySchedule(index)" id="day" :min="start_date" :max='end_date' name="day" class="form-control" :disabled="disable_flag">
                  <div v-if="disabled_flag=='active'+(index-1)" style="color:#ff5252;">{{disable_msg}}</div>
                </div>
                <div class="form-group form-primary">
                  <label for="cars" class="mr-2">Select Timing</label>
                  <div class="row">
                    <div class="col-lg-2">
                      <input class="mb-2" type="time" id="time" name="time" v-model="selectedTime.start" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
                      <div style="color:red;font-size:10px;" v-if="isTimeIncluded">{{time_error}}</div>
                    </div>
                    <div class="col-lg-1">
                      <div v-on:click.prevent="addScheduleTime(index)" class="btn btn-outline-light daysadd mb-2" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;">+</div>
                    </div>
                  </div>
                  <div class="row" style="margin-left:2px;" v-if="schedules.length&&schedules[0].timing.length&&schedules[0].timing[0].start&&schedules[0].timing[0].end">
                    <div class="col-lg-2" style="font-size: 14px;border: 1px solid #cfced0;border-radius: 5px;" v-for="(time,t_index) in schedules[index-1].timing" v-bind:key="t_index"><div v-if="time.start&&time.end">{{ time.start }} - {{ time.end }} <span v-on:click.prevent="removeTime(index,t_index)">x</span></div></div>
                  </div>
                </div>
              </div>
              <div class="form-group form-primary">
                <a class="btn btn-outline-light daysadd" v-on:click.prevent="numberOfDays=numberOfDays+1">+ Add more days</a>
              </div>
            </div>
            <div class="card-body" v-if="package_type == 'monthly-package'">
              <div class="form-group form-primary">
                <label for="month">Select Date</label>
                <input type="date" v-model="toBook.start_date" id="day" @input="submitMonthPrice"  :min="start_date" :max='end_date' name="day" class="form-control" :disabled="disable_flag">
                <div v-if="disabled_flag" style="color:#ff5252;">{{disable_msg}}</div>
              </div>
              <div class="form-group form-primary">
                <label for="time">Select Timing</label>
                <input type="time" id="time" name="time" v-model="toBook.time_from" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
                <input type="time" id="time" name="time" v-model="toBook.time_to" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
              </div>
            </div>
            <div class="card-body" v-if="package_type == 'yearly-package'">
              <div class="form-group form-primary">
                <label for="month">Month</label>
                <input type="date" v-model="toBook.start_date" id="day" @input="submitYearPrice"  :min="start_date" :max='end_date' name="day" class="form-control" :disabled="disable_flag">
              </div>
              <div class="form-group form-primary">
                <label for="cars">Select Timing</label>
                <input type="time" id="time" name="time" v-model="toBook.time_from" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
                <input type="time" id="time" name="time" v-model="toBook.time_to" style="border: solid #9e9e9e 0.5px;padding: 13px;border-radius: 3px;" required>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="card">
            <div class="card-body">
              <table class="table">
                <tbody>
                  <tr v-if="package_type == 'hourly-package'">
                    <td>Total Selected Hours</td>
                    <td>{{charges.total_hr}} hrs</td>
                  </tr>
                  <tr v-if="package_type == 'monthly-package'">
                    <td>Total Selected Months</td>
                    <td>{{charges.total_month}} month</td>
                  </tr>
                  <tr>
                    <td>Fee amount</td>
                    <td>$ {{charges.fee_amount}}</td>
                  </tr>
                  <tr>
                    <td>Website Service Charge</td>
                    <td>$ {{charges.service_fee}}</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td>$ {{charges.tax}}</td>
                  </tr>
                  <tr>
                    <td><h4>Total Payable</h4></td>
                    <td><h4>$ <span id="total_payable">{{charges.total_payable}}</span></h4></td>
                  </tr>
                </tbody>
              </table>
              <div class="terms">
                <v-checkbox class="mt-0" v-model="terms"></v-checkbox><span>I agree the <a href="/terms">Terms & Conditions and giving permission to send email to my mail</a></span>
              </div>
              <a v-on:click="bookClass" class="btn btn-success pay text-white" v-if="!booking&&terms">Pay Now</a>
              <div class="error-feedback" v-if="error">{{err_msg}}</div>
              <div id="paypal-button-container" v-show="booking && !error"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  var moment = require('moment-timezone');
  export default {
    data(){
      return {
        schedules:[{startDay:null,endDay:null,timing:[]}],
        months:[
          {value:'1', text:'January'},
          {value:'2', text:'February'},
          {value:'3', text:'March'},
          {value:'4', text:'April'},
          {value:'5', text:'May'},
          {value:'6', text:'June'},
          {value:'7', text:'July'},
          {value:'8', text:'August'},
          {value:'9', text:'September'},
          {value:'10', text:'October'},
          {value:'11', text:'November'},
          {value:'12', text:'December'}
        ],
        default_package_time:{value:'08:00 AM - 09:00 AM',text:'8AM - 9AM'},
        sheduled_time:[],
        package_type:null,
        terms:true,
        days:[],
        timing:[],
        packages:[],
        package_format:{
          day:null,
          time:'08:00 AM - 09:00 AM',
          month:null,
        },
        numberOfDays:1,
        numberOfTimes:1,
        price:null,
        charges:{
          total_hr:0,
          total_month:0,
          fee_amount:0,
          service_fee:0,
          tax:0,
          total_payable:0,
          schedule: [],
        },
        schedule:[],
        loaded:false,
        paidFor:false,
        booking:false,
        error:true,
        err_msg:'',
        tutor:null,
        scheduled_months:[],
        start_date:null,
        end_date:null,
        disable_flag:false,
        disabled_flag:false,
        disable_msg:'',
        upcomingClasses:[],
        toBook:{
          start_date:null,
          end_date:null,
          package:null,
          time:null
        },
        static_days:[
          {value:1,text:'Sunday'},
          {value:2,text:'Monday'},
          {value:3,text:'Tuesday'},
          {value:4,text:'Wedensday'},
          {value:5,text:'Thursday'},
          {value:6,text:'Friday'},
          {value:7,text:'Saturday'},
        ],
        local_time:[],
        student:null,
        time_error:'',
        isTimeIncluded:false,
        selectedTime:{start:null,end:null}
      }
    },
    beforeMount(){
      window.scrollTo(0, 0);
      this.package_type = this.$route.path.split('/')[2];
      if(this.package_type == 'hourly-package'){
        this.price = this.$route.query.hour_price;
        this.toBook.package = 1;
      }
      if(this.package_type == 'monthly-package'){
        this.price = this.$route.query.month_price;
        this.toBook.package = 2;
      }
      if(this.package_type == 'yearly-package'){
        this.price = this.$route.query.hour_price;
        this.toBook.package = 3;
        // this.months.forEach(month => {
        //   if(parseInt(month.value) >= (new Date().getMonth()+1)){
        //     this.scheduled_months.push(month)
        //   }
        // })
      }
      var tutor_id = this.$route.query.tutor_id;
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/'+tutor_id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.message == 'User found'){
          this.tutor = res.data.data;
          this.setMonthSchedule();
          if(this.tutor.timing){
            let current_date = new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate();
            // let start_date = (this.tutor.timing.year?new Date().getFullYear():new Date().getFullYear()+1)+'-'+((this.tutor.timing.start_month>9)?this.tutor.timing.start_month:'0'+this.tutor.timing.start_month)+'-01';
            // let end_date = (this.tutor.timing.year=="1"?new Date().getFullYear():new Date().getFullYear()+1)+'-'+((this.tutor.timing.end_month>9)?this.tutor.timing.end_month:'0'+this.tutor.timing.end_month)+'-01';
            let start_date = this.tutor.timing.year?(this.tutor.timing.year+'-'+((this.tutor.timing.start_month>9)?this.tutor.timing.start_month:'0'+this.tutor.timing.start_month)+'-01'):null;
            let end_date = this.tutor.timing.year?(this.tutor.timing.year+'-'+((this.tutor.timing.end_month>9)?this.tutor.timing.end_month:'0'+this.tutor.timing.end_month)+'-01'):null;
            if(Date.parse(start_date)<Date.parse(current_date)<Date.parse(end_date)){
              this.start_date = new Date().toISOString().split('T')[0]
            }else if(Date.parse(start_date)>Date.parse(current_date)){
              this.start_date = this.tutor.timing.year?(this.tutor.timing.year+'-'+((this.tutor.timing.start_month>9)?this.tutor.timing.start_month:'0'+this.tutor.timing.start_month)+'-01'):null;
            }else{
              this.disable_flag = true;
              this.disabled_flag = 'active'+0;
              this.disable_msg = 'Date expired'
            }
            
            // this.end_date= (this.tutor.timing.year=="1"?new Date().getFullYear():new Date().getFullYear()+1)+'-'+((this.tutor.timing.end_month>9)?this.tutor.timing.end_month:'0'+this.tutor.timing.end_month)+'-'+(new Date((this.tutor.timing.year=="1"?new Date().getFullYear():new Date().getFullYear()+1),this.tutor.timing.end_month,0).getDate());
            this.end_date = this.tutor.timing.year?this.tutor.timing.year+'-'+((this.tutor.timing.end_month>9)?this.tutor.timing.end_month:'0'+this.tutor.timing.end_month)+'-'+(new Date(this.tutor.timing.year,this.tutor.timing.end_month,0).getDate()):null;
            console.log('end-date',end_date);
          }
        }
      });
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor_classes/'+tutor_id, {headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.message === 'Upcomming class retrived sucessfully'){
          this.upcomingClasses = res.data.data;
        }
      });
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-student').token}}).then(res => {
        if(res.data.message == 'Student retrieved successfully'){
          this.student = res.data.data.student;
          console.log(this.student);
        }
      });
    },
    mounted(){
      const script = document.createElement('script');
      script.src="https://www.paypal.com/sdk/js?client-id="+process.env.VUE_APP_CLIENT_ID;
      script.addEventListener("load", () => this.setLoaded());
      document.body.appendChild(script);
    },
    methods:{
      addMoreDays(e){
        e.preventDefault();
      },
      getLocalTime(index){
        this.local_time = '';
        let time = this.timing[index-1];
        let date = this.days[index-1];
        
        time.forEach(t => {
          // var local_time = '';
          let start_time_24 = this.get24Hour(t.split("-")[0]);
          let end_time_24 = this.get24Hour(t.split("-")[1]);
          let start_local_time = moment.tz(date+' '+ start_time_24, this.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm');
          let end_local_time = moment.tz(date+' '+ end_time_24, this.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm');
          let start_time_12 = this.tConvert(start_local_time); 
          let end_time_12 = this.tConvert(end_local_time)
          this.local_time += start_time_12+'-'+end_time_12;
        })
        return (date.split('-').reverse().join('-')+'/'+this.local_time);
      },
      tConvert(time) {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join ('');
      },
      get24Hour(time){
        time = time.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')
        var hours = Number(time.match(/^(\d+)/)[1]);
        var minutes = Number(time.match(/:(\d+)/)[1]);
        var AMPM = time.match(/\s(.*)$/)[1];
        if(AMPM == "PM " && hours<12) hours = hours+12;
        if(AMPM == "AM " && hours==12) hours = hours-12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if(hours<10) sHours = "0" + sHours;
        if(minutes<10) sMinutes = "0" + sMinutes;
        return sHours + ":" + sMinutes;
      },
      setTimeSchedule(index){
        this.schedules[index] = [];
        if(this.tutor.timing){
          if(this.tutor.timing.start_time&&this.tutor.timing.end_time){
            var flag = true;
            var start_time = [parseInt(this.tutor.timing.start_time.split(' ')[0]),this.tutor.timing.start_time.split(' ')[1]];
            var end_time = [parseInt(this.tutor.timing.end_time.split(' ')[0]),this.tutor.timing.end_time.split(' ')[1]];
            var break_start = this.tutor.timing.break_start_time?[parseInt(this.tutor.timing.break_start_time.split(' ')[0]),this.tutor.timing.break_start_time.split(' ')[1]]:0;
            var break_end = this.tutor.timing.break_end_time?[parseInt(this.tutor.timing.break_end_time.split(' ')[0]),this.tutor.timing.break_end_time.split(' ')[1]]:0;
            var time_diff = ((start_time[1] == end_time[1])?end_time[0]-start_time[0]:(start_time[1] == 'AM' && end_time[1] == 'PM')?((end_time[0]+12)-start_time[0]):((start_time[0]+12)-end_time[0]));
            var j = start_time[0] ;
            this.schedules[index] = [];
            for(var i = 0; i<=time_diff-1; i++,j++){
              if((start_time[1] == end_time[1]) && flag == true){
                flag = (break_start?(((j!=12?(j+1):1) == break_start[0])?false:true):true);
                if(j == 12){
                  this.schedules[index].push({value:j+':00 '+start_time[1]+' - '+'01:00 '+start_time[1],text:j+start_time[1]+' - '+1+start_time[1]});
                  j = 0;
                }else if(j == 11){
                  this.schedules[index].push({value:j+':00 '+start_time[1]+' - '+(j+1)+':00 '+end_time[1],text:j+start_time[1]+' - '+(j+1)+end_time[1]});
                }else if(9 < j < 11){
                  this.schedules[index].push({value:j+':00 '+start_time[1]+' - '+(j+1)+':00 '+start_time[1],text:j+start_time[1]+' - '+(j+1)+start_time[1]});
                }
                else{ 
                  this.schedules[index].push({value:'0'+j+':00'+start_time[1]+' - '+'0'+(j+1)+':00 '+start_time[1],text:j+start_time[1]+' - '+(j+1)+start_time[1]})
                }
              }else if(flag== true){
                flag = (break_start?(((j!=12?(j+1):1) == break_start[0])?false:true):true);
                if(j == 12){
                  start_time[1] = end_time[1];
                  this.schedules[index].push({value:j+':00 '+start_time[1]+' - '+'01:00 '+start_time[1],text:j+start_time[1]+' - '+1+start_time[1]});
                  j = 0;
                }else if(j == 11){
                  this.schedules[index].push({value:j+':00 '+start_time[1]+' - '+(j+1)+':00 '+end_time[1],text:j+start_time[1]+' - '+(j+1)+end_time[1]});
                }else if(9 < j < 11){
                  this.schedules[index].push({value:j+':00 '+start_time[1]+' - '+(j+1)+':00 '+start_time[1],text:j+start_time[1]+' - '+(j+1)+start_time[1]});
                }else{
                  this.schedules[index].push({value:'0'+j+':00'+start_time[1]+' - '+'0'+(j+1)+':00 '+start_time[1],text:j+start_time[1]+' - '+(j+1)+start_time[1]})
                }
              }else{
                flag = (break_end?(((j!=12?(j+1):1) == break_end[0])?true:false):true);
                if(j==12){
                  j=0;
                  start_time[1] = end_time[1];
                }
              }
            }
            if(this.schedules[index].length != 0){
              this.schedules[index].forEach((schedule,i) => {
                let s_time = (schedule.value.split(' - ')[0].split(' ')[1] == 'PM'?((parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0])==12)?(parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0])):(parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0]))+12):((parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0]))==12)?(parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0])+12):(parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0])))
                if(this.upcomingClasses.length > 0){
                  this.upcomingClasses.forEach(u_class => {
                    if(u_class.start_date == u_class.end_date){
                      if(this.days[index] == u_class.start_date){
                        if(u_class.start_time == s_time+':00:00'){
                          this.schedules[index].splice(i, 1);
                        }
                      }
                    }else{
                      if((parseInt(u_class.start_date.split("-")[1])+1 == parseInt(u_class.end_date.split('-')[1])) || (parseInt(u_class.start_date.split("-")[1]) == 12 && parseInt(u_class.end_date.split("-")[1]) == 1)){
                        if((this.days[index].split('-')[1] == u_class.start_date.split("-")[1])||(this.days[index].split('-')[1] == u_class.end_date.split("-")[1])){
                          if((this.days[index].split('-')[1] == u_class.start_date.split("-")[1]) && (this.days[index].split('-')[2]>=u_class.start_date.split("-")[2])){
                            if(u_class.start_time == s_time+':00:00'){
                              this.schedules[index].splice(i, 1);
                            }
                          }else if((this.days[index].split('-')[1] == u_class.end_date.split("-")[1]) && (this.days[index].split('-')[2]<=u_class.end_date.split("-")[2])){
                            if(u_class.start_time == s_time+':00:00'){
                              this.schedules[index].splice(i, 1);
                            }
                          }
                        }
                      }else if(parseInt(u_class.start_date.split("-")[0])+1 == parseInt(u_class.end_date.split('-')[0])){
                        if((this.days[index].split("-")[0] == u_class.start_date.split('-')[0])||(this.days[index].split("-")[0] == u_class.end_date.split('-')[0])){
                          if((parseInt(this.days[index].split('-')[1]) >= parseInt(u_class.start_date.split("-")[1])) ||((parseInt(this.days[index].split('-')[1]) == parseInt(u_class.start_date.split("-")[1])) && (this.days[index].split('-')[2]>=u_class.start_date.split("-")[2]))){
                            if(u_class.start_time == s_time+':00:00'){
                              this.schedules[index].splice(i, 1);
                            }
                          }else if((parseInt(this.days[index].split('-')[1]) <= parseInt(u_class.end_date.split("-")[1])) ||((parseInt(this.days[index].split('-')[1]) == parseInt(u_class.end_date.split("-")[1])) && (this.days[index].split('-')[2]<=u_class.end_date.split("-")[2]))){
                            if(u_class.start_time == s_time+':00:00'){
                              this.schedules[index].splice(i, 1);
                            }
                          }
                        }
                      }
                    }
                  });
                }
              });
              if(this.schedules[index].length == 0){
                this.schedules[index] = [];
                this.disabled_flag = 'active'+index;
                this.disable_msg = 'Not available in this date';
              }else{
                this.schedules[index].forEach((schedule,i) => {
                  let s_time = (schedule.value.split(' - ')[0].split(' ')[1] == 'PM'?((parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0])==12)?(parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0])):(parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0]))+12):((parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0]))==12)?(parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0])+12):(parseInt(schedule.value.split(' - ')[0].split(' ')[0].split(':')[0])));
                  let start_timezone = moment.tz(this.days[index]+' '+(s_time.toString().length === 1?('0'+s_time):s_time)+':00:00',this.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
                  let end_timezone = moment.tz(this.days[index]+' '+(((s_time!=24?s_time+1:1).toString().length === 1?('0'+(s_time!=24?s_time+1:1)):(s_time!=24?s_time+1:1)))+':00:00', this.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
                  let start_date = start_timezone.format('YYYY-MM-DD');
                  let start_time = start_timezone.format('HH:mm');
                  // let end_date = end_timezone.format('YYYY-MM-DD');
                  let end_time = end_timezone.format('HH:mm');
                  if(start_date == this.days[index]){
                    let time = this.tConvert(start_time)+' - '+this.tConvert(end_time);
                    schedule.text = time;
                  }else{
                    this.schedules[index].splice(i,1);
                  }
                });
              }
            }else{
              this.schedules[index] = [];
              this.disabled_flag = 'active'+index;
              this.disable_msg = 'Not available in this date';
            }
          }
        }else{
          this.schedules[index] = [];
          this.disabled_flag = 'active'+index;
          this.disable_msg = 'Not available in this date';
        }
      },
      checkTime(day,index) {
        this.disable_msg = '';
        this.disabled_flag = false;
        if(day == null || day == undefined){
          this.disable_msg = 'Date is empty';
          this.disabled_flag = 'active'+(index-1);
        }else{
          this.isTimeIncluded = false;
          this.selectedTime.end = this.selectedTime.start;
          this.isTimeIncluded = this.timing.some((period) => {
            const startTime = new Date(`${day}T${period.start}`);
            const endTime = new Date(`${day}T${period.end}`);
            const startCurrentTime = new Date(`${day}T${this.selectedTime.start}`);
            const endCurrentTime = new Date(`${day}T${this.selectedTime.end}`);
            if(startTime <= startCurrentTime <= endTime || startTime <= endCurrentTime <= endTime){
              return true;
            }
          });
        }
      },
      removeTime(index,t_index){
        this.schedules[index-1].timing.splice(t_index,1);
        this.setScheduleCalculation(index);
      },
      // setScheduledTime(time,index){        
      //   if((this.tutor.timing.start_day <= (new Date(time).getDay()+1)) && ((new Date(time).getDay()+1) <= this.tutor.timing.end_day)){
      //     console.log(new Date(),new Date(time));

      //   }
      // },
      addScheduleTime(index){
        this.disable_msg = '';
        this.disabled_flag = false;
        this.isTimeIncluded = false;
        if(this.schedules[index-1].startDay == null || this.schedules[index-1].startDay == undefined){
          this.disable_msg = 'Date is empty';
          this.disabled_flag = 'active'+(index-1);
        }else{
          this.time_error=false;
          if(this.selectedTime.start != null){
            if(this.isSameDate(new Date(this.schedules[index-1].startDay) ,new Date()) && this.selectedTime.start){
              const [inputHour, inputMinute] = this.selectedTime.start.split(':');
              if(new Date().getHours() < inputHour || (new Date().getHours() == inputHour && new Date().getMinutes() < inputMinute)){
                this.checkBooking(index)
              }else{
                this.isTimeIncluded = true;
                this.time_error = 'Time is expired';
              }
            }else{
              this.checkBooking(index);
            }
          }else{
            this.isTimeIncluded = true;
            this.time_error = 'Time is empty';
          }
        }
      },
      isSameDate(date1, date2) {
        return (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        );
      },
      checkBooking(index){
        this.isTimeIncluded = false;
        var date = new Date(this.schedules[index-1].startDay);
        var timeParts= this.selectedTime.start.split(":");
        date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]));
        date.setHours(date.getHours() + 1);
        var updatedHours = date.getHours();
        var updatedMinutes = date.getMinutes();
        this.selectedTime.end = ("0" + updatedHours).slice(-2) + ":" + ("0" + updatedMinutes).slice(-2);

        this.isTimeIncluded = this.timing.some((period) => {
          const startTime = new Date(`${this.schedules[index-1].startDay}T${period.start}`);
          const endTime = new Date(`${this.schedules[index-1].startDay}T${period.end}`);
          const startCurrentTime = new Date(`${this.schedules[index-1].startDay}T${this.selectedTime.start}`);
          const endCurrentTime = new Date(`${this.schedules[index-1].startDay}T${this.selectedTime.end}`);
          if((startCurrentTime >= startTime && startCurrentTime <= endTime) || (endCurrentTime >= startTime && endCurrentTime <= endTime)){
            return true;
          }
        });
        
        if(this.isTimeIncluded == true){
          this.isTimeIncluded = true;
          this.time_error = 'Time already booked';
        }else{
          var student_token = this.$cookies.get('tution-student').token;
          const timezone = Intl.DateTimeFormat(this.student.country.sortname).resolvedOptions().timeZone;
          let isCheck = {
            'tutor':this.tutor.tutor_id,
            'start':this.selectedTime.start,
            'end':this.selectedTime.end,
            'date':this.schedules[index-1].startDay,
            'tutor_timezone':this.tutor.timezone,
            'student_zone':timezone         
          }
          axios.post(process.env.VUE_APP_TUTION_SITE_API+'/check_time',isCheck,{headers:{"Authorization":'Bearer '+student_token}}).then(res => {
            if(res.data.status == true){
              this.schedules[index-1].timing.push(this.selectedTime);
              this.setScheduleCalculation(index);
              this.selectedTime = {start:null,end:null};
            }else{
              this.isTimeIncluded = true;
              this.time_error = res.data.error;
            }
          });
        }
      },
      removenumberOfTimes(){
        this.timing.pop();
        this.numberOfTimes = this.numberOfTimes-1;
      },
      setMonthSchedule(){
        if(this.tutor.timing){
          if(this.tutor.timing.start_month && this.tutor.timing.end_month){
            for(var i =this.tutor.timing.start_month; i<=this.tutor.timing.end_month; i++){
              this.months.forEach(month => {
                if(parseInt(month.value) == i && parseInt(month.value) >= (new Date().getMonth()+1)){
                  this.scheduled_months.push(month);
                }
              })
              // i = (i == 12)?0:i;
            }
          }else{
            this.scheduled_months =[];
          }
        }else{
          this.scheduled_months =[];
        }
      },
      addDaySchedule(index){
        console.log(this.tutor);
        let startDate = new Date(this.tutor.timing.year, this.tutor.timing.start_month-1, this.tutor.timing.start_day);
        let endDate = new Date(this.tutor.timing.year, this.tutor.timing.end_month-1, this.tutor.timing.end_day);
        let currentDate = new Date(this.schedules[index-1].startDay);
        if(this.package_type == 'hourly-package'){
          this.schedules[index-1].endDay=this.schedules[index-1].startDay;
        }
        if(currentDate >= startDate && currentDate <= endDate){
          this.disabled_flag = '';
          this.disable_msg = '';
          // this.setTimeSchedule(index);
        }else{
          this.disabled_flag = 'active'+index;
          this.disable_msg = 'Not available in this date';
        }
      },
      setScheduleCalculation(){
        let count = 0;
        if(this.schedules.length>0){
          this.schedules.forEach(schedule => {
            console.log(schedule);
            count = count+schedule.timing.length;
          })
        }
        this.charges.total_hr = count;
        if(this.charges.total_hr > 0){
          this.charges.fee_amount = this.charges.total_hr*this.price;
          this.charges.service_fee = (this.package_type == 'hourly-package')?(this.tutor.salary.website_hourly?(this.tutor.salary.website_hourly*this.charges.total_hr):0):((this.package_type == 'monthly-package')?(this.tutor.salary.website_monthly?this.tutor.salary.website_monthly:0):(this.tutor.salary.website_yearly?this.tutor.salary.website_yearly:0));
          this.charges.tax = (this.student.country_id == 101)?((Math.floor((((parseInt(this.charges.fee_amount)+parseInt(this.charges.service_fee))*0.18)) * 100) / 100)):0;
          this.charges.total_payable = parseInt(this.charges.fee_amount)+parseInt(this.charges.service_fee)+this.charges.tax;
        }else{
          this.charges.fee_amount = 0;
          this.charges.service_fee = 0;
          this.charges.tax = 0;
          this.charges.total_payable =0;
        }
      },
      submitMonthPrice(){
        this.toBook.end_date = null;
        this.disabled_flag = false;
        this.disable_msg = '';
        if(this.toBook.start_date && (this.tutor.timing.start_day <= (new Date(this.toBook.start_date).getDay()+1)) && ((new Date(this.toBook.start_date).getDay()+1) <= this.tutor.timing.end_day)){
          var start_date = this.toBook.start_date.split("-");
          var start_timestamp = new Date( start_date[0], start_date[1] - 1, start_date[2]);
          var end_timestamp = start_timestamp.setDate(start_timestamp.getDate()+30);
          let end_time = new Date(end_timestamp);
          this.toBook.end_date = end_time.getFullYear()+'-'+(end_time.getMonth()+1)+'-'+end_time.getDate();
          let schedule_index= {startDate:this.toBook.start_date,endDate:this.toBook.end_date,time:this.timing[0]};
          this.schedule.push(schedule_index);
          // this.setTimeSchedule(0);
          this.charges.total_month = 1;
          this.charges.fee_amount = parseInt(this.tutor.salary.monthly);
          this.charges.service_fee = parseInt(this.tutor.salary.website_monthly);
          this.charges.tax = (this.student.country_id==101)?((Math.floor((((parseInt(this.charges.fee_amount)+parseInt(this.charges.service_fee))*0.18)) * 100) / 100)):0;
          this.charges.total_payable = parseInt(this.charges.fee_amount)+parseInt(this.charges.service_fee)+this.charges.tax;
        }else{
          this.disabled_flag = true;
          this.disable_msg = 'Not available in this date';
          this.charges.total_month = 0;
          this.charges.fee_amount = 0;
          this.charges.service_fee = 0;
          this.charges.tax=0;
          this.charges.total_payable = 0;
        }
      },
      submitYearPrice(){
        this.disabled_flag = false;
        this.disable_msg = '';
        this.toBook.end_date = null;
        if(this.toBook.start_date&&(this.tutor.timing.start_day <= (new Date(this.toBook.start_date).getDay()+1)) && ((new Date(this.toBook.start_date).getDay()+1) <= this.tutor.timing.end_day)){
          var start_date = this.toBook.start_date.split("-");
          var start_timestamp = new Date( start_date[0], start_date[1] - 1, start_date[2]);
          var end_timestamp = start_timestamp.setDate(start_timestamp.getDate()+365);
          let end_time = new Date(end_timestamp);
          this.toBook.end_date = end_time.getFullYear()+'-'+(end_time.getMonth()+1)+'-'+end_time.getDate();
          let schedule_index= {startDate:this.toBook.start_date,endDate:this.toBook.end_date,time:this.timing[0]};
          this.schedule.push(schedule_index);
          // this.setTimeSchedule(0);
          // this.setBeforeTime(0);
          // this.setAfterTime(0);
          this.charges.fee_amount = parseInt(this.tutor.salary.yearly);
          this.charges.service_fee = parseInt(this.tutor.salary.website_yearly);
          this.charges.tax = (this.student.country_id==101)?((Math.floor((((parseInt(this.charges.fee_amount)+parseInt(this.charges.service_fee))*0.18)) * 100) / 100)):0;
          this.charges.total_payable = parseInt(this.charges.fee_amount)+parseInt(this.charges.service_fee)+this.charges.tax;
        }else{
          this.disabled_flag = true;
          this.disable_msg = 'Not available in this date';
          this.charges.fee_amount = 0;
          this.charges.service_fee = 0;
          this.charges.tax=0;
          this.charges.total_payable = 0;
        }
      },
      packageSubmit(){
        if(this.package_format.month.length > 0){
          this.schedule = [];
          var getDaysInMonth = (month, year) => (new Array(31)).fill('').map((v,i)=>new Date(year,month-1,i+1)).filter(v=>v.getMonth()===month-1);
          this.package_format.month.forEach((m => {
            var year = (m < new Date().getMonth()+1)? new Date().getFullYear()+1:new Date().getFullYear();
            var timestamp = getDaysInMonth(m, year);
            timestamp.forEach((time)=> {
              var date = (time.getDate()<10?'0'+time.getDate():time.getDate())+'-'+(time.getMonth()<9?'0'+(time.getMonth()+1):(time.getMonth()+1))+'-'+time.getFullYear();
              this.schedule.push({date:date,timings:[this.package_format.time],gradeId:this.tutor.grades[0].grade_id,subjectId:this.tutor.subjects[0].subject_id})
            })
          }))
          // this.schedule.gradeId = this.tutor.grades[0].grade_id;
          // this.schedule.subjectId = this.tutor.subjects[0].subject_id;
          // this.charges.schedule = this.schedule;
        }else{
          console.log('select month');
        }
      },
      bookClass(e){
        e.preventDefault();
        this.error = false;
        this.err_msg = '';
        if(this.package_type == 'hourly-package'){
          if(this.schedules.length > 0){
            this.schedules.forEach((schedule,index) => {
              console.log(schedule);
              if(schedule.startDay == null){
                this.error = true;
                this.err_msg = 'Add date for field '+index;
              }else if(schedule.timing.length == 0 || schedule.timing[0].start == null || schedule.timing[0].end == null){
                this.error = true;
                this.err_msg = 'Add time for '+schedule.startDay;
              }else{
                this.booking = true;
                // var request = {tutorId:this.$route.query.tutor_id,dates:this.schedule,gradeId:this.tutor?.grades[0].grade_id,subjectId:this.tutor?.subjects[0].subject_id,package:this.toBook.package};
                // axios.post(process.env.VUE_APP_TUTION_SITE_API+'/bookclass',request,{headers:{"Authorization":'Bearer '+this.$cookies.get('tution-student').token}}).then(res => {
                //   console.log(res);
                // })
              }
            })
          }else{
            this.error = true;
            this.err_msg = 'Select atleast one schedule'
          }
        }
        if(this.package_type == 'monthly-package' || this.package_type == 'yearly-package'){
          // this.booking = true;
          this.schedule[0].time = [];
          if(this.toBook.time == null){
            this.error = true;
            this.err_msg = 'Add time field ';
          }else if(this.toBook.start_date === null){
            this.error = true;
            this.err_msg = 'Add date field ';
          }else{
            this.schedule[0].time.push(this.toBook.time)
          }
          // this.packageSubmit();
        }
      },
      getPackageTime(){
        this.package_format.time = this.default_package_time;
      },
      setLoaded: function(){
        this.loaded = true; 
        var self = this;
        // var tutor_id = this.$route.query.tutor_id;
        var student_token = this.$cookies.get('tution-student').token;
          window.paypal.Buttons({
            // Sets up the transaction when a payment button is clicked
            createOrder: function(data, actions) {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: self.charges.total_payable// Can reference variables or functions. Example: `value: document.getElementById('...').value`
                  }
                }]
              })
            },

            // Finalize the transaction after payer approval
            onApprove: function(data, actions) {
              return actions.order.capture().then(function(orderData) {
                // Successful capture! For dev/demo purposes:
                console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                // var transaction = orderData.purchase_units[0].payments.captures[0];
                // alert('Transaction '+ transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');
                var request = {tutorId:self.$route.query.tutor_id,dates:self.schedules,gradeId:self.tutor.grades.length?self.tutor.grades[0].grade_id:null,subjectId:self.tutor.subjects?self.tutor.subjects[0].subject_id:null,package:self.toBook.package};
                axios.post(process.env.VUE_APP_TUTION_SITE_API+'/bookclass',request,{headers:{"Authorization":'Bearer '+student_token}}).then(res => {
                  if(res.data.message == 'Class Booked successfully'){
                      var booked = {};
                      var student_startdate = res.data.data[0].start_date.split("-").reverse().join("-");
                      var student_enddate = res.data.data[0].end_date.split("-").reverse().join("-");
                      var student_time = res.data.data[0].time;
                      let start_timezone = moment.tz(res.data.data[0].start_date+' '+res.data.data[0].start_time, self.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
                      let end_timezone = moment.tz(res.data.data[0].end_date+' '+res.data.data[0].end_time, self.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
                      booked.start_date = start_timezone.format('YYYY-MM-DD');
                      booked.start_time = start_timezone.format('HH:mm');
                      booked.end_date = end_timezone.format('YYYY-MM-DD');
                      booked.end_time = end_timezone.format('HH:mm');
                      booked.time = self.tConvert(booked.start_time)+' - '+self.tConvert(booked.end_time)
                      var body = {
                                  "eventType": "booking",
                                  "student": {
                                    "name": self.student.name,
                                    "email": self.student.email
                                  },
                                  "tutor": {"name": self.tutor.name},
                                  "request": {
                                      "dates": [
                                          {"startDate": student_startdate, "endDate": student_enddate}
                                      ]
                                  },
                                  "booked": {"time": student_time}
                              }
                      axios.post('https://fpurd8z9i3.execute-api.ap-south-1.amazonaws.com/test01/confirm-booking',body).then(res => {
                        console.log(res);
                      })

                    var element = document.getElementById('paypal-button-container');
                    element.innerHTML = '';
                    element.innerHTML = '<h3 style="color:#088e26">Your class Booked</h3>'; 
                    // setTimeout(() => {
                    //   self.$router.push('/student/booked-classes');
                    // },4000)
                  }else{
                    console.log('Error when booking class');
                  }
                })
              });
            }
          }).render('#paypal-button-container');
      }
    }
  }

</script>

<style scoped>
.form-group {
    margin-bottom: 1.25em;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
.form-control {
    display: block;
    width: 100%;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #252328;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cfced0;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-outline-light:hover {
    color: #2e2f39;
    background-color: #f0f0f8;
    border-color: #f0f0f8;
}
.btn-outline-light {
    color: #2e2f39;
    background-color: transparent;
    border-color: #ebebed;
}
.daysadd {
    float: right;
}
.btn {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
    cursor: pointer;
}
.red--text{
  color: red;
}
.terms{
  display: flex;
  flex-direction: row;
}
</style>