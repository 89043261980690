<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">Booked Classes</h3>
            <p class="d-block">You can see all booked classes here</p>
          </div>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right mb-6">
          <a href="/tutors" class="btn btn-default btn-sm text-white">Book a Class</a>
        </div>
      </div>
      <div class="dashboard-vendor-list">
        <ul class="list-unstyled pl-0" v-if="booked_classes.length">
          <li v-for="b_class in booked_classes" v-bind:key="b_class.id">
            <div class="dashboard-list-block">
              <div class="row">
                <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-img">
                    <a :href="'/tutors/tutor-details?id='+b_class.tutor.tutor_id">
                      <img :src="b_class.tutor.profile_pic" alt="" class="img-fluid" v-if="b_class.tutor.profile_pic" style="pointer-events:none;">
                      <img src="../../assets/images/dummy-tutor.jpg" alt="" class="img-fluid" v-if="!b_class.tutor.profile_pic" style="pointer-events:none;">
                    </a>                  
                  </div>
                </div>
                <div class="col-xl-7 col-lg-5 col-md-6 col-sm-12 col-12">
                  <div class="dashboard-list-content">
                    <h3 class="mb-0"><a :href="'/tutors/tutor-details?id='+b_class.tutor.tutor_id" class="title" v-if="b_class.tutor">{{b_class.tutor.username}} | {{b_class.subject.subject_name}} | {{getGrade(b_class.grade_id)}} Grade</a></h3>
                    <p style="font-size:14px;">Class Time : {{b_class.start_date.split("-").reverse().join("-")}} <span v-if="b_class.package!=1"> to {{b_class.end_date.split("-").reverse().join("-")}}</span> / {{convertToHHMM(b_class.time)}}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-if="checkLive(b_class)">
                  <div class="dashboard-list-btn"><button v-on:click="getClassRoom(b_class.video_key)" class="btn btn-outline-violate btn-xs mr10" target="_blank">Join Class</button></div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-else>
                  <div class="dashboard-list-btn"><a :href="'/booking/'+b_class.video_key" class="btn btn-outline-violate btn-xs mr10 text-white" target="_blank">Join Class</a></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul v-else>
          <div class="empty-data" style="margin-left:250px;">No Booked Classes Found</div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// var moment = require('moment-timezone');
export default {
  name: 'booked-classes',
  data(){
    return {
      booked_classes:[],
      grades:[],
      months:[
        {value:'1', text:'January'},
        {value:'2', text:'February'},
        {value:'3', text:'March'},
        {value:'4', text:'April'},
        {value:'5', text:'May'},
        {value:'6', text:'June'},
        {value:'7', text:'July'},
        {value:'8', text:'August'},
        {value:'9', text:'September'},
        {value:'10', text:'October'},
        {value:'11', text:'November'},
        {value:'12', text:'December'}
      ],
      live:false
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/bookedclass', {headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
      if(res.data.message == 'Booked class retrived sucessfully'){
        res.data.data.forEach(booked => {
          if(booked.is_active == 'Y'){
            // const options = {
            //   timeZone: 'UTC',
            //   timeZoneName: 'long'
            // };
            // const timezone = Intl.DateTimeFormat(booked.student_country.country.shortname).resolvedOptions().timeZone;
            // let start_timezone = moment.tz(booked.start_date+' '+booked.start_time, booked.tutor.timezone).clone().tz(timezone);
            // let end_timezone = moment.tz(booked.end_date+' '+booked.end_time, booked.tutor.timezone).clone().tz(timezone);
            // booked.start_date = start_timezone.format('YYYY-MM-DD');
            // booked.start_time = start_timezone.format('HH:mm');
            // booked.end_date = end_timezone.format('YYYY-MM-DD');
            // booked.end_time = end_timezone.format('HH:mm');
            booked.time = this.tConvert(booked.start_time)+' - '+this.tConvert(booked.end_time)

            this.booked_classes.push(booked);
          }
        })
      }
    })
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      if(res.data.Grades.length){
        res.data.Grades.forEach(g => {
          if(g.is_active == 'Y'){
            this.grades.push({value:g.grade_id,text:g.name});
          }
        })
      }
    })
  },
  methods:{
    getGrade(g){
      let result;
      this.grades.forEach(grade => {
        if(grade.id == g){
          result =grade.value;
        }
      });
       result;
    },
    tConvert (time) {
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join ('');
    },
    convertToHHMM(timeString) {
      console.log(timeString);
      const start = timeString.split(' - ')[0];
      const end = timeString.split(' - ')[1];
      const [s_hours, s_minutes] = start.split(':').slice(0, 2);
      const [e_hours, e_minutes] = end.split(':').slice(0, 2);
      const $s_format =  start.split(':')[2].slice(2);
      const $e_format =  end.split(':')[2].slice(2);
      return `${s_hours}:${s_minutes}`+$s_format+` - ${e_hours}:${e_minutes}`+$e_format;
    },
    checkLive(u_class){
      var hours = new Date().getHours();
      var minutes = new Date().getMinutes();
      let start_hour = parseInt(u_class.start_time.split(':')[0]);
      let start_minute = parseInt(u_class.start_time.split(':')[1]);

      let end_hour = parseInt(u_class.end_time.split(':')[0]);
      let end_minute = parseInt(u_class.end_time.split(':')[1]);

      let start_timestamp = new Date(u_class.start_date).setHours(start_hour,start_minute,0,0);
      let end_timestamp = new Date(u_class.end_date).setHours(end_hour,end_minute,0,0);
      let current_timestamp = new Date().setHours(hours,minutes,0,0);

      if((start_timestamp<=current_timestamp && current_timestamp<=end_timestamp)){
          return true;
      }else{
        return false;
      }
    },
    getClassRoom(key){
      const newTab = window.open(process.env.VUE_APP_LIVE_CLASS_URL+'/'+key, '_blank');
      newTab.focus();
    }
  },
}
</script>
<style scoped>
.dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    border-radius: 3px;
}
.dashboard-list-content {
    font-size: 14px;
    /* padding-top: 23px; */
    /* padding-bottom: 23px; */
}
.dashboard-list-btn {
    padding-right: 29px;
    padding-top: 20px;
    text-align: right;
}
.dashboard-list-img img {
    width: 70%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.title {
    color: #252328;
    text-decoration: none;
}
.title:hover {
    outline: 0;
    text-decoration: none;
}
.title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.5;
}
</style>