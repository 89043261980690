<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">Upcoming Classes</h3>
            <p class="d-block">You can see all upcoming classes here</p>
          </div>
        </div>
      </div>
      <div class="dashboard-vendor-list">
        <ul class="list-unstyled pl-0" v-if="upcoming_classes.length > 0">
          <li v-for="b_class in upcoming_classes" v-bind:key="b_class.id">
            <div class="dashboard-list-block">
              <div class="row">
                <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-img">
                    <a :href="'/tutors/tutor-details?id='+b_class.tutor.tutor_id">
                      <img :src="b_class.tutor.profile_pic" alt="" class="img-fluid" v-if="b_class.tutor.profile_pic" style="pointer-events:none;">
                      <img src="../../assets/images/dummy-tutor.jpg" alt="" class="img-fluid" v-if="!b_class.tutor.profile_pic" style="pointer-events:none;">
                    </a>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-5 col-md-6 col-sm-12 col-12">
                  <div class="dashboard-list-content">
                    <h3 class="mb-0"><a href="" class="title">Student: <span class="text-success">{{b_class.student.name}}</span> | {{b_class.subject?b_class.subject.subject_name:'-'}} | {{b_class.grade?b_class.grade.name:'-'}} Grade</a></h3>
                    <p style="font-size:14px;">Class Time : {{b_class.start_date.split("-").reverse().join("-")}}<span v-if="b_class.package!=1"> to {{b_class.end_date.split("-").reverse().join("-")}}</span>   / {{b_class.time}}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-if="checkLive(b_class)">
                  <div class="dashboard-list-btn"><button v-on:click="getClassRoom(b_class.video_key)" class="btn btn-outline-violate btn-xs mr10" target="_blank">Join Class</button></div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-else>
                  <div class="dashboard-list-btn"><a :href="'/booking/'+b_class.video_key" class="btn btn-outline-violate btn-xs mr10 text-white" target="_blank">Join Class</a></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="row" v-else>
          <div class="col-lg-12 empty-data text-center">
            No Upcomming classes found
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
var moment = require('moment-timezone');

export default {
  name: 'booked-classes',
  data(){
    return {
      upcoming_classes:[],
      grades:[],
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor_upcomming_class',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-tutor').token)}}).then(res => {
      if(res.data.message == 'Upcomming class retrived sucessfully'){
        // this.upcoming_classes = res.data.data;
        res.data.data.forEach(booked => {
            // let student_timezone = Intl.DateTimeFormat(booked.student.country.sortname).resolvedOptions().timeZone;
            let student_timezone = this.getTimeZoneByCountry(booked.student.country.sortname);
            // let convertedTime = moment.tz(booked.start_date+' '+booked.start_time, student_timezone).tz(booked.tutor.timezone);
            // console.log(student_timezone,booked.tutor.timezone);
            // console.log(`Converted Time: ${convertedTime.format('HH:mm')} in Europe/London`);

            // let original_start_time = moment(booked.start_date+' '+booked.start_time, 'YYYY-MM-DD HH:mm:ss').tz(student_timezone);
            let converted_start_time = moment.tz(booked.start_date+' '+booked.start_time, student_timezone).tz(booked.tutor.timezone);
            // let original_end_time = moment(booked.end_date+' '+booked.end_time, 'YYYY-MM-DD HH:mm:ss').tz(student_timezone);
            let converted_end_time = moment.tz(booked.end_date+' '+booked.end_time, student_timezone).tz(booked.tutor.timezone);
            // let start_timezone = moment.tz(booked.start_date+' '+booked.start_time, booked.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
            // let end_timezone = moment.tz(booked.end_date+' '+booked.end_time, booked.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
            booked.start_date = converted_start_time.format('YYYY-MM-DD');
            booked.start_time = converted_start_time.format('HH:mm');
            booked.end_date = converted_end_time.format('YYYY-MM-DD');
            booked.end_time = converted_end_time.format('HH:mm');
            booked.time = this.tConvert(booked.start_time)+' - '+this.tConvert(booked.end_time)
          this.upcoming_classes.push(booked);
        })
      }else{
        this.upcoming_classes = [];
      }
    });
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      if(res.data.Grades.length){
        res.data.Grades.forEach(g => {
          if(g.is_active == 'Y'){
            this.grades.push({value:g.grade_id,text:g.name});
          }
        })
      }
    })
  },
  methods:{
    getGrade(g){
      let result;
      this.grades.forEach(grade => {
        if(grade.id == g){
          result =grade.value;
        }
      });
      return result;
    },
    tConvert (time) {
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join ('');
    },
    getClassRoom(key){
      const newTab = window.open(process.env.VUE_APP_LIVE_CLASS_URL+'/'+key, '_blank');
      newTab.focus();
    },
    getTimeZoneByCountry(countryCode) {
      const timeZones = moment.tz.names();
      for (const timeZone of timeZones) {
        const countryCodes = moment.tz.zone(timeZone).countries();
        if (countryCodes.includes(countryCode)) {
          return timeZone;
        }
      }
      return null;
    },
    checkLive(u_class){
      var hours = new Date().getHours();
      var minutes = new Date().getMinutes();
      let start_hour = parseInt(u_class.start_time.split(':')[0]);
      let start_minute = parseInt(u_class.start_time.split(':')[1]);

      let end_hour = parseInt(u_class.end_time.split(':')[0]);
      let end_minute = parseInt(u_class.end_time.split(':')[1]);

      let start_timestamp = new Date(u_class.start_date).setHours(start_hour,start_minute,0,0);
      let end_timestamp = new Date(u_class.end_date).setHours(end_hour,end_minute,0,0);
      let current_timestamp = new Date().setHours(hours,minutes,0,0);

      if((start_timestamp<=current_timestamp && current_timestamp<=end_timestamp)){
          return true;
      }else{
        return false;
      }
    }
  }
}
</script>
<style scoped>
.dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    border-radius: 3px;
}
.dashboard-list-content {
    font-size: 14px;
    /* padding-top: 23px; */
    /* padding-bottom: 23px; */
}
.dashboard-list-btn {
    padding-right: 29px;
    padding-top: 20px;
    text-align: right;
}
.dashboard-list-img img {
    width: 70%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.title {
    color: #252328;
    text-decoration: none;
}
.title:hover {
    outline: 0;
    text-decoration: none;
}
.title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.5;
}
</style>