<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">My Video Courses</h3>
            <p class="d-block">You can see all courses you purchased here</p>
          </div>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right mb-6">
          <a href="/video_course" class="btn btn-default btn-sm text-white">Purchase Video Courses</a>
        </div>
      </div>
      <div class="dashboard-vendor-list" v-if="!loading">
        <ul class="list-unstyled pl-0" v-if="downloaded_materials.length">
          <li v-for="(material,index) in downloaded_materials" :key="index">
            <div class="dashboard-list-block">
              <div class="row">
                <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-img">
                    <a href=" ">
                      <img :src="material.thumbnails[0].url" alt="" class="img-fluid" style="pointer-events:none;height:70px;" v-if="material.thumbnails.length">
                      <img src="../../assets/images/cart-l.jpg" alt="" class="img-fluid" style="pointer-events:none;" v-else>
                    </a>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-5 col-md-6 col-sm-12 col-12">
                  <div class="dashboard-list-content">
                    <h3 class="mb-0"><a href="" class="title">{{material.name}}</a></h3>
                    <p style="font-size:14px;">{{material.description}}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-btn">
                    <a class="btn btn-outline-violate btn-xs mr10" v-on:click.prevent="startClass(material.id)">Start Class</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul v-else>
          <div class="empty-data">No Courses</div>
        </ul>
      </div>
      <div class="dashboard-vendor-list" style="margin-top:75px;text-align:center;" v-else>
        <pulseLoader :loading="loading" color="#d51439" size="100"></pulseLoader>
      </div>
    </div>
    <b-modal id="success" ref="video-modal" size="xl" :title="video_title" hide-footer centered>
      <div class="container text-center">
        <video id="video_modal" width="1000" height="400" type="video/*" controls :src="this.video_id"></video>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import pulseLoader from '@/components/dist/Loader';
export default {
    name: 'completed-classes',
    components:{
      pulseLoader
    },
    data(){
      return{
        downloaded_materials:[],
        video_id:null,
        video_title:null,
        loading:true,
        secret:"#tu@910",
      }
    },
    beforeMount(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list/video_courses', {headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.meaasge == 'User paid items listed successfully'){
          this.loading = false;
          res.data.data.forEach((item => {
            if(item.course.is_active == 1){
              this.downloaded_materials.push(item.course);
            }
          })
        )}
      });
    },
    methods:{
      openVideoModal(videoID,title){
        this.video_id = videoID;
        this.video_title = title;
        this.$refs['video-modal'].show();
      },
      startClass(id){
        const data = this.$CryptoJS.AES.encrypt('tuition_id='+id, 'tutn@369').toString().replaceAll('/','Por21Ld');
        this.$router.push('/student/video-class-room/'+data);
      }
    }
}
</script>

<style scoped>
.dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    border-radius: 3px;
}
.dashboard-list-content {
    font-size: 14px;
    /* padding-top: 23px; */
    /* padding-bottom: 23px; */
}
.dashboard-list-btn {
    padding-right: 29px;
    padding-top: 20px;
    text-align: right;
}
.dashboard-list-img img {
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.title {
    color: #252328;
    text-decoration: none;
}
.title:hover {
    outline: 0;
    text-decoration: none;
}
.title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.5;
}
</style>