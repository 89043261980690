<template>
  <div class="study_materials">
    <div class="content">
      <div class="container">
        
        <div class="row course-details">
            <div class="col-md-6">
                <carousel :starting-image="2" :images="images" v-if="this.images.length" :auto-slide-interval="10000" :show-progress-bar="true"></carousel>
            </div>
            <div class="col-md-6" v-if="course">
                <h1>{{course.name}}<span v-if="course.female_only==1" class="female_filter">Female only</span></h1>
                <div class="price">${{course.price}}</div>
                <p><a href="" class="btn btn-success btn-sm mr-1" @click.prevent="addToCart(course)" v-if="!flag">Add to Cart</a> 
                <a href="/cart" class="btn btn-success btn-sm mr-1" v-else>Go to cart</a></p>
                <p>{{course.description}}</p>
                <h3>Modules</h3>
                <ol class="modules" v-if="course.modules.length">
                  <li v-for="v_module in course.modules" :key="v_module.id">{{v_module.name}}</li>
                </ol>
            </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <p class="text-center shopCopyright">Copyright 2021 Fromyourtutor.com. All Rights Reserved <br>
              No part of this may be reproduced in any language,
              printed in any form or any electronic or mechanical
              means including but not limited to photocopying,
              recording, or otherwise, without specific verbal or
              written consent from Fromyourtutor.Com
              <br>
              Contact us at:
              info@fromyourtutor.com
              admin@fromyourtutor.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="carousel.js"></script>
<script>
import axios from 'axios'
import Carousel from "@/components/dist/Carousal.vue";
export default {
  name: "studyMaterials",
  components:{
    Carousel
  },
  data(){
    return {
      user_id:null,
      course:null,
      book:null,
      items:[],
      images: [],
      flag:false
    }
  },
  async beforeMount(){
    window.scrollTo(0, 0);
    this.getCourseDetails(this.$route.query.id);
    if(this.$cookies.get('order-cart-session-key')&&this.$cookies.get('order-cart-session-token')){
      console.log('');
    }else{
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/cart',{},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.Message == 'A new cart have been created for you!'){
          this.$cookies.set('order-cart-session-key',res.data.cartKey);
          this.$cookies.set('order-cart-session-token',res.data.cartToken);
        }
      })
    }

    if(this.$cookies.get('order-cart-session-key')&&this.$cookies.get('order-cart-session-token')){
      let cartKey = this.$cookies.get('order-cart-session-key');
      let cartToken = this.$cookies.get('order-cart-session-token');
        if(this.$cookies.get('tution-student')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-student').token}}).then(res => {
            if(res.data.message == 'Student retrieved successfully'){
              let student = res.data.data.student;
              this.user_id = student.user.user_id;
            }
          });
        }else if(this.$cookies.get('tution-tutor')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-tutor').token}}).then(res => {
            if(res.data.message == 'User found'){
              let tutor = res.data.data.users;
              this.user_id = tutor.user_id;
            }
          });
        }
        
        await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/getcart',{cartKey:cartKey,cartToken:cartToken,user_id:this.user_id},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message == 'course listed successfully'){
          res.data.Items.forEach(item => {
            if(item.is_active == 'Y'){
              this.items.push(item)
            }
          });
        }
      });
    }else{
      this.items = [];
    }
  },
  methods:{
    getCourseDetails(id){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/video_course/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data&&res.data.course.is_active==1&&res.data.course.modules.length){
          this.course = res.data.course;
          if(this.course.thumbnails.length>0){
            this.course.thumbnails.forEach((item,index)=>{
              if(item.status == 1){
                this.images.push({id:index+1,big:item.url,thumb:item.url});
              }
            })
          }
          this.flag = false;
          if(this.items.length > 0){
            this.items.forEach((item,index) => {
              if(this.course.video_course_id == item.video_course_id){
                flag = true;
                this.items.splice(index,1)
              }
            })
          }else{
            this.flag = false;
          }
        }else{
          this.course = null;
        }
      });
    },

    addToCart(course){
        let cartKey = this.$cookies.get('order-cart-session-key');
        let cartToken = this.$cookies.get('order-cart-session-token');

        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/addcarts',{productID:course.id,quantity:1,cartKey:cartKey,cartId:cartToken,user:this.user_id,item_type:3},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.message == 'product added to Cart  successfully' || res.data.message == 'The Cart was updated with the given product information successfully'){
            this.$router.push('/course/cart');
          }
        })
      }
      // else{
      //   // this.$cookies.set("previousURL", {location: window.location.href, path:'/study_materials'});
      //   // this.$router.push('/login');
      //   this.$router.replace({name:'student-login', params:{return:true}});
      // }
    // }
  }
}
</script>
<style>
.card-carousel .thumbnails .thumbnail-image{
  width: 140px !important;
}
</style>

<style scoped>

button.btn {
    font-size: 13px;
    height: 50px;
    font-weight: 400;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.zoomimg {
    position: relative;
    overflow: hidden;
}
.vendor-content {
    padding: 16px 16px 12px 16px;
    margin-bottom: 6px;
}
a {
    color: #252328;
    -webkit-font-smoothing: antialiased;
}
.vendor-img img {
    width: 100%;
}
.zoomimg img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.vendor-title {
    font-size: 20px;
    margin: 0px 0px 5px 0px;
}
.vendor-address {
    font-size: 12px;
    font-weight: 400;
    color: #6f6d72;
    line-height: 18px;
}
p {
    margin: 0px 0px 20px 0px;
    -webkit-font-smoothing: antialiased;
}
.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 1.5;
}
.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.zoomimg:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.female_filter{
    font-size: 12px;
    background-color: #ce0d3d;
    color: white;
    padding: 2px 7px 2px 7px;
    border-radius: 6px;
    margin-left: 5px;
}
</style>