<template>
  <div class="dashboard-content">
    <div id="slide-reveal-overlay" v-on:click="closeSlideBar()" style="display: none; position: fixed; top: 0px; left: 0px; height: 100%; width: 100%; z-index: 1048; background-color: rgba(0, 0, 0, 0.5);"></div>
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header">
            <h3 class="dashboard-page-title">Tutors Manager</h3>
            <p>Tutors manager allows you to manage edit &amp; delete tutors.</p>
          </div>
        </div>
      </div>
      <div class="rsvp-summary-section">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="card summary-block rsvp-summary bg-light-violate">
              <div class="card-body summary-content">
                <div class="summary-count">{{requests.length}}</div>
                <p class="summary-text">New Requests</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="card summary-block rsvp-summary  bg-light-green">
              <div class="card-body summary-content">
                <div class="summary-count">{{joined_tutors.length}}</div>
                <p class="summary-text">Tutors</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guest-list-block">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-7 col-sm-6 col-6">
            <h4 class="guest-list-block-title">Tutors List<a v-on:click="addTutorModal()" class="btn btn-default ml-3" id="trigger6" style="font-size:12px !important;">ADD TUTOR</a></h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="guestlist-management">
            <div class="card">
              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs pl-0" id="myTab" role="tablist">
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'new-request'}" @click="tab='new-request'" id="allguest-tab" data-toggle="tab" role="tab" aria-controls="allguest">New Request</a>
                  </li>
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'joined-tutors'}" id="confirmguest-tab" @click="tab='joined-tutors'" data-toggle="tab" role="tab" aria-controls="confirmguest">Joined Tutors</a>
                  </li>
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'disabled-tutors'}" id="confirmguest-tab" @click="tab='disabled-tutors'" data-toggle="tab" role="tab" aria-controls="confirmguest">Disabled Tutors</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" :class="{'active show': tab == 'new-request'}" id="allguest" role="tabpanel" aria-labelledby="allguest-tab">
                  <div class="tab-content" id="guest-filter-content">
                    <div class="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
                      <div class="guest-list-table table-responsive mb-0">
                        <div class="single-guest-tab">
                          <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                            <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                              <thead>
                                <tr role="row">
                                  <th class="sorting_asc" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 20px;" aria-sort="ascending">Sn.</th>
                                  <th class="sorting_asc" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 142px;" aria-sort="ascending">Tutor Name</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 124px;">Contact Num</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 180px;">Email Address</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 170px;">Country</th>
                                  <th data-orderable="false" class="sorting_disabled" rowspan="1" colspan="1" style="width: 167px;">Actions</th>
                                </tr>
                              </thead>
                              <tbody v-if="requests.length"> 
                                <tr role="row" class="odd" v-for="(request,index) in requests" :key="request.id">
                                  <td class="sorting_1">{{index+1}}</td>
                                  <td class="sorting_1" v-if="request.name">{{request.name}}</td><td v-else>-</td>
                                  <td v-if="request.mobile">{{request.code}}-{{request.mobile}}</td><td v-else>-</td>
                                  <td v-if="request.email">{{request.email}}</td><td v-else>-</td>
                                  <td v-if="request.country">{{request.country}}</td><td v-else>-</td>
                                  <td><a :href="'/tutn-auth/tutor-view/'+request.id" class="btn btn-outline-violate btn-xs mr-1">view</a>
                                      <a class="btn btn-outline-pink btn-xs" v-on:click.prevent="deleteTutor(request.id,index,'request')">delete</a>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody class="container" v-else>
                                <tr>
                                  <td class="empty-data text-center" colspan="6" rowspan="4">Empty Requests</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" :class="{'active show': tab == 'joined-tutors'}" id="confirmguest" role="tabpanel" aria-labelledby="confirmguest-tab">
                  <div class="guest-list-table table-responsive mb-0">
                    <div class="single-guest-tab">
                      <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                        <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                          <thead>
                            <tr role="row">
                                  <th class="sorting_asc" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 20px;" aria-sort="ascending" aria-label="Guest Name: activate to sort column descending">Sn.</th>
                                  <th class="sorting_asc" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 142px;" aria-sort="ascending" aria-label="Guest Name: activate to sort column descending">Tutor Name</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 124px;" aria-label="Group: activate to sort column ascending">Contact Num</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 180px;" aria-label="Email Address: activate to sort column ascending">Email Address</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 170px;" aria-label="RSVP: activate to sort column ascending">Country</th>
                                  <th data-orderable="false" class="sorting_disabled" rowspan="1" colspan="1" style="width: 167px;" aria-label="Status">Status</th>
                                  <th data-orderable="false" class="sorting_disabled" rowspan="1" colspan="1" style="width: 167px;" aria-label="Actions">Actions</th>
                            </tr>
                          </thead>
                          <tbody v-if="joined_tutors.length">
                            <tr role="row" class="odd" v-for="(tutor,index) in joined_tutors" :key="tutor.id">
                              <td class="sorting_1">{{index+1}}</td>
                              <td class="sorting_1">{{tutor.name}}</td>
                              <td>{{tutor.code}}-{{tutor.mobile}}</td>
                              <td>{{tutor.email}}</td>
                              <td>{{tutor.country}}</td>
                              <td v-if="tutor.status==0"><span class="dot-badge dot-badge-success"></span> Confirmed</td>
                              <td v-if="tutor.status==1"><span class="badge-dot badge-danger"></span> Pending</td>
                              <td>
                                <a :href="'/tutn-auth/tutor-view/'+tutor.id" class="btn btn-outline-violate btn-xs mr-2 mb-2">edit</a>
                                <a v-on:click.prevent="deleteTutor(tutor.id,index,'joined')" class="btn btn-outline-pink btn-xs">delete</a>
                              </td>
                            </tr>
                          </tbody>
                          <tbody class="container" v-else>
                            <tr>
                              <td class="empty-data text-center" colspan="6" rowspan="4">Empty Requests</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" :class="{'active show': tab == 'disabled-tutors'}" id="confirmguest" role="tabpanel" aria-labelledby="confirmguest-tab">
                  <div class="guest-list-table table-responsive mb-0">
                    <div class="single-guest-tab">
                      <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                        <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                          <thead>
                            <tr role="row">
                                  <th class="sorting_asc" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 20px;" aria-sort="ascending">Sn.</th>
                                  <th class="sorting_asc" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 142px;" aria-sort="ascending">Tutor Name</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 124px;">Contact Num</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 180px;">Email Address</th>
                                  <th class="sorting" tabindex="0" aria-controls="allguest-datatables" rowspan="1" colspan="1" style="width: 170px;">Country</th>
                                  <!-- <th data-orderable="false" class="sorting_disabled" rowspan="1" colspan="1" style="width: 167px;" aria-label="Status">Status</th> -->
                                  <th data-orderable="false" class="sorting_disabled" rowspan="1" colspan="1" style="width: 167px;" aria-label="Actions">Actions</th>
                            </tr>
                          </thead>
                          <tbody v-if="disabled_tutors.length">
                            <tr role="row" class="odd" v-for="(tutor,index) in disabled_tutors" :key="tutor.id">
                              <td class="sorting_1">{{index+1}}</td>
                              <td class="sorting_1">{{tutor.name}}</td>
                              <td>{{tutor.code}}-{{tutor.mobile}}</td>
                              <td>{{tutor.email}}</td>
                              <td>{{tutor.country}}</td>
                              <!-- <td v-if="tutor.status==0"><span class="dot-badge dot-badge-success"></span> Confirmed</td>
                              <td v-if="tutor.status==1"><span class="badge-dot badge-danger"></span> Pending</td> -->
                              <td>
                                <a v-on:click.prevent="enableTutor(tutor.id,index)" class="btn btn-outline-pink btn-xs">Enable</a>
                              </td>
                            </tr>
                          </tbody>
                          <tbody class="container" v-else>
                            <tr>
                              <td class="empty-data text-center" colspan="6" rowspan="4">Empty Requests</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="addTutor" ref="tutor-modal" size="lg" title="Add Tutor Deatils" hide-footer centered>
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body">
                <v-form ref="form" lazy-validation v-model="valid">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Name<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined ></v-text-field>
                    </div>
<!-- 
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Surname</label>
                      <v-text-field type="text" v-model="register.surname" outlined ></v-text-field>
                    </div> -->

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Email<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.email" :rules="emailRules" outlined :error-messages="auth_msg"></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Password<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="password" v-model="register.password" :rules="passwordRules" outlined></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Country</label>
                       <v-autocomplete v-model="tutor.country" :items="countries" item-text="text" item-value="value" @input="getRegions()" searchable outlined clearable></v-autocomplete>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div class="row">
                        <div class="col-lg-4 pt-0">
                          <label for="inputText3" class="col-form-label">Country code</label>
                          <v-autocomplete v-model="tutor.code" :items="codes" item-text="text" item-value="value"  @input="getCountry" outlined></v-autocomplete>
                        </div>
                        <div class="col-lg-8 pt-0">
                          <label for="inputText3" class="col-form-label">Contact num</label>
                          <v-text-field type="text" v-model="register.mobile" outlined :error-messages="mob_msg"></v-text-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select preferred subjects</label>
                      <v-autocomplete  v-model="register.subjects" v-on:input="limiter" :items="subjects" item-text="text" item-value="value" multiple chips clearable deletable-chips searchable outlined></v-autocomplete>
                    </div>
                    
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select gender</label>
                      <v-autocomplete v-model="register.gender" :items="gender" item-text="text" item-value="value" outlined ></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Region</label>
                       <v-autocomplete v-model="register.region" :items="regions" item-text="text" item-value="value" searchable outlined clearable></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select highest educational qualifcation</label>
                       <v-autocomplete v-model="register.qualification" :items="qualifications" item-text="text" item-value="value" searchable outlined clearable></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select preferred grades</label>
                      <v-autocomplete v-model="register.grades" :items="grades" item-text="text" item-value="value" v-on:input="limiter" multiple searchable chips clearable deletable-chips outlined ></v-autocomplete>
                    </div>

                    <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select language</label>
                      <v-autocomplete :items="languages" item-text="text" item-value="value" v-model="register.language" outlined ></v-autocomplete>
                    </div> -->

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Tell me about yourself</label>
                      <v-textarea v-model="register.about" outlined ></v-textarea>
                    </div>
                    <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Upload Univercity Certificate<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.name" :rules="[v => !!v || 'Upload univercity certificate']" outlined ></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Upload Demo Class Video<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.name" outlined ></v-text-field>
                    </div> -->

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <b-button type="submit" class="btn btn-default" v-if="!loading" v-on:click.prevent="postRegister">Submit</b-button>
                        <b-button type="button"  class="btn btn-default" v-if="loading"><i class="fa fa-spinner fa-spin"></i></b-button>
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p v-if="record.type == 'DELETE'">Do you really want to delete these records?</p>
          <p v-if="record.type == 'ENABLE'">Do you really want to enable these records?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal('confirm-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" v-if="record.type == 'DELETE'" v-on:click="deleteRecord">Delete</button>
            <button type="button" class="btn btn-danger" v-if="record.type == 'ENABLE'" v-on:click="enableRecord">Enable</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Tutor added</div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "admin-tutor",
  data(){
    return {
      tab: "new-request",
      joined_tutors:[],
      requests:[],
      countries:[],
      t_country:null,
      record:{
        id:null,
        index:null,
        type:null,
      },
      register:{
        name:null,
        surname:null,
        mobile:null,
        email:null,
        password:null,
        country:null,
        region:null,
        qualification:null,
        language:null,
        gender:null,
        grades:null,
        subjects:null,
        about:null,
      },
      tutor:{
        code:null,
        mobile:null,
        country:null,
      },
      grades:[],
      gender:[
        {value:1, text:'Male'},
        {value:2, text:'Female'},
        {value:3, text:'Other'}
      ],
      subjects:[],
      languages:[],
      regions:[],
      qualifications:[],
      codes:[],
      auth_error: false,
      auth_msg: null,
      mob_error:false,
      mob_msg:null,
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number',
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      disabled_tutors:[],
      valid:true,
      loading:false,
      err_msg:'',
    }
  },
  beforeMount(){
    this.getTutors();
    this.getCountries();
    this.getLanguages();
    this.getSubjects();
    this.getGrades();
    this.getQualification();
  },
  methods:{
    hideModal(data){
      this.$refs[data].hide();
    },
    addTutorModal(){
      this.$refs['tutor-modal'].show();
    },
    getTutors(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/listTutors',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        res.data.data.forEach(tutor => {
          if(tutor.is_active == 'Y' &&tutor.users&& tutor.users.user_status == 0){
            this.joined_tutors.push({id:tutor.tutor_id,name:tutor.name,email:tutor.users.email,code:tutor.users.code,mobile:tutor.users.mobile,country:tutor.country?tutor.country.name:null,status:tutor.users.user_status})
          }
          if(tutor.is_active == 'P' &&tutor.users&& tutor.users.user_status == 0){
            this.requests.push({id:tutor.tutor_id,name:tutor.name,email:tutor.users.email,code:tutor.users.code,mobile:tutor.users.mobile,country:tutor.country?tutor.country.name:null});
          }
        });
      });
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/disabled_tutors',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Tutors listed successfully'){
          res.data.data.forEach(tutor => {
            if(tutor.is_active == 'N' &&tutor.users&& tutor.users.user_status == 0){
              this.disabled_tutors.push({id:tutor.tutor_id,name:tutor.name,email:tutor.users.email,code:tutor.country_code,mobile:tutor.users.mobile,country:(tutor.country)?tutor.country.name:null});
            }
          })
        }
      })
    },
    deleteTutor(id,index,type){
      this.record.id = id;
      this.record.index = index;
      this.record.type = type;
      this.record.type = 'DELETE';
      this.$refs['confirm-modal'].show();
    },
    enableTutor(id,index){
      this.record.id = id;
      this.record.index = index;
      this.record.type = 'ENABLE';
      this.$refs['confirm-modal'].show();
    },
    enableRecord(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor_activate',{status:'Y',tutor_id:this.record.id},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Tutor updated successfully'){
          this.disabled_tutors.splice(this.record.index,1);
          this.$refs['confirm-modal'].hide();
          this.joined_tutors.push({id:res.data.data.tutor_id,name:res.data.data.name,email:res.data.data.users.email,code:res.data.data.users.code,mobile:res.data.data.users.mobile,country:res.data.data.country.name,status:res.data.data.users.user_status});
        }
      })
    },
    deleteRecord(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor_activate',{status:'N',tutor_id:this.record.id},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Tutor updated successfully'){
          this.joined_tutors.splice(this.record.index,1);
          this.$refs['confirm-modal'].hide();
          this.disabled_tutors.push({id:res.data.data.tutor_id,name:res.data.data.name,email:res.data.data.users.email,code:res.data.data.users.code,mobile:res.data.data.users.mobile,country:(res.data.data.country)?res.data.data.country.name:null,status:res.data.data.users.user_status});
        }
      })
    },
    limiter(e){
      if(e.length>4){
        e.pop();
      }
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode})
        })
      })
    },
    getLanguages(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/languages',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Languages.forEach(language => {
          if(language.is_active == 'Y'){
            this.languages.push({value:language.language_id,text:language.name});
          }
        })
      })
    },
    getSubjects(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          if(subject.is_active == 'Y'){
            this.subjects.push({value:subject.subject_id, text:subject.subject_name});
          }
        })
      })
    },
    getRegions(){
      this.register.country = this.tutor.country;
      this.listRegions(this.register.country);
      this.countries.forEach(country => {
        if(country.value == this.register.country){
          this.tutor.code = {value:country.code, text:country.short+ '-' + country.code};
        }
      });
    },
    getCountry(){
      this.countries.forEach(country => {
        if(country.code == this.tutor.code){
          this.tutor.country = {value:country.value, text:country.text};
        }
      })
      this.register.country = this.tutor.country.value;
      this.listRegions(this.tutor.country.value);
    },
    getGrades(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Grades.forEach(grade => {
          this.grades.push({value:grade.grade_id,text:grade.name});
        })
      })
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    getQualification(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/qualification',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Qualification.forEach(qual => {
          if(qual.is_active == 'Y'){
            this.qualifications.push({value :qual.qualification_id,text:qual.name});
          }
        })
      })
    },
    postRegister(e){
      e.preventDefault();
      this.loading = true;
      this.auth_msg = null;
      this.auth_error = false;
      this.mob_error = false;
      this.mob_msg = null;
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        this.register.country_code = (typeof this.tutor.code === 'object'&&this.tutor.code)?this.tutor.code.value:this.tutor.code;
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/admin/tutor',this.register,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          this.loading = false;
          if(res.data.status != 0){
            this.$refs["success"].show();
            setTimeout(() => {
              this.requests.unshift({id:res.data.data.tutor_id,name:res.data.data.name,email:this.register.email,code:res.data.data.users.code,mobile:res.data.data.users.mobile,country:res.data.data.country?res.data.data.country.name:null});
              this.$refs["success"].hide();
              this.$refs['tutor-modal'].hide();
            },2000);
          }else{
            this.loading = false;
            if(res.data.message == 'Mobile error'){
              this.mob_error = true;
              this.mob_msg = 'Mobile is already registered with us'
            }else{
              this.auth_error = true;
              this.auth_msg = 'Email is already registered with us'
            }
          }
          // let student = { token: res.access.token, name:this.register.name}
          // this.$cookies.set("tution-student",student);
          // location.reload();
          // this.$router.push('/student/dashboard');
        }).catch((err) => {
          this.loading = false;
          if(err.response.data.email){
            this.auth_error = true;
            this.auth_msg = 'Email is already registered with us'
          }
          if(err.response.data.mobile){
            this.mob_error = true;
            this.mob_msg = 'Mobile is already registered with us'
          }
        });
      }else{
        console.log('not valid', this.valid);
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.rsvp-summary.card {
    box-shadow: none;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.summary-block {
    margin-bottom: 30px;
}
.bg-light-violate {
    background-color: #e1caf1 !important;
    border-color: #e1caf1;
}
.bg-light-green {
    background-color: #cfedad !important;
    border-color: #cfedad;
}
.card-body {
    padding: 20px;
}
.summary-count {
    font-size: 42px;
    color: #252328;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1;
}
.rsvp-summary .summary-text {
    color: #252328;
}
.rsvp-summary-section {
    margin-bottom: 30px;
}
p:last-child {
    margin: 0px;
}
.summary-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #6f6d72;
}
.guest-list-block, .budget-list-block {
    font-size: 18px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0;
}

.card-header-tabs .nav-item {
    font-size: 14px;
}
.nav-tabs .nav-item {
    margin-bottom: 5px;
}
.card-header-tabs .nav-item .nav-link.active {
    color: #0079df;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.guest-list-table table th {
    background-color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252324;
}
table th{
  border-top: none;
}
.guest-list-table .table {
    font-size: 14px;
}
.card-header-tabs .nav-item .nav-link {
    color: #6f6d72;
    font-size: 16px;
    padding: 10px 30px;
}
.guest-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
table{
  color: #5f5f5f;
}
.red--text{
  color: red;
}
.btn{
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
}
.btn-default{
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn-default:hover {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
</style>