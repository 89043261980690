var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-sidebar offcanvas-collapse",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"vendor-user-profile"},[_c('h3',{staticClass:"vendor-profile-name"},[_vm._v(_vm._s(_vm.admin.name))]),_c('a',{staticClass:"edit-link",attrs:{"href":"#"}},[_vm._v("fromyourtutor.com")])]),_c('div',{staticClass:"dashboard-nav",staticStyle:{"height":"63vh","overflow-y":"auto"}},[(_vm.isMobile)?_c('ul',{staticClass:"list-unstyled"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)]):_vm._e(),(!_vm.isMobile)?_c('ul',{staticClass:"list-unstyled"},[_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27)]):_vm._e()]),(_vm.isMobile)?_c('span',{attrs:{"id":"mob"}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/dashboard#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-th-large"})]),_vm._v("Dashboard")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/tutors#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-users"})]),_vm._v("Tutors")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/students#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-users"})]),_vm._v("Students")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/counselling#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-users"})]),_vm._v("Counselling")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/video-courses#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-video"})]),_vm._v("Video Courses")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/live-classes#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-object-group"})]),_vm._v("Live Classes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/shop#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-book"})]),_vm._v("Shop")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/payments#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-dollar-sign"})]),_vm._v("Payments")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/gallery#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-camera"})]),_vm._v("Gallery")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/orders#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-cart"})]),_vm._v("Orders")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/testimonial#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-eye"})]),_vm._v("Testimonials")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/sliders#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-eye"})]),_vm._v("Sliders")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/chat-settings#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-comments"})]),_vm._v("Chat Pool")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/settings#mob"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-cog"})]),_vm._v("Primary Settings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/dashboard"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-th-large",attrs:{"aria-hidden":"true"}})]),_vm._v("Dashboard")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/tutors"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-users",attrs:{"aria-hidden":"true"}})]),_vm._v("Tutors")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/students"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-users",attrs:{"aria-hidden":"true"}})]),_vm._v("Students")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/counselling"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-users"})]),_vm._v("Counselling")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/video-courses"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-video",attrs:{"aria-hidden":"true"}})]),_vm._v("Video Courses")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/live-classes"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-object-group"})]),_vm._v("Live Classes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/shop"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-book",attrs:{"aria-hidden":"true"}})]),_vm._v("Shop")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/payments"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-dollar-sign"})]),_vm._v("Payments")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/gallery"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-camera"})]),_vm._v("Gallery")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/orders"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa-solid fa-cart-shopping"})]),_vm._v("Orders")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/testimonial"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-eye"})]),_vm._v("Testimonials")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/sliders"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-eye"})]),_vm._v("Sliders")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/chat-settings"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fas fa-comments"})]),_vm._v("Chat Pool")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/tutn-auth/settings"}},[_c('span',{staticClass:"dash-nav-icon"},[_c('i',{staticClass:"fa fa-cog",attrs:{"aria-hidden":"true"}})]),_vm._v("Primary Settings")])])
}]

export { render, staticRenderFns }