<template>
  <div class="about-us">
    <div class="space-vision pb-0">
      <div class="space-medium">
        <div class="container">
          <div class="row">
            <!--  about-details  -->
            <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2>History of the academy</h2>
              <p>The academy started off as a small personal platform where we would help someone learn the Islamic subjects and academic subjects they wished to at home and the demand for this grew later on.</p>
              <div class="card">
                <h3 class="card-header bg-white">General Questions</h3>
                <div class="card-body">
                  <div class="faq-qa-block">
                    <h4>Who owns the academy?</h4>
                    <p>The owner of the academy is a follower of the salafi manhaj and takes from students of knowledge such us Abu Khadeeja and the brothers from Salafi Publications, and those affiliated with them. </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Our path :  </h4>
                    <p>We follow the Quran and Sunnah, follow the manhaj of the first three generations (pious predecessors), as well as past, and current day scholars walilaahilhamd. 
                      We call to what is all khair and beneficial for the community to the best of our abilities to facilitate in both worlds. 
                      <a href="https://youtu.be/aqphL-1JxtQ" target="_blank"> https://youtu.be/aqphL-1JxtQ</a>
                    </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>How do you ensure authenticity of our Islamic studies materials? </h4>
                    <p>The Islamic materials we  use are from well known resources alhamdulillah. The workbooks were being checked over by various students of knowledge (Usthaad Abu Inaaya Seif , Usthaad Saeed Rhana and the likes may Allah preserve them all) and any questions or doubts we ask the students of knowledge we mentioned in the following q&a</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Do you have a student of knowledge who can attest to your correctness? </h4>
                    <p>We appreciate everyone's sincere intentions to stick to the correct path. Allaahumma Baarik! That's indeed necessary in these times of fitna.But we are doing our best to help the community to the best of our abilities seeking answers and help from scholars and students of knowledge. But taking their name to promote our private platform , we don't want to do that. If someone is willing to extend their hand on this project for the sake of Allah we are happy to acknowledge that but not for promotional purposes. 
                    </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Will any Students of knowledge vouch for your platform fromyourtutor.com? </h4>
                    <p>We don't use any students of knowledge 's name to promote our platform as it's our private service and not related to any Markaz or masjid.We are doing this as a service especially to less previliged with our experience and qualifications by hiring qualified teachers or individuals who can do what we expect to do and train them for online teaching. 
                    </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Mission and Vision of the Al- Khair Academy?</h4>
                    <p>Our goal is to support and nurture the children's and our own natural desire to be life-long learners until we meet our Lord. We are committed to the families we serve, providing support and encouragement through various services we provide  and our Vision is a community where all children feel loved, respected and encouraged to develop to their fullest potential and hence we focus on to provide a stimulating early Islamic tarbiyya ( learning ) and child care experience which promotes each child’s social/emotional, physical and cognitive development.
                    <a href=" https://youtu.be/AWcx3cqsLOA" target="_blank"> https://youtu.be/AWcx3cqsLOA</a> 
                    </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Do you connected with any students of knowledge ? </h4>
                    <p>We do clear doubts from all students of knowledge if we have some genuine questions, doubts from our teachers , from students and their parents . But we may not stress our platform name for the same as our intentions to seek knowledge is for the sake of Allah and to rectify our own affairs then sharing for others benefits 
                      (Doubts and Questions are always posted to below students of knowledge and if they give us a reply alhamdhullilah 
                      Because personally we know they are too busy serving the community and helping Muslims with their time and we do respect that walilaahilhamd) 
                    </p>
                    <ol>
                      <li>Usthaad Saeed Rhana Hafidhahullah </li>
                      <li>Usthaad Moosa Richardson hafidhahullah </li>
                      <li>Usthaad Musthafa George hafidhahullah </li>
                      <li>Usthaad Abu Muad Taqweem hafidhahullah </li>
                      <li>Usthaad Abu Khadeeja hafidhahullah </li>
                      <li>Usthaad Raha Batts Hafidhahullah </li>
                      <li>Usthaad Abu Innaya Seif Hafidhahullah and the likes</li>
                    </ol>
                    <p>( May Allah سبحانه وتعالى preserve all of them upon goodness and rectify all of our affairs) </p>
                    <a href="https://t.me/salafihelpline/10" target="_blank">https://t.me/salafihelpline/10</a>
                  </div>
                  <div class="faq-qa-block">
                    <h4>What about Al-Khair Academy's (FYT)  Curriculum? </h4>
                    <p>WE USE AMERICAN CURRICULUM AS OUR BASE BUT OUR LESSONS ARE INSPIRED AND DEVELOPED (ON ISLAMIC VALUES ) FROM IT RATHER THAN BEING FROM A SET BOOK.WE FOCUS ON SKILL DEVELOPMENT: HELPING HOMESCHOOL CHILDREN WITH A STRUCTURED LEARNING SUPPORT PROGRAM.</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>How do we ensure authenticity of our Islamic studies materials?</h4>
                    <p>We base all our workbook on authentic students of knowledge and classes. Our pre- Kg Islamic studies curriculum was checked and reviewed by Usthaad Abu Inaaya Seif Hafidhahullah </p>
                    <p>Words of usthaad: </p>
                    <p><i>❝Al-hamdu lillah I can access it and I'm working my way through each one, very impressed so far, may Allah make these resources of great benefit.Allahumma Bärik I found the content to be excellent and very well thought out and put together with the focus being to nurture children upon the true fitrah! Best of all it is all correct and authentic. May Allah bless all those involved for their efforts and hardwork. in shã Alläh I will continue to look through the rest as you upload them to the drive and provide any feedback.❞ </i></p>
                    <p> We send our other materials to (eg: Rainbow puzzle solved) to Usthaad Saeed Rhana hafidhahullah  and the likes depending on who is willing  to take it</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>How is the learning process and other aspects of the academy? </h4>
                    <p>All classes are taught online via zoom as live sessions insha Allah.Even if the sessions aren't 1-1 they include the needs of all the students within the group and assess their abilities. We always encourage our teachers to make sure the classes are catered towards students level and abilities.Classes may be a few days a week so whilst concepts are explained in class the students will be assigned a combination of traditional homeworks as well as creative assignments in order to motivate them to learn and encourage creativity in education.It's very important they do the homework so we encourage parents to help them in this aspect insha Allah.Admins will be available to take feedback insha Allah so if you feel your children's classes need adjustments for them we're always happy to receive feedback that will ensure classes are as beneficial and enjoyable as possible for them insha Allah.</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Can you brief me how your tiny tots classes work?  </h4>
                    <p>We do the learning through stories method. The children will listen to stories and their comprehension will be tested along with word understanding. Then the activities and crafts will be based off it insha Allah and within the same subject.</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Who can benefit from your EShop? </h4>
                    
                    <p>Insha Allah they are suitable for a variety of levels and can be adapted to fit multiple ages. The have a lot of information  Allahumma baarik whilst being explained in a simple manner.Pre-KG level will need assistance and to be broken down further. Rather than focusing on the proofs for them focus on asking questions with mainly short or yes or no answers until they understand the concept.Older children can benefit too and can memorise or understand the proofs given. We will be uploading many workbooks and pdf to help sisters , single moms as well which include mental health topics and video course in near future so that any one can learn at their own pace with out time limit . Our Hijama recorded course is a huge success with Allah’s mercy .
                    </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>How to book your counselling services?</h4>
                    <p>Go to the form and fill it <a href=" https://form.jotform.com/213561984696875" target="_blank"> https://form.jotform.com/213561984696875</a></p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>How to register a student? </h4>
                    <p>Please go to student registration link <a href="https://fromyourtutor.com/student-registration" target="_blank">https://fromyourtutor.com/student-registration</a> </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>What about fee structure? </h4>
                    <p>For academic year plan contact <a href="mailto:"> admin@fromyourtutor</a> </p>
                    <p>For one to one any subjects including islamic studies it will be $8 for 30 minutes class via zoom. </p>
                    <p>Private Group classes will be given package accordingly.</p>
                    <p>Remember: we provide a quality service for less than the cost of an affordable meal</p>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2>History of the academy</h2>
              <p>The academy started off as a small personal platform where we would help someone learn the Islamic subjects and academic subjects they wished to at home and the demand for this grew later on.</p>
              <div class="card">
                <h3 class="card-header bg-white">General Questions</h3>
                <div class="card-body">
                  <div class="faq-qa-block">
                    <h4>When was Al-Khair Academy (FYT) founded ?</h4>
                    <p>Al- Khair FYT was founded in 2021. The academy started off as a small personal platform where we would help someone learn the Islamic subjects and academic subjects they wished to at home and the demand for this grew later on. Now our academic program extends to four grade levels spanning preschool through 4 th grade .</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Tell us  briefly about your  program! </h4>
                    <p>Our program includes a thorough academic curriculum that integrates genuine Islamic Studies, Quran, and Arabic instruction. Passionate live instructors provide safe and convenient classes from the comfort of your home.

                    </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Mission and Vision of the Al- Khair Academy</h4>
                    <p>Our goal is to support and nurture the children's and our own natural desire to be life-long learners until we meet our Lord. We are committed to the families we serve, providing support and encouragement through various services we provide and our Vision is a community where all children feel loved, respected and encouraged to develop to their fullest potential and hence we focus on to provide a stimulating early Islamic tarbiyya ( learning ) and child care experience which promotes each child’s social/emotional, physical and cognitive development.
</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>What about Al-Khair Academy's (FYT) Curriculum?</h4>
                    <p>WE USE AMERICAN CURRICULUM AS OUR BASE BUT OUR LESSONS ARE INSPIRED AND DEVELOPED (ON ISLAMIC VALUES ) FROM IT RATHER THAN BEING FROM A SET BOOK.WE FOCUS ON SKILL DEVELOPMENT: HELPING HOMESCHOOL CHILDREN WITH A STRUCTURED LEARNING SUPPORT PROGRAM.
</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>How is the learning process and other aspects of the academy?
</h4>
                    <p>All classes are taught online via zoom as live sessions.Even if the sessions aren't 1-1 they include the needs of all the students within the group and assess their abilities. We always encourage our teachers to make sure the classes are catered towards students level and abilities.Classes may be a few days a week so whilst concepts are explained in class the students will be assigned a combination of traditional homeworks as well as creative assignments in order to motivate them to learn and encourage creativity in education.It's very important they do the homework so we encourage parents to help them in this aspect.Admins will be available to take feedback, so if you feel your children's classes need adjustments for them we're always happy to receive feedback that will ensure classes are as beneficial and enjoyable as possible for them.

</p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Can you brief me how your tiny tots classes work?
</h4>
                    <p>We do the learning through stories method. The children will listen to stories and their comprehension will be tested along with word understanding. Then the activities and crafts will be based off it insha Allah and within the same subject.

                    </p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Who can benefit from your EShop?
 </h4>
                    <p>They are suitable for a variety of levels and can be adapted to fit multiple ages. The have a lot of information whilst being explained in a simple manner.

                    </p>
                    <p>We will be uploading many workbooks and pdf to help sisters , single moms as well which include mental health topics and video course in near future so that any one can learn at their own pace with out time limit . Our cupping course , counseling courses all  are  a huge success with Allah’s mercy .

</p>
                    <p>Click the link to access eshop </p>
                    <a href="https://fromyourtutor.com/study_materials" target="_blank">https://fromyourtutor.com/study_materials</a>
                  </div>
                  <div class="faq-qa-block">
                    <h4>How to book your counselling services?</h4>
                    <p>Please go to student registration link <a href="https://fromyourtutor.com/student-registration" target="_blank">https://fromyourtutor.com/student-registration</a></p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>How to access video courses ?</h4>
                    <p>Click the link and choose accordingly 
</p>
                    <p><a href="https://fromyourtutor.com/video_course" target="_blank">https://fromyourtutor.com/video_course</a></p>
                  </div>
                  <div class="faq-qa-block">
                    <h4>Does you plan to offer Higher grades in the future?
</h4>
                    <p>Indeed, we are fully committed to providing this service. As we embark on each new academic year, we will progressively incorporate additional grade levels into our program. This expansion will be a result of our continuous growth in enrollment and our meticulous pursuit of our predetermined objectives through strategic planning.</p>
                  </div>
           
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq"
}
</script>

<style scoped>
.page-header{
  border-bottom: transparent;
  background: #ce0d3d;
  min-height: 189px;
}
.page-caption{
  padding-top:30px;
  padding-bottom:32px;
}
.page-title{
      font-size: 46px;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
}
.page-breadcrumb {
    background-color: rgba(0, 0, 0, .5);
    border-top: rgba(255, 255, 255, .1) 1px solid;
}
.page-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 13px;
}
.breadcrumb{
    margin-bottom: 8px;
    padding-left: 2px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link {
    color: #bebbc1;
    text-decoration: none;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link:hover {
    color: #0079df;
}
/* .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #bebbc1;
    content: "\f105";
    font-family: 'font Awesome 5 Free';
    font-weight: 900;
} */
.circle-icon {
    height: 125px;
    width: 125px;
    font-size: 60px;
    line-height: 2.1;
    padding: 0px 33px;
    color: #fff;
    display: inline-block;
}
.bg-default {
    background-color: #0079df !important;
}
h2 {
    font-size: 28px;
    line-height: 38px;
}
.faq-qa-block{
    margin-bottom: 20px !important;
border-bottom: 1px solid #e6e5e7;
padding-bottom: 20px;
}
.faq-qa-block h4:first-child{
  margin-top: 0px;
}
.about-us h3.card-header{
  margin-top: 10px;
}
.lead{
  font-size: 21px;
    font-weight: 400;
    color: #252328;
    line-height: 31px;
    font-family: 'Playfair Display', serif;
}
.metrics {
    padding: 70px !important;
    text-align: center;
}
.metrics-title {
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 36px;
}
.metrics-subtitle {
    font-size: 56px;
    line-height: 64px;
}
.space-medium{
    padding-top: 50px;
    padding-bottom: 80px;
}
.section-title {
    margin-bottom: 50px;
}
.section-title h2 {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 36px;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
    margin-bottom: 15px;
}
.section-title h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 36px;
}
.heading-btm-line {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #0079df;
    padding-bottom: 10px;
    display: inline-block;
}
p:last-child {
    margin: 0px;
}
</style>