<template>
  <div class="dashboard-content">
    <div class="container pt-7">
      <div class="card">
        <div class="card-header">Selected Subjects & Grades</div>
        <div class="card-body">
          <form action="submit">
            <div class="personal-form-info">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="subjects">Selected Subjects</label>
                    <v-text-field v-model="subjects" outlined readonly></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="subjects">Selected Grades</label>
                    <v-text-field v-model="grades" outlined readonly></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="subjects">Accent</label>
                    <v-text-field v-model="accent" outlined readonly></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'student-category',
  data(){
    return {
      subjects:[],
      grades:[],
      accent:null,
    }
  },
  beforeMount(){
    let tutor_token = this.$cookies.get("tution-tutor").token;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+tutor_token}}).then(res => {
      this.tutor = res.data.data;
      this.accent = (this.tutor.accent.name)?this.tutor.accent.name:null;
      this.tutor.grades.forEach(grade => {
        this.grades.push(grade.grade.name)
      });
      this.tutor.subjects.forEach(sub => {
        this.subjects.push(sub.subject.subject_name)
      })
    })
  },
  methods:{

  }
}
</script>
<style scoped>
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 1px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
</style>