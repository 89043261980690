<template>
  <div class="dashboard-sidebar offcanvas-collapse" id="sidebar">
    <div class="vendor-user-profile">
      <div class="vendor-profile-img">
        <img src="../../assets/images/dashboard-profile.jpg" alt="" class="rounded-circle" v-if="!profile_pic" style="pointer-events:none;">
        <img :src="profile_pic" alt="" class="rounded-circle" v-if="profile_pic" width="100" height="70" style="pointer-events:none;">
      </div>
      <h3 class="vendor-profile-name">{{student.name}}</h3>
      <a href="/student/profile" class="edit-link">Edit Profile</a>
    </div>
    <div class="dashboard-nav">
      <ul class="list-unstyled">
        <li><router-link to="/student/dashboard"><span class="dash-nav-icon"><i class="fas fa-compass"></i></span>Dashboard</router-link></li>
        <li><router-link to="/student/my-video-courses"><span class="dash-nav-icon"><i class="fas fa-download"></i></span>My Video Courses</router-link></li>
        <li><router-link to="/student/booked-classes"><span class="dash-nav-icon"><i class="fas fa-list-alt"></i> </span>Booked Classes</router-link></li>
        <!-- <li><router-link to="/student/chatroom"><span class="dash-nav-icon"><i class="fas fa-file-video"></i></span>Chatroom</router-link></li> -->
        <li><router-link to="/student/completed-classes"><span class="dash-nav-icon"><i class="fas fa-calendar-check"></i></span>Completed Classes</router-link></li>
        <li><router-link to="/student/downloads"><span class="dash-nav-icon"><i class="fas fa-download"></i></span>Downloads</router-link></li>
        <li><router-link to="/student/profile"><span class="dash-nav-icon"><i class="fas fa-user-circle"></i></span>My Profile</router-link></li>
        <li><router-link to="/student/favourite-tutors"><span class="dash-nav-icon"><i class="fas fa-heart"></i></span>Favourite Tutors</router-link></li>
        <li><a href="" v-on:click="logout"><span class="dash-nav-icon"><i class="fas fa-sign-out-alt"></i></span>Logout</a></li>
      </ul>
    </div>
     <span id="mob" v-if="isMobile"></span>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'student-sidebar',
  data(){
    return{
      student:null,
      profile_pic:null,
      isMobile:false,
    }
  },
  beforeMount(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
    this.student = (this.$cookies.get('tution-student'))?this.$cookies.get('tution-student'):null;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.student.token}}).then(res => {
      if(res.data.message == 'Student retrieved successfully'){
        this.profile_pic = res.data.data.student.profile_pic;
      }
    })
  },
  methods:{
    logout(e){
      e.preventDefault();
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/logout',{headers:{"Authorization":'Bearer '+this.student.token}}).then(res => {
        if(res.data.status == 1){
          this.$cookies.remove("cart-session-token");
          this.$cookies.remove("cart-session-key");
          this.$cookies.remove("tution-student");
          this.$router.replace('/login')
        }
      })
    }
  }
}
</script>

<style>
@media (max-width: 520px){
  
  .dashboard-content{
    margin-left: 0px !important;
  }
  #mob{
    display: block;
    margin-top: 20px;
    height: 10px;
  }
}
</style>


<style scoped>
.dashboard-sidebar {
    background-color: #fbfbfb;
    box-shadow: 0 0 1px 0 rgb(198 198 198 / 85%);
    margin-bottom: 50px;
    position: fixed;
    bottom: 0px;
    height: 100%;
    /* position:absolute;
    height: auto; */
    width: 260px;
    top: 60px;
    left: 0px;
}
.vendor-user-profile {
    padding: 15px;
    border-bottom: 1px solid #ebebeb;
    line-height: 1;
    display: inline-block;
    width: 100%;
}
.vendor-profile-name {
    margin-bottom: 0px;
    font-size: 16px;
    margin-top: 12px;
}
.edit-link {
    font-size: 12px;
    text-transform: capitalize;
    margin-top: 6px;
    display: block;
    color: #626262;
    text-decoration: none;
}
.dashboard-nav ul {
    margin-bottom: 0px;
    padding: 0px;
}
.dashboard-nav ul li a {
    color: #626262;
    font-size: 14px;
    font-weight: 400;
    display: block;
    line-height: 16px;
    padding: 14px;
    border-bottom: 1px solid #ebebeb;
    text-decoration: none;
}
.dash-nav-icon {
    margin-right: 8px;
    width: 20px;
    display: inline-block;
    text-align: center;
}
.dashboard-nav ul li a:hover {
    color: #ce0d3c;
    background-color: #ffe9e9;
}
.vendor-profile-img {
    margin-bottom: 10px;
    float: left;
    padding-right: 20px;
}
.vendor-profile-img img {
    width: 70px;
}
@media (max-width: 520px){
  .dashboard-sidebar{
    position: relative;
    width: auto;
  }
  .dashboard-content{
    margin-left: 0px;
  }
}
</style>