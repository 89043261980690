<template>
  <div class="about-us">
    <div class="space-vision pb-0">
        <div class="space-medium">
        <div class="container">
            <div class="row">
                <!--  about-details  -->
                <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <h2>Staff Directory</h2>
                    
 <h3>Know Our Founder & Managing Director </h3>
 <p>Our founder is <b class="prof-name">Umm Hamna Fatima </b> (<a href="https://linktr.ee/ummhamnafatima" target="_blank">https://linktr.ee/ummhamnafatima</a>), who is currently residing in Riyadh, Saudi Arabia with her family.  
</p>
<iframe class="mob-video" width="560" height="315" src="https://www.youtube.com/embed/G90UAq_Jwlw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<p><b>Her background : </b></p>
<ul>
    <li>Facilitator Learn How do read Quran course by Masjid As-Sunnah An-Nabawiyyah, GermanTown  </li>
    <li>Attended Shaikh Luhaidan Rahimahullah's class in masjid Riyadh where Usthaad Musthafa George hafidhahullah use to translate to English the live classes. </li>
<li>A student of Daar Ar Raid, Riyadh Saudi Arabia.  </li>
<li>Former teacher at Princess Noura University, Riyadh, Saudi Electronic University, Riyadh, Every Day Ibaadha Academy (online), TUITION CLUB SALAFI (online) MANCHESTER, Workbench (online) etc</li>
    <li>Teacher by profession Since 2003 
(Bachelor of Arts & Education, Masters, TEFL, CELTA, IELTS:  Life experiences in USA, Europe and currently settled in Riyadh, Saudi Arabia) 
</li>
<li>Also a double Certified Hijama Practitioner from United Kingdom </li>
    <li>Diploma in counseling ( ongoing) Australian University , NLP Certified, 
CBT certified etc 
</li>
</ul>
<h5><b>A very brief biography</b></h5>
<p>Coming from a teacher family , Umm Hamna Fathima and her family is in this teaching profession since 2003  but in July 2021 she created and registered this online platform as an educational support service, to continue her passion & profession with a mission and vision. 
</p><p>
<b>We registered our company name as Fromyourtutor.com</b> but chose the Kunya of the platform as <b>Al-Khair Academy</b> to accelerate our unique mission and vision<br>
</p><p class="text-left">Email: <a href="mailto:admin@fromyourtutor.com">admin@fromyourtutor.com</a> <br></p>


<h3>Academic and Finance Director</h3>
<p>
    <b class="prof-name">Professor Dr. Hamza VK (Abu Hamna Fatimah), PhD, HDR France</b></p>
    
  <p>  Currently working as Professor,
King Saud University,
Riyadh, Saudi Arabia </p>
<p>
Former Research Scientist at University of Arkansas, United States of America, University of Rennes, France <br>


Email: <a href="mailto:director@fromyourtutor.com">director@fromyourtutor.com</a> 
</p>
<h3>Director of Islamic Studies
</h3>
<p><b class="prof-name">Usthaad Ibrahim Ally M</b></p>
<p><b>2013-2015</b><br>
    (Diploma of Arabic Language)
Institute of Arabic Language King Saud University</p>
<p><b>2015-2020</b><br>
    (Degree of Tafseer of Quran and Hadeeth rasullah)
King Saud University

</p>
<p><b>2020-now</b><br>
    Master degree of Hadeth rasulillah
King Saud University</p>
<p>and among my teachers<br>
<b class="prof-name">1. Sheikh Al Allamah Swaalih Al Fauzan <br>
2. Sheikh Al Allamah Swaalih Al Luhaydaan <br>
3. Sheikh Muft Abdul Azizi Al Sheikh<br>
4. Sheikh Abdulrazack Al Badr <br>
5. Sheikh Abdallah Al Bukhariy</b><br>
Any many of them...
alhamdhullilah...
(May Allah preserve and forgive us and all of them) </p>
<p>Email: <a href="mailto:info@fromyourtutor.com">info@fromyourtutor.com</a> 
</p>
<h3>Principal </h3>
<p><b class="prof-name">Tiffany Mayhew<br>
(Summaya, Umm Muhammad)
</b></p>
<p><b>Principal Message</b></p>
<p>Assalaamu alayikkum 
</p>

<p>I am honoured  to welcome you to the Al-Khair Academy ( Fromyourtutor.com) , a fast growing online academy with Allah’s mercy which provides Islamic education, alongside the  core subjects along with other special services that our community need. </p>
<p>

We try our best to ensure that  students are offered  a balanced and broad level of education to meet their spiritual, moral, emotional, physical, and social needs.</p>
<p>

Our passionate and experienced teachers of both Islamic and core subjects are committed to working hard to help the students or the clients achieve their full potential. The feedback we are getting affirms the same. </p>
<p>

Al-Khair academy team works closely with the students and their families through online in order to develop their Akhlaq and Adhab with high standards of Islamic environment upon the path of our pious predecessors ( the first three blessed generations) </p>
<p>

We focus on the practical aspects which develops a student to become capable of finding solutions for the contemporary problems in the daily life of a Muslim. We provide counselling services whenever needed to parents and students through the AMCC counselling team of ours. </p>
<p>

We review our curriculum and make our materials mostly, and get it checked by those who are knowledgeable to ensure that it remains up to date and meets the current programme of studies as well as the needs of our students.</p>
<p>
Our Islamic  department provide quality lessons to memorise the Holy Qur’an, in addition to their regular subjects. Our ethos is that all students should ‘fly high halaal and stay halaal ’ not only as a good Muslim, but also as a good citizen wherever they are and be a good model to others.</p>
<p>
I hope that all of you feel proud of being a part of this blessed academy with a mission and vision to call to that is all Khair .</p>
<p><b class="prof-name">
Baarakhallaahu Feekum<br>
Tiffany Mayhew<br>
(Summaya, Umm Muhammad )<br>
United States of America <br><br></b>

<a href="mailto:principalalkhair@fromyourtutor.com">principalalkhair@fromyourtutor.com</a><br>
<a href="https://wa.me/message/JTZN7S4BQMOYC1" target="_blank">https://wa.me/message/JTZN7S4BQMOYC1</a></p>


<h3>Supervisor </h3>
<p><b class="prof-name">Umm Hurrairah Bint Humaid
</b></p>
<p><b>Her message :</b> <br>
    Assalaamu alayikkum 
</p>
<p>I am Umm Hurayrah, a teacher and supervisor at Al-Khair academy FromYourTutor.</p>
<p>My enjoyment of teaching began from a young age and my main reason has not changed. I believe that every student deserves to be recognised for the uniqueness and methods adapted in order to cater to as many people as possible. I also strongly believe a classroom (in person or virtual) should be a place everyone feels listened to and understood rather than everything dictated. This goes for students teachers and parents as I feel all have such an important role they equally share. These values are also the values of this academy which is why I'm passionate about my work here. </p>
<p>I value uniqueness, creativity and doing your best in whatever you do to allow ourselves and those we teach thrive. 
بارك الله فيكم
</p>
<p>
    <b class="prof-name"> Umm Hurraira<br>
    Summaya<br>
    United Kingdom</b>
</p>
<p>WhatsApp: <a href="https://wa.me/message/LGXKOWJHHJGSL1" target="_blank">https://wa.me/message/LGXKOWJHHJGSL1</a> <br>
Email : <a href="mailto:Supervisor@fromyourtutor.com">Supervisor@fromyourtutor.com</a></p>

              

                </div> -->

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <h2>Administrative Team</h2>
                    <p>

<b class="prof-name">Principal </b><br>
<b></b>Tiffany Mayhew<br>
Email: <a href="mailto:principalalkhair@fromyourtutor.com">principalalkhair@fromyourtutor.com</a>
</p>
<p><b class="prof-name">Managing Director</b><br>
Nazia BintAbdulHameed<br>
Email: <a href="mailto:admin@fromyourtutor.com">admin@fromyourtutor.com</a><br>
</p>
<p><b class="prof-name">Academic Director</b><br>
Professor Dr. Hamza VK <br>
PhD, HDR France<br>
Email: <a href="mailto:director@fromyourtutor.com">director@fromyourtutor.com</a><br>
</p>
<p><b class="prof-name">Directors  of Quran, Islamic Studies and Arabic</b><br>
Ibrahim Ally M & Hisham Haleem Ahmed Ibrahim<br>
Email: <a href="mailto:info@fromyourtutor.com">info@fromyourtutor.com</a></p>
<p><b class="prof-name">Supervisor</b><br>
Summaya Humaid United Kingdom<br>
Email: <a href="mailto:Supervisor@fromyourtutor.com">Supervisor@fromyourtutor.com</a>
</p>
                </div>
            </div>
        </div>
        </div>
    
    </div>
  </div>
</template>

<script>
export default {
  name: "Staff"
}
</script>

<style scoped>
.page-header{
  border-bottom: transparent;
  background: #ce0d3d;
  min-height: 189px;
}
.page-caption{
  padding-top:30px;
  padding-bottom:32px;
}
.page-title{
      font-size: 46px;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
}
.page-breadcrumb {
    background-color: rgba(0, 0, 0, .5);
    border-top: rgba(255, 255, 255, .1) 1px solid;
}
.page-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 13px;
}
.breadcrumb{
    margin-bottom: 8px;
    padding-left: 2px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link {
    color: #bebbc1;
    text-decoration: none;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link:hover {
    color: #0079df;
}
/* .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #bebbc1;
    content: "\f105";
    font-family: 'font Awesome 5 Free';
    font-weight: 900;
} */
.circle-icon {
    height: 125px;
    width: 125px;
    font-size: 60px;
    line-height: 2.1;
    padding: 0px 33px;
    color: #fff;
    display: inline-block;
}
.bg-default {
    background-color: #0079df !important;
}
h2 {
    font-size: 28px;
    line-height: 38px;
}
.faq-qa-block{
    margin-bottom: 20px !important;
border-bottom: 1px solid #e6e5e7;
padding-bottom: 20px;
}
.faq-qa-block h4::before{
content: "❓";
}
.lead{
  font-size: 21px;
    font-weight: 400;
    color: #252328;
    line-height: 31px;
    font-family: 'Playfair Display', serif;
}
.metrics {
    padding: 70px !important;
    text-align: center;
}
.metrics-title {
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 36px;
}
.metrics-subtitle {
    font-size: 56px;
    line-height: 64px;
}
.space-medium{
    padding-top: 50px;
    padding-bottom: 80px;
}
.section-title {
    margin-bottom: 50px;
}
.section-title h2 {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 36px;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
    margin-bottom: 15px;
}
.section-title h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 36px;
}
.heading-btm-line {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #0079df;
    padding-bottom: 10px;
    display: inline-block;
}
p:last-child {
    margin: 0px;
}
</style>