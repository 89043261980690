<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
          <div class="dashboard-page-header" v-if="student">
            <h3 class="dashboard-page-title" v-if="student.name">{{student.name}}</h3>
            <p class="d-block mb-0">View &amp; Edit Student Details</p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <div>
            <ul>
              <li class="d-flex justify-content-between align-items-center">
                Activate Profile
                <div class="switch-notification" v-if="student">
                  <label class="switch">
                    <input type="checkbox" id="activate-profile" @click="activateStudent" :checked="this.student.is_active=='Y'?true:false">
                    <span class="slider"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="profile">

        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header">
            <a class="btn btn-sm mr-1" :class="(tab=='profile')?'btn-default':'btn-outline-light'" @click="tab='profile'">Profile</a> 
            <a class="btn btn-sm mr-1" :class="(tab=='settings')?'btn-default':'btn-outline-light'" @click="tab='settings'">Settings</a> 
          </div>
        </div>
      </div>
      <div class="row">
        <Profile :student="student" v-if="tab=='profile' && student" />
        <Setting :student="student" v-if="tab=='settings' && student" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const Profile = () => import(/* webpackPrefetch: true */ "@/components/student-form-details/Profile.vue");
const Setting = () => import(/* webpackPrefetch: true */ "@/components/student-form-details/Settings.vue");
export default {
  name:'admin-student-form-details',
  components:{
    'Profile' : Profile,
    'Setting' : Setting,
  },
  data(){
    return{
      student: null,
      tab:'profile',
      activate:{
        status: null,
        student_id: null
      },
      reset_password:{
        password: null,
        new_password: null,
        new_password_confirmation: null,
      },
      
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student/form/'+this.$route.params.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      this.student = res.data.data;
      this.activate.status = this.student.is_active;
      this.activate.student_id = this.student.student_id;
    });
  },
  methods:{
    activateStudent(index,id){
      this.activate.status = (document.getElementById('activate-profile').checked == true)?'Y':'N';
      this.activate.student_id = id;
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/student/form_disable',this.activate,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Student updated successfully'){
          this.students[index].is_active = this.activate.status
        }else{
          this.students[index].is_active = (this.activate.status == 'Y')?'N':'Y';
        }
      });
    }
  }
}
</script>

<style scoped>
.switch {
    display: inline-block;
    width: 40px;
    height: 20px;
    transform: translateY(20%);
    position: relative;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
.switch input {
    display: none;
}
.slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    cursor: pointer;
    transition: .4s;
    background: #bdbdbd;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: white;
  transition: .4s;
  border-radius: 20px;
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
input:checked + .slider:before {
  transform: translateX(20px);
}
input:checked+.slider {
    background: #129e32;
}
.btn-outline-light:hover {
    color: #2e2f39;
    background-color: #f0f0f8;
    border-color: #f0f0f8;
}
.btn-outline-light {
    color: #2e2f39;
    background-color: transparent;
    border-color: #ebebed;
}
</style>