<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header mb-0 pb-3">
            <h3 class="dashboard-page-title">Payments</h3>
            <p class="d-block mb-2">View all payments details</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="guestlist-management">
            <div class="card">
              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs pl-0" id="myTab" role="tablist">
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'payments'}" @click="tab='payments'" id="allguest-tab" data-toggle="tab" role="tab" aria-controls="allguest">Payments</a>
                  </li>
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'donation'}" id="confirmguest-tab" @click="tab='donation'" data-toggle="tab" role="tab" aria-controls="confirmguest">Donation</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" :class="{'active show': tab == 'payments'}" id="allguest" role="tabpanel" aria-labelledby="allguest-tab">
                  <div class="tab-content" id="guest-filter-content">
                    <div class="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
                      <div class="guest-list-table table-responsive mb-0">
                        <div class="single-guest-tab">
                          <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                            <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                              <thead>
                                <tr role="row">
                                  <th>Sn.</th>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Amount</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Details</th>
                                </tr>
                              </thead> 
                              <tbody v-if="payments.length">
                                <tr v-for="(payment,index) in payments" :key="index">
                                  <td v-if="payment">{{index+1}}</td>
                                  <td v-if="payment">{{payment.created_at.split(' ')[0].split('-').reverse().join('-')}}</td>
                                  <td v-if="payment">{{payment.created_at.split(' ')[1]}}</td>
                                  <td v-if="payment.amount">{{payment.amount}}</td>
                                  <td v-if="payment.name">{{payment.name}}</td><td v-else>-</td>
                                  <td v-if="payment.email">{{payment.email}}</td><td v-else>-</td>
                                  <td v-if="payment.details">{{payment.details}}</td><td v-else>-</td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td class="empty-data text-center" colspan="6" rowspan="4">Empty Payment List</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" :class="{'active show': tab == 'donation'}" id="allguest" role="tabpanel" aria-labelledby="allguest-tab">
                  <div class="tab-content" id="guest-filter-content">
                    <div class="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
                      <div class="guest-list-table table-responsive mb-0">
                        <div class="single-guest-tab">
                          <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                            <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                              <thead>
                                <tr role="row">
                                  <th>Sn.</th>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Amount</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                </tr>
                              </thead> 
                              <tbody v-if="donations.length">
                                <tr v-for="(donation,index) in donations" :key="index">
                                  <td v-if="donation">{{index+1}}</td>
                                  <td v-if="donation.created_at">{{donation.created_at.split(' ')[0].split('-').reverse().join('-')}}</td>
                                  <td v-if="donation.created_at">{{donation.created_at.split(' ')[1]}}</td>
                                  <td v-if="donation.amount">{{donation.amount}}</td>
                                  <td v-if="donation.name">{{donation.name}}</td><td v-else>-</td>
                                  <td v-if="donation.email">{{donation.email}}</td><td v-else>-</td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td class="empty-data text-center" colspan="6" rowspan="4">Empty Donation List</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "admin-payments",
  data(){
    return {
      tab: "payments",
      payments:[],
      donations:[],
      activate:{
        status:false
      },
      loading:false,
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list/payment',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      if(res.data.status == 1 && res.data.payments.length > 0){
        res.data.payments.forEach(payment => {
          if(payment.type == 1){
            this.payments.push(payment);
          }else{
            this.donations.push(payment);
          }
        })
      }
    });
  }
}
</script>
<style scoped>
.rsvp-summary.card {
    box-shadow: none;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.summary-block {
    margin-bottom: 30px;
}
.bg-light-violate {
    background-color: #e1caf1 !important;
    border-color: #e1caf1;
}
.bg-light-green {
    background-color: #cfedad !important;
    border-color: #cfedad;
}
.card-body {
    padding: 20px;
}
.summary-count {
    font-size: 42px;
    color: #252328;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1;
}
.rsvp-summary .summary-text {
    color: #252328;
}
.rsvp-summary-section {
    margin-bottom: 30px;
}
p:last-child {
    margin: 0px;
}
.summary-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #6f6d72;
}
.guest-list-block, .budget-list-block {
    font-size: 18px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0;
}

.card-header-tabs .nav-item {
    font-size: 14px;
}
.nav-tabs .nav-item {
    margin-bottom: 5px;
}
.card-header-tabs .nav-item .nav-link.active {
    color: #0079df;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.guest-list-table table th {
    background-color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252324;
}
table th{
  border-top: none;
}
.guest-list-table .table {
    font-size: 14px;
}
.card-header-tabs .nav-item .nav-link {
    color: #6f6d72;
    font-size: 16px;
    padding: 10px 30px;
}
.guest-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
table{
  color: #5f5f5f;
}
.red--text{
  color: red;
}
.btn{
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
}
.btn-default{
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn-default:hover {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.red--text{
  color: red;
}
</style>