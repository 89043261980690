<template>
  <div class="about-us">
    <div class="space-vision pb-0">
      <div class="space-medium">
        <div class="container">
          <div class="row">
            <!--  about-details  -->

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2 class="mt-1">Enrollment for the academic year started</h2>
              <p>For any questions please visit our faq session <a href="/faq">Click here to view FAQ</a></p>
              <p>Download the academic calendar and go through the details carefully before asking any further questions. 
</p>
<a target="_blank" href="https://tution-site.s3.ap-south-1.amazonaws.com/test/Academic-Calendar-2024-2025.pdf" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100 calander-btn">📅 Download Academic Calendar</a>
<p>To enroll, students must submit the online student registration and pay the  associated registeration fees (20$) to ensure your student seat with us . 

</p>
<p>The enrollment process includes providing transcripts if any ,confirming commitment of attendance, and registering for classes.
</p>
<p>It is important to stay as up to date as possible with tuition payments in order to ensure a good standing with the academy. If payments become delinquent, it is imperative to contact administration to explore payment options that may bring account balance current . If not students entering virtual class room will be paused until the fee issue is solved or even lead to termination of studies . 
</p>
<p><input type="checkbox" id="consent" name="consent"> I am aware of the fee policy and agree to these terms and conditions </p>
<p>For any further assistance please contact <a href="mailto:admin@fromyourtutor.com"> admin@fromyourtutor.com</a></p>
<a href="/student-registration" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100 calander-btn">Register Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "academic-year"
}
</script>

<style scoped>
.calander-btn{
    max-width: 353px;
    font-size: 15px;
    color: #fff;
}
h4{
    font-weight: bold;
}
.page-header{
  border-bottom: transparent;
  background: #ce0d3d;
  min-height: 189px;
}
.page-caption{
  padding-top:30px;
  padding-bottom:32px;
}
.page-title{
      font-size: 46px;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
}
.page-breadcrumb {
    background-color: rgba(0, 0, 0, .5);
    border-top: rgba(255, 255, 255, .1) 1px solid;
}
.page-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 13px;
}
.breadcrumb{
    margin-bottom: 8px;
    padding-left: 2px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link {
    color: #bebbc1;
    text-decoration: none;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link:hover {
    color: #0079df;
}
/* .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #bebbc1;
    content: "\f105";
    font-family: 'font Awesome 5 Free';
    font-weight: 900;
} */
.circle-icon {
    height: 125px;
    width: 125px;
    font-size: 60px;
    line-height: 2.1;
    padding: 0px 33px;
    color: #fff;
    display: inline-block;
}
.bg-default {
    background-color: #0079df !important;
}
h2 {
    font-size: 28px;
    line-height: 38px;
}
.lead{
  font-size: 21px;
    font-weight: 400;
    color: #252328;
    line-height: 31px;
    font-family: 'Playfair Display', serif;
}
.metrics {
    padding: 70px !important;
    text-align: center;
}
.metrics-title {
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 36px;
}
.metrics-subtitle {
    font-size: 56px;
    line-height: 64px;
}
.space-medium{
    padding-top: 50px;
    padding-bottom: 80px;
}
.section-title {
    margin-bottom: 50px;
}
.section-title h2 {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 36px;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
    margin-bottom: 15px;
}
.section-title h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 36px;
}
.heading-btm-line {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #0079df;
    padding-bottom: 10px;
    display: inline-block;
}
p:last-child {
    margin: 0px;
}
</style>