<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">Favorite Tutors</h3>
            <p class="d-block">Here’s listing your favorite tutors.</p>
          </div>
        </div>
      </div>
      <div class="row" v-if="fav_tutors.length">
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" v-for="(fav,index) in fav_tutors" v-bind:key="fav.favorite_id">
          <div class="vendor-thumbnail">
            <div class="vendor-img zoomimg">
              <a href=""><img src="../../assets/images/dummy-tutor.jpg" alt="" class="img-fluid" style="pointer-events:none;"></a>
            </div>
            <div class="vendor-content" v-if="fav.tutor">
              <h2 class="vendor-title" v-if="fav.tutor.name"><a href="" class="title">{{fav.tutor.name}}</a></h2>
              <p class="vendor-address">Accent:<i v-if="fav.tutor.accent"> {{fav.tutor.accent.name}}</i> <br> Fee: Start From <span v-if="fav.tutor.salary">${{fav.tutor.salary.hourly}}/hr</span><span v-else>)</span></p>
              <div>
                <a v-on:click.prevent="bookClass(fav.tutorId)" class="btn btn-primary btn-theme text-white">Book a Class</a> 
                <a v-on:click.prevent="removeTutor(fav.tutorId,index)" class="btn btn-default ml-1" title="Remove from favorite"><i class="fa fa-trash text-white" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="col-lg-12 empty-data text-center" style="margin-top:55px;">
        No Favorite Tutors Added
        </div>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <!-- <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		 -->
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to delete these records? This process cannot be undone.</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click.prevent="$refs['confirm-modal'].hide();">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="removeFromFavorite">Delete</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "fav-tutor",
  data(){
    return {
      fav_tutors:[],
      record:{
        tutor_id:null,
        index:null
      }
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/favoritetutors',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
      if(res.data.message == 'Student retrieved successfully'){
        var fav_tutors = res.data.data;
        fav_tutors.forEach(fav => {
          if(fav.is_active == 'Y'){
            this.fav_tutors.push(fav);
          }
        })
      }
    });
  },
  methods:{
    removeTutor(id,index){
      this.record.tutor_id = id;
      this.record.index = index;
      this.$refs['confirm-modal'].show();
    },
    removeFromFavorite(e){
      e.preventDefault();
      axios.delete(process.env.VUE_APP_TUTION_SITE_API+'/favoritetutor/'+this.record.tutor_id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.message == 'Student favorite tutor deleted successfully'){
          this.$refs['confirm-modal'].hide();
          this.fav_tutors.splice(this.record.index,1);
        }
      })
    },
    bookClass(id){
      if(this.$cookies.get('tution-student')){
        this.$router.push('/pricing?id='+id);
      }else{
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped>
.zoomimg {
    position: relative;
    overflow: hidden;
}
.zoomimg:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.vendor-img img {
    width: 100%;
}
.zoomimg img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.vendor-title {
    font-size: 20px;
    margin: 0px 0px 0px 0px;
}
.title {
    color: #252328;
    text-decoration: none;
}
a:hover {
    outline: 0;
    color: #ce0d3d;
    text-decoration: none;
}
.vendor-address {
    font-size: 12px;
    font-weight: 400;
    color: #6f6d72;
    line-height: 18px;
}
.btn.btn-theme {
    background: #ce0d3d;
    border-color: #ce0d3d;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.vendor-content {
    padding: 16px 0px 12px 12px;
    margin-bottom: 6px;
}

</style>