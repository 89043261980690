<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header">
              <h3 class="dashboard-page-title">Edit Video Courses</h3>
            <p class="d-block">Fill below details and add video courses</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <v-form ref="form" lazy-validation v-model="valid">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="title">Title<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field v-model="toAdd.name" :rules="[v => !!v || 'Enter title']" outlined></v-text-field>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="summernote">Descriptions </label>
                      <v-textarea v-model="toAdd.description" rows="6" placeholder="Write Descriptions about the course" outlined ></v-textarea>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="title">Price<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field placeholder="$" v-model="toAdd.price" :rules="[v => !!v || 'Enter price']" outlined></v-text-field>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="filebutton">Upload Image (For thumbnail & Slider)</label>
                      <v-file-input accept="image/*" v-model="toAdd.thumbnails" @change="addMultipleFiles"  prepend-icon="mdi-camera" multiple small-chips truncate-length="10" counter outlined>
                        <template v-slot:selection="{ index, text }">
                          <v-chip close @click:close="deleteChip(index, text)">{{ text }}</v-chip>
                        </template>
                      </v-file-input>
                      <v-progress-linear :value="thumbnail_progress" v-if="toAdd.thumbnails.length>0 && thumbnail_progress !== 100" color="light-blue" height="10" striped></v-progress-linear>
                      <div v-if="toAdd.uploaded_thumbnails" class="row" style="margin-left:30px;">
                        <div v-for="(thumb,index) in toAdd.uploaded_thumbnails" :key="index" class="col-1 ml-1" style="border:1px solid #ddd">
                          <img :src="thumb" :alt="'thumb'+index" height="50" width="50" style="pointer-events:none;">
                          <a v-on:click.prevent="deleteThumb(index)" :id="index" class="btn btn-outline-pink btn-xs" style="padding: 0 !important;position: relative;top: 10px;">DELETE</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <v-checkbox v-model="toAdd.female_only" label="Female Only"></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row module-title">
              <div class="col-md-6">
                <h4>Add Modules</h4>
              </div>
              <div class="col-md-6 text-right">
                <a href="" v-on:click.prevent="addModule()" class="btn add-module-btn btn-sm ">Add module +</a>
              </div>
            </div>
            <div class="card modules-card" v-for="(v_module, index) in toAdd.modules" :key="index">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <p class="moduleSn-badge">{{index+1}}.</p>
                    <span class="moduleClose-badge" v-on:click.prevent="removeModule(index)">X</span>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div>
                      <label class="control-label" for="filebutton">Module Title<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="v_module.name" :rules="[v => !!v || 'Enter title']" placeholder="Module Title" outlined ></v-text-field>
                    </div>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div>
                      <label class="control-label" for="filebutton">Module Description<span class="red--text"><strong>* </strong></span></label>
                      <v-textarea type="text" v-model="v_module.description" :rules="[v => !!v || 'Enter description']" placeholder="Module Description" outlined ></v-textarea>
                    </div>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div v-if="v_module.video_url&&v_module.is_active == 1">
                      <label class="control-label" for="filebutton">Upload Video<span class="red--text"><strong>* </strong></span></label>
                      <div class="row">
                        <div class="col-md-10" style="cursor:pointer;" v-on:click.prevent="play(v_module.video_url)">
                          <p>{{v_module.video_url}}</p>
                        </div>
                        <div class="col-md-2">
                          <a v-on:click.prevent="deleteVideo(index)" :id="index" class="btn btn-outline-pink btn-xs" style="padding: 0 !important;position: relative;top: 10px;">DELETE</a>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <label class="control-label" for="filebutton">Upload Video<span class="red--text"><strong>* </strong></span></label>
                      <v-file-input accept="video/*" v-model="v_module.video" @change="addFileToS3(v_module.video,'video','course_file',index)"  prepend-icon="mdi-paperclip" :rules="[v => !!v || 'Select video']" outlined></v-file-input>
                      <v-progress-linear :value="video_progress" v-if="v_module.video && video_progress !== 100" color="light-blue" height="10" striped></v-progress-linear>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div v-if="v_module.resource_url&&v_module.is_active==1">
                      <label class="control-label" for="filebutton">Upload Resourse File</label>
                      <div class="row">
                        <div class="col-md-10" style="cursor:pointe--r;">
                          <a :href="v_module.resource_url" target="_blank">{{v_module.resource_url}}</a>
                        </div>
                        <div class="col-md-2">
                          <a v-on:click.prevent="deleteResourceFile(index)" :id="index" class="btn btn-outline-pink btn-xs" style="padding: 0 !important;position: relative;top: 10px;">DELETE</a>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <label class="control-label" for="filebutton">Upload Resourse File</label>
                      <v-file-input v-model="v_module.resource" @change="addFileToS3(v_module.resource,'all','resource',index)" prepend-icon="mdi-paperclip" outlined></v-file-input>
                      <v-progress-linear :value="resource_progress" v-if="v_module.resource && resource_progress !== 100" color="light-blue" height="10" striped></v-progress-linear>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <b-button type="submit" class="btn btn-default" v-if="isUploading == 0" v-on:click.prevent="editVideoCourse">Submit</b-button>
              <b-button type="button"  class="btn btn-default" v-if="isUploading != 0"><i class="fa fa-spinner fa-spin"></i></b-button>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Course updated</div>
    </b-modal>
    <b-modal id="view_video" ref="video_player" size="lg" title="Video Player" hide-footer centered>
      <div class="container text-center">
        <video controls style="width:inherit;">
          <source :src="video_url" type="video/mp4">
          <source :src="video_url" type="video/ogg">
          Your browser does not support HTML video.
        </video>
      </div>
    </b-modal>
    <b-modal id="confirmation" ref="confirm-delete-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to delete these records?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="confirmDelete">Delete</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to edit these records?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="confirmEdit">Confirm</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
  
<script>
import AWS from "aws-sdk";
import axios from 'axios';
export default {
  name:'add-video-course',
  data(){
    return {
      valid:true,
      loading:false,
      toAdd:{
        name:null,
        description:null,
        price:null,
        female_only:false,
        thumbnails:[],
        thumb_files:[],
        uploaded_thumbnails:[],
        modules:[]
      },
      files:[],
      thumbnail_progress:0,
      resource_progress:0,
      video_progress:0,
      isUploading: 0,
      video_url:null,
      record:{
        type:null,
        index:null,
      },
    }
  },
  beforeMount(){
    let id = this.$route.params.id;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/view/modules/'+id, {headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      if(res.data.status == true && res.data.course.is_active == 1){
        let course = res.data.course;
        this.toAdd.id = course.id;
        this.toAdd.name = course.name;
        this.toAdd.price = course.price;
        this.toAdd.description = course.description;
        if(course.thumbnails.length > 0){
          course.thumbnails.forEach(thumb => {
            if(thumb.status == 1){
              this.toAdd.thumb_files.push({id:thumb.id,status:1,url:thumb.url});
              this.toAdd.uploaded_thumbnails.push(thumb.url);
            }
          })
        }

        if(course.modules.length){
          course.modules.forEach(module => {
            if(module.is_active == 1){
              let u_module = {};
              u_module.id = module.id;
              u_module.name = module.name;
              u_module.video_url = module.video_url;
              u_module.video = null;
              u_module.description = module.description;
              u_module.resource_url = module.resource_file_url;
              u_module.resource = null;
              u_module.is_active = 1;
              this.toAdd.modules.push(u_module);
            }
          })
        }
      }else{
        this.course = null;
      }
    });
  },
  methods:{
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    cancelDialog(){
      this.$refs.form.reset()
      this.dialog = false;
    },
    addModule(){
      this.toAdd.modules.push({name:null,video:null,resource:null, video_url:null, resource_url:null});
    },
    removeModule(index){
      this.record.index = index;
      this.record.type = 'module';
      this.$refs['confirm-delete-modal'].show();
    },
    deleteChip(index){
      this.toAdd.uploaded_thumbnails.splice((this.toAdd.uploaded_thumbnails.length-this.toAdd.thumbnails.length)+index,1);
      this.toAdd.thumb_files.splice((this.toAdd.thumb_files.length-this.toAdd.thumbnails.length)+index, 1);
      this.toAdd.thumbnails.splice(index, 1);
    },
    deleteThumb(index){
      // this.toAdd.thumb_files.find((thumb,index) => thumb.url == this.toAdd.uploaded_thumbnails[index]).status = 0;
      const thumb_index = this.toAdd.thumb_files.map(e => e.url).indexOf(this.toAdd.uploaded_thumbnails[index]);
      if(this.toAdd.thumb_files[thumb_index].id == 0){
        this.toAdd.thumb_files.splice(thumb_index, 1);
        this.toAdd.thumbnails.splice(index-(this.toAdd.uploaded_thumbnails.length-this.toAdd.thumbnails.length),1);
      }else{
        this.toAdd.thumb_files[thumb_index].status = 0;
      }
      this.toAdd.uploaded_thumbnails.splice(index,1);
    },
    addMultipleFiles(){
      this.toAdd.thumbnails.forEach(thumbnail => {
        if(!this.files.includes(thumbnail)){
          this.files.push(thumbnail);
          this.addFileToS3(thumbnail,'image','thumbnail',0)
        }
      });
      this.toAdd.thumbnails=[];
      this.toAdd.thumbnails = this.files;
    },
    addFileToS3(file,type,field_name,module_index){
      var i = module_index
      var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
      var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
      let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
    
      AWS.config.update({
        accessKeyId: keys.accessKeyId,
        secretAccessKey: keys.secretAccessKey,
        region: 'ap-south-1'
      });

      let s3BucketName = "tution-site"; 
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
      var timestamp = Math.floor(Date.now() / 1000);
      if(file){
        var data = (field_name=='resource')?new Blob([file]):new Blob([file], { type: "application/"+type });
        var extension = file.name.split('.')[file.name.split('.').length-1];
        var name = file.name.split('.')[0];
        this.isUploading++;
        var s3obj = new AWS.S3({
          params: {
            Bucket: s3BucketName,
            Key:
              'courses/'+field_name+'/'+ current_date+"/"+name+ timestamp + "."+extension,
            ACL: "public-read",
          },
        });
        var upload = s3obj.upload({ Body: data });
        let that = this;
        upload.on("httpUploadProgress", function (evt) {
          switch(field_name) {
              case 'resource': that.resource_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              case 'thumbnail': that.thumbnail_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              case 'course_file': that.video_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              default: break;
          }
        }).send((err, data) => {
          switch(field_name) {
              case 'resource': that.toAdd.modules[i].resource_url= data.Location; break;
              case 'thumbnail': that.toAdd.thumb_files.push({id:0,status:1,url:data.Location}); 
                                that.toAdd.uploaded_thumbnails.push(data.Location);
                                break;
              case 'course_file': that.toAdd.modules[i].video_url = data.Location; break;
              default: break;
          }
          this.isUploading--;
        });
      }
    },
    addVideoCourse(e){
      e.preventDefault();
      this.loading = true;
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/video_course',this.toAdd,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.status){
            this.$refs["success"].show();
            setTimeout(() => {
              this.$refs["success"].hide();
              this.$router.push("/tutn-auth/video-courses");
            },2000);
          }
        })
      }
    },
    deleteVideo(index){
      this.record.type = 'video';
      this.record.index = index;
      this.$refs['confirm-delete-modal'].show();
    },
    confirmDelete(){
      if(this.record.type == 'video'){
        this.toAdd.modules[this.record.index].video_url = null;
      }
      if(this.record.type == 'resource'){
        this.toAdd.modules[this.record.index].resource_url = null;
      }
      if(this.record.type == 'module'){
        this.toAdd.modules.splice(this.record.index,1);
      }

      this.$refs['confirm-delete-modal'].hide();
    },
    deleteResourceFile(index){
      this.record.type = 'resource';
      this.record.index = index;
      this.$refs['confirm-delete-modal'].show();
    },
    
    play(url){
      this.video_url = url;
      this.$refs['video_player'].show();
    },
    editVideoCourse(){
      this.$refs['confirm-modal'].show();
    },
    confirmEdit(e){
      e.preventDefault();
      this.$refs['confirm-modal'].hide();
      this.loading = true;
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        axios.put(process.env.VUE_APP_TUTION_SITE_API+'/video_course/'+this.toAdd.id,this.toAdd,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.status){
            this.$refs["success"].show();
            setTimeout(() => {
              this.$refs["success"].hide();
              this.$router.push("/tutn-auth/video-courses");
            },2000);
          }
        })
      }
    }
  }
}
</script>
  
<style scoped>
  .switch {
      display: inline-block;
      width: 40px;
      height: 20px;
      transform: translateY(20%);
      position: relative;
  }
  label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 14px;
      color: #5f5e61;
      line-height: 2.3;
  }
  .switch input {
      display: none;
  }
  .slider {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 20px;
      cursor: pointer;
      transition: .4s;
      background: #bdbdbd;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    background-color: white;
    transition: .4s;
    border-radius: 20px;
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  input:checked + .slider:before {
    transform: translateX(20px);
  }
  input:checked+.slider {
      background: #129e32;
  }
  .btn-outline-light:hover {
      color: #2e2f39;
      background-color: #f0f0f8;
      border-color: #f0f0f8;
  }
  .btn-outline-light {
      color: #2e2f39;
      background-color: transparent;
      border-color: #ebebed;
  }
  </style>