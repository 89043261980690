<template>
  <div class="about-us">
    <div class="space-vision pb-0">
        <div class="space-medium">
        <div class="container">
            <div class="row">
                <!--  about-details  -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <p>By purchasing any product or service you agree that:</p>
          <p>1. You respect gender segregation as part of Islamic etiquettes especially sisters only products are purchased for females.</p>
          <p>2. A Muslim woman, do not address men. So please agree to protect your menfolk and your sisters. You will not allow any males over the age of 10 to hear any of the sisters only classes, or audios or contact meetings (as applicable to your purchase). Sisters will listen in a room where they are unable to hear or you will use headphones.</p>
          <p>(Permission is granted to allow your menfolk to read written material included in your purchase (PDF workbooks / class notes etc) in order to enhance your understanding and discussions together to raise  awareness as a team)</p>
          <p>All purchases from Fromyourtutor.com are for your own personal use only.<br>
By purchasing any product or service you agree that:</p>
<p>1. You will not share your product or service access with others.</p>
<p>2. You will not resell any product or service</p>
<p>3. You will not replicate the products, tools or strategies in your purchase, for your own business or non-profit.
</p>
<p>I trust that you will fear Allah to uphold this agreement and honor the rights of our team</p>
<p>We look forward to meeting you inside our website  soon!<br>
As soon as you sign up you'll have immediate access so you can get started right away إِنْ شَاء اَللّٰه </p>
<p>Your login details are automatically emailed to you, please check your spam/junk folder or contact admin@fromyourtutor.com for any assistance.</p>
                </div>
            </div>
        </div>
        </div>
    
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms"
}
</script>

<style scoped>
.page-header{
  border-bottom: transparent;
  background: #ce0d3d;
  min-height: 189px;
}
.page-caption{
  padding-top:30px;
  padding-bottom:32px;
}
.page-title{
      font-size: 46px;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
}
.page-breadcrumb {
    background-color: rgba(0, 0, 0, .5);
    border-top: rgba(255, 255, 255, .1) 1px solid;
}
.page-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 13px;
}
.breadcrumb{
    margin-bottom: 8px;
    padding-left: 2px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link {
    color: #bebbc1;
    text-decoration: none;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link:hover {
    color: #0079df;
}
/* .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #bebbc1;
    content: "\f105";
    font-family: 'font Awesome 5 Free';
    font-weight: 900;
} */
.circle-icon {
    height: 125px;
    width: 125px;
    font-size: 60px;
    line-height: 2.1;
    padding: 0px 33px;
    color: #fff;
    display: inline-block;
}
.bg-default {
    background-color: #0079df !important;
}
h2 {
    font-size: 28px;
    line-height: 38px;
}
.lead{
  font-size: 21px;
    font-weight: 400;
    color: #252328;
    line-height: 31px;
    font-family: 'Playfair Display', serif;
}
.metrics {
    padding: 70px !important;
    text-align: center;
}
.metrics-title {
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 36px;
}
.metrics-subtitle {
    font-size: 56px;
    line-height: 64px;
}
.space-medium{
    padding-top: 50px;
    padding-bottom: 80px;
}
.section-title {
    margin-bottom: 50px;
}
.section-title h2 {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 36px;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
    margin-bottom: 15px;
}
.section-title h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 36px;
}
.heading-btm-line {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #0079df;
    padding-bottom: 10px;
    display: inline-block;
}
p:last-child {
    margin: 0px;
}
</style>