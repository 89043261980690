<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header mb-0 pb-3">
            <h3 class="dashboard-page-title">Orders</h3>
            <p class="d-block mb-2">View all order details</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="guestlist-management">
            <div class="card">
              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs pl-0" id="myTab" role="tablist">
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'order'}" @click="tab='order'" id="allguest-tab" data-toggle="tab" role="tab" aria-controls="allguest">Order</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" :class="{'active show': tab == 'order'}" id="allguest" role="tabpanel" aria-labelledby="allguest-tab">
                  <div class="tab-content" id="guest-filter-content">
                    <div class="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
                      <div class="guest-list-table table-responsive mb-0">
                        <div class="single-guest-tab">
                          <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                            <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                              <thead>
                                <tr role="row">
                                  <th>Sn.</th>
                                  <th>Date</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Material</th>
                                  <th>Amount</th>
                                  <th>Action</th>
                                </tr>
                              </thead> 
                              <tbody v-if="orders.length">
                                <tr v-for="(order,index) in orders" :key="index">
                                  <td v-if="order">{{index+1}}</td>
                                  <td v-if="order">{{order.created_at.split('T')[0].split('-').reverse().join('-')}}</td>
                                  <td v-if="order.name">{{order.name}}</td><td v-else>-</td>
                                  <td v-if="order.address">{{order.address}}</td><td v-else>-</td>
                                  <th ><p style="font-size:12px;color:#28a745;margin-bottom:0px;">{{order.item_type}}</p><p style="font-weight:600;">{{order.product_name}}</p></th>
                                  <td v-if="order.totalPrice">{{order.totalPrice}}</td>
                                  <td><a class="btn btn-outline-violate btn-xs mb-1" v-on:click.prevent="shareOrder(order.transactionID,order.address,order.products)" style="width:95px;height:40px;">SHARE</a></td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td class="empty-data text-center" colspan="6" rowspan="4">Empty Payment List</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-modal id="alert" ref="alert-modal" title=" " hide-footer hide-header centered body-bg-variant="success">
          <div class="container">
            <p class="text-center text-white">{{message}}</p>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
/*import AWS from "aws-sdk";*/
export default {
  name: "admin-orders",
  data(){
    return {
      tab: "order",
      orders:[],
      activate:{
        status:false
      },
      loading:false,
      message:'',
      order_success:false
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list/orders',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      if(res.data.status == true && res.data.orders.length > 0){
        res.data.orders.forEach(order => {
          order.products = JSON.parse(order.products);
          order.product_name = (order.products[0]&&order.products[0].Name)?order.products[0].Name:'';
          order.item_type = (order.products[0]&&order.products[0].item_type==3)?'Video Course':'E-Book';
            this.orders.push(order);
        })
      }
    });
  },
  methods:{
    shareOrder(transactionID,email,products){
      let Name = products[0].Name;
      let type = products[0].item_type;
      this.$refs['alert-modal'].show();
      this.message = 'sending...'
      var body;
      if(type == 3){
        body = {
            "eventType": "videoCourseShare",
            "share": {
                "email": email,
                "course_name": Name,
                "transactionID": transactionID
            }
        }
      }else{
          body = {
              "eventType": "pdfBookShare",
              "share": {
                  "email": email,
                  "product_name": Name,
                  "transactionID": transactionID
              }
          }
      }
      axios.post('https://fpurd8z9i3.execute-api.ap-south-1.amazonaws.com/test01/confirm-booking',body).then(res => {
        console.log(res);
        this.message = 'Message sent';
        setTimeout(() => {
          this.$refs['alert-modal'].hide();
        }, 2000);
      })
    }
  }
}
</script>
<style scoped>
.rsvp-summary.card {
    box-shadow: none;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.summary-block {
    margin-bottom: 30px;
}
.bg-light-violate {
    background-color: #e1caf1 !important;
    border-color: #e1caf1;
}
.bg-light-green {
    background-color: #cfedad !important;
    border-color: #cfedad;
}
.card-body {
    padding: 20px;
}
.summary-count {
    font-size: 42px;
    color: #252328;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1;
}
.rsvp-summary .summary-text {
    color: #252328;
}
.rsvp-summary-section {
    margin-bottom: 30px;
}
p:last-child {
    margin: 0px;
}
.summary-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #6f6d72;
}
.guest-list-block, .budget-list-block {
    font-size: 18px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0;
}

.card-header-tabs .nav-item {
    font-size: 14px;
}
.nav-tabs .nav-item {
    margin-bottom: 5px;
}
.card-header-tabs .nav-item .nav-link.active {
    color: #0079df;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.guest-list-table table th {
    background-color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252324;
}
table th{
  border-top: none;
}
.guest-list-table .table {
    font-size: 14px;
}
.card-header-tabs .nav-item .nav-link {
    color: #6f6d72;
    font-size: 16px;
    padding: 10px 30px;
}
.guest-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
table{
  color: #5f5f5f;
}
.red--text{
  color: red;
}
.btn{
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
}
.btn-default{
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn-default:hover {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.red--text{
  color: red;
}
</style>