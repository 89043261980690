<template>
  <section class="payment-section">
    <div class="payment-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12" v-show="!payed">
            <v-form class="md-float-material form-material" ref="form" lazy-validation v-model="valid">
              <div class="auth-box card">
                <div class="card-block" v-if="!QRCode">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="text-center" style="margin-bottom:15px !important;"><a href="/"><img src="../assets/images/logo.png" style="margin-bottom: 5px;pointer-events:none;" ></a><br>Payment</h5>
                    </div>
                  </div> 
                  <div class="amount">
                    <v-text-field type="amount" placeholder="$"  v-model="payment.amount" :rules="amountRules" required><template #label><span style="font-size:18px !important;">Amount in USD($)<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="email">
                    <v-text-field type="text" v-model="payment.email" :rules="[v => !!v || 'Required!']"><template #label><span>Email<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="name">
                    <v-text-field type="text" v-model="payment.name" :rules="[v => !!v || 'Required!']"><template #label><span>Name<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="course">
                    <v-text-field type="text" v-model="payment.course" :rules="[v => !!v || 'Required!']"><template #label><span>Course/Product<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="terms">
                    <v-checkbox class="mt-0" v-model="terms"></v-checkbox><span>I agree the <a href="/terms">Terms & Conditions and giving permission to send email to my mail</a></span>
                  </div>
                  <div class="row" v-if="!isPayment&&terms">
                    <div class="col-md-12">
                      <button class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-on:click.prevent="toPayment('paypal')"><b>Paypal or Credit Card</b></button>
                      <button class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100 text-white" v-on:click.prevent="toPayment('qr_code')"><b>GPay or QR Code</b></button>
                    </div>
                  </div>
                  <div id="paypal-button-container" v-show="isPayment"></div>
                </div>
                <div class="card-block" v-if="QRCode">
                  <i class="fa fa-arrow-left" aria-hidden="true" style="cursor:pointer;" v-on:click="goBack()"></i>
                  <div class="row">
                    <div class="col-md-12" style="text-align: center;background-color: #152b76;color: white;">
                      <span><strong>GPay  :  8111965400</strong></span>
                    </div>
                    <div class="col-md-12">
                      <img src="../assets/images/qr.jpg" alt="QR_Code">
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
          <div class="col-sm-12" v-show="payed">
            <div class="auth-box card">
              <div class="card-block text-center">
                <h4 class="text-success" style="line-height:30px !important;">Your payment has been completed successfully.</h4>
                <p><strong>Amount : {{ payment.amount }}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
        <div class="container text-center">
          Your payment has been completed successfully.
          <p v-if="payment.email">
            An email has to sent into your account.Kindly check your email for confirmation.
          </p>
        </div>
      </b-modal>
      <b-modal id="danger" ref="error" size="sm" title=" " body-bg-variant="danger" bod body-text-variant="light" hide-footer hide-header centered>
        <div class="container text-center">
          An error occurred while payment processing.
          <p>Please contact admin for further assistance.</p>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
// import AWS from "aws-sdk";
import axios from 'axios';
export default {
  name: 'Payment',
  data(){
    return{
      payment:{
        email: null,
        amount: null,
        name:null,
        course:null,
        type:1
      },
      terms:true,
      payed: false,
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      amountRules:[
        v => !!v || 'Enter valid amount',
        v => /\D/g.test(v) == false || 'Enter valid amount',
      ],
      loading:false,
      valid: true,
      isPayment:false,
      QRCode:false,
    }
  },
  mounted(){
    const script = document.createElement('script');
    script.src="https://www.paypal.com/sdk/js?client-id="+process.env.VUE_APP_CLIENT_ID+"&disable-funding=credit";
    script.addEventListener("load", () => this.setLoaded());
    document.body.appendChild(script);
  },
  methods:{
    toPayment(type){
      this.valid = this.$refs.form.validate();
      if(type == 'paypal'){
        if(this.valid){
          this.isPayment = true;
        }else{
          this.isPayment = false;
        }
      }else{
        if(this.valid){
          axios.post(process.env.VUE_APP_TUTION_SITE_API+'/store/payment',this.payment,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
              if(res.data.status === 1){
                this.QRCode = true;
              }
          })
        }
      }
    },
    goBack(){
      this.payment = {
        email: null,
        amount: null,
        name:null,
        course:null,
        type:1
      },
      this.QRCode = false;
    },
    setLoaded: function(){
      var self = this;
        window.paypal.Buttons({
          // Sets up the transaction when a payment button is clicked
          createOrder: function(data, actions) {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: self.payment.amount // Can reference variables or functions. Example: `value: document.getElementById('...').value`
                }
              }]
            })
          },
          // Finalize the transaction after payer approval
          onApprove: function(data, actions) {
            console.log(data,actions);
          return actions.order.capture().then(function() {
            axios.post(process.env.VUE_APP_TUTION_SITE_API+'/store/payment',self.payment,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
              if(res.data.status === 1){
                if(self.payment.email){
                    var body = {
                          "eventType": "purchase",
                          "payment": {
                              "email": self.payment.email,
                              "name": self.payment.name,
                              "course": self.payment.course,
                              "amount": self.payment.amount
                          }
                      }

                      axios.post('https://fpurd8z9i3.execute-api.ap-south-1.amazonaws.com/test01/confirm-booking',body).then(res => {
                        console.log(res);
                        self.$refs['success'].show();
                        setTimeout(function(){
                          self.$refs['success'].hide();
                          location.reload();
                        },3000)
                      })

                  // Handle promise's fulfilled/rejected states
                }else{
                  self.$refs['success'].show();
                  setTimeout(function(){
                    self.$refs['success'].hide();
                    location.reload();
                  },3000)
                }
              }else{
                self.$refs['error'].show();
                setTimeout(function(){
                  self.$refs['error'].hide();
                  location.reload();
                },3000)
              }
            });
            // var element = document.getElementById('paypal-button-container');
            // element.innerHTML = '';
            // element.innerHTML = '<h5>you\'re payment has been completed successfully.</h5>';
            self.payed = true;
          });
          }
        }).render('#paypal-button-container');
    }
  }
}
</script>
<style scoped>
.payment-section{
    background: url(../assets/images/couple-bg-img.jpg)no-repeat;
    background-size: cover;
    height: 100%;
}
.payment-block {
    margin: 30px auto;
    min-height: 93.6vh;
}
.payment-block .auth-box {
    margin: 20px auto 0;
    max-width: 450px !important;
}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
}
.card .card-block {
    padding: 1.25rem;
}
h5 {
    font-size: 16px;
    line-height: 26px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn:hover {
  color: #ffa25f;
}
.btn-lg {
    font-size: 14px;
    line-height: 1.3333333;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
a:active, a:hover {
    outline: 0;
    text-decoration: none;
}
a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
a {
    color: #252328;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}
.terms{
  display: flex;
  flex-direction: row;
}
</style>