<template>
  <section class="login-section">
    <div class="login-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <v-form class="md-float-material form-material" ref="form" lazy-validation v-model="valid">
              <div class="auth-box card">
                <div class="card-block">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="text-center" style="margin-bottom:15px !important;"><a href="/"><img src="../../assets/images/logo.png" style="margin-bottom: 5px;pointer-events:none;"></a><br>Tutor Login</h5>
                    </div>
                  </div> 
                  <div class="email">
                    <v-text-field type="text" :rules="emailRules" v-model="login.email" @keydown.space.prevent required><template #label><span style="font-size:18px !important;">Email<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="password">
                    <v-text-field type="password" v-model="login.password" :rules="[v => !!v || 'Enter valid password']" required><template #label><span style="font-size:18px !important;">Password<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="error-feedback" v-if="auth_error">{{auth_msg}}</div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-on:click.prevent="toLogin"><b>Login</b></button>
                    </div>
                  </div>
                  <div class="or-container">
                    <div class="line-separator"></div>
                    <div class="or-label">or</div>
                    <div class="line-separator"></div>
                  </div>
                  <br>
                  <p class="text-inverse text-center">Not yet an account <a href="/tutor_registration" data-abc="true" target="_blank">Signup Now</a></p>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="upload-details" id="tutor-details" title="Upload" hide-footer no-close-on-backdrop centered>
      <div class="container">
        <div class="tutor-details">
          <v-form class="md-float-material form-material" ref="uploadForm" lazy-validation v-model="valid_upload">
            <div class="row">
              <div class="col-md-12">
                  <v-file-input accept="image/*" v-model="upload.university_certificate" :rules="[v => !!v || 'Upload certificate']" label="University Certificate"></v-file-input>
              </div>
              <div class="col-md-12">
                  <v-file-input accept="video/*" v-model="upload.demo_class" label="Demo Class" prepend-icon="mdi-camera" show-size></v-file-input>
              </div>
            </div>
          </v-form>
        </div>
        <div class="modal-footer" style="border-top:none;">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
          <button type="button" class="btn btn-success" v-on:click="uploadTutorDetails" v-if="!loading">Submit</button>
          <button type="button"  class="btn btn-success" v-if="loading"><i class="fa fa-spinner fa-spin"></i></button>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import AWS from "aws-sdk";
import axios from 'axios';
export default {
  data(){
    return {
      valid: true,
      valid_upload:true,
      login:{
        email: null,
        password: null
      },
      upload:{
        demo_class:null,
        university_certificate:null
      },
      tutor:{
        name:null,
        token:null,
      },
      can_login:0,
      auth_error:false,
      auth_msg:null,
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      loading:false,
    }
  },
  methods:{
    async toLogin(e){
      e.preventDefault();
      this.can_login = 0;
      this.auth_error = false;
      this.auth_msg = '';

      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        try{
         await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tu_login',this.login,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
            if(res.status == 200){
              if(res.data.user_status == 1){
                this.tutor.name= res.data.name;
                this.tutor.token = res.data.data.access_token;
                this.$refs['upload-details'].show();
              }else{
                let tutor = {token:res.data.data.access_token,name:res.data.name}
                this.$cookies.set("tution-tutor", tutor);
                this.$router.push('/tutor/dashboard');
                location.reload();
              }
            }else{
              this.auth_error = true;
              this.auth_msg = 'Incorrect email or password';
            }
          })
        }catch(err){
          this.auth_error = true;
          this.auth_msg = 'Incorrect email or password';
        }
      }
    },
    hideModal(e){
      e.preventDefault();
      this.$refs['upload-details'].hide();
      this.upload.university_certificate = null,
      this.upload.demo_class = null
    },
    uploadTutorDetails(e){
      e.preventDefault();
      this.valid_upload = this.$refs.uploadForm.validate();
      if(this.valid_upload){
        this.loading = true;
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });
        let s3BucketName = "tution-site"; 
        var ceritificate = new Blob([this.upload.university_certificate], { type: "application/pdf" });
        var extension = this.upload.university_certificate.name.split('.')[this.upload.university_certificate.name.split('.').length-1]
        var d = new Date();
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
        var timestamp = Math.floor(Date.now() / 1000);

        // if (ceritificate.size < 20000000) {
          var s3obj = new AWS.S3({
            params: {
              Bucket: s3BucketName,
              Key:
                'tutor/certificate/'+ current_date+"/"+this.tutor.name + timestamp + "."+extension,
              ACL: "public-read",
            },
          });
          var upload = s3obj.upload({ Body: ceritificate });
          upload.on("httpUploadProgress", function (evt) {
              // uploadStatus == 0;
              //checkConnetionOffnOn(upload);
              var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
              console.log(per_uploaded);55/9
            })
            .send((err, data) => {
              this.upload.certificate = data.Location;
              if (this.upload.demo_class) {
                var demo_class = new Blob([this.upload.demo_class], { type: "video/mp4" });
                var demo_extension = this.upload.demo_class.name.split('.')[this.upload.demo_class.name.split('.').length-1]
                s3obj = new AWS.S3({
                  params: {
                    Bucket: s3BucketName,
                    Key:
                      'tutor/demo-video/'+ current_date+"/"+this.tutor.name + timestamp + "."+demo_extension,
                    ACL: "public-read",
                  },
                });
                upload = s3obj.upload({ Body: demo_class });
                upload.on("httpUploadProgress", function (evt) {
                    // uploadStatus == 0;
                    //checkConnetionOffnOn(upload);
                    var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                    console.log(per_uploaded);55/9
                  })
                  .send((err, data) => {
                    this.upload.demo_class = data.Location
                    this.submitDetails();
                  });
              }else{
                this.submitDetails();
              }
            });
        // }
      }else{
        console.log('empty filed');
      }
    },
    submitDetails(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor_update',this.upload,{headers:{"Authorization":'Bearer '+this.tutor.token}}).then(res => {
        if(res.data.message == 'Tutor Activated successfully'){
          this.loading = false;
          let tutor = {token:this.tutor.token,name:this.tutor.name}
          this.$cookies.set("tution-tutor", tutor);
          this.$router.replace('/tutor/dashboard')
          location.reload();
        }
      })
    }
  }
}
</script>

<style scoped>
.login-section{
    background: url(../../assets/images/couple-bg-img.jpg)no-repeat;
    background-size: cover;
    height: 100%;
}
.login-block {
    margin: 30px auto;
    min-height: 93.6vh;
}
.login-block .auth-box {
    margin: 20px auto 0;
    max-width: 450px !important;
}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
}
.card .card-block {
    padding: 1.25rem;
}
h5 {
    font-size: 16px;
    line-height: 26px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn:hover {
  color: #ffa25f;
}
.btn-lg {
    font-size: 14px;
    line-height: 1.3333333;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
a:active, a:hover {
    outline: 0;
    text-decoration: none;
}
a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
a {
    color: #252328;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}
</style>