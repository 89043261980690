<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
          <div class="dashboard-page-header" v-if="counselling">
            <h3 class="dashboard-page-title" v-if="counselling.name">{{counselling.name}}</h3>
            <p class="d-block mb-0">View &amp; Edit Counselling Details</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header">
            <a class="btn btn-sm mr-1" :class="(tab=='profile')?'btn-default':'btn-outline-light'" @click="tab='profile'">Profile</a> 
            <a class="btn btn-sm mr-1" :class="(tab=='info')?'btn-default':'btn-outline-light'" @click="tab='info'">Info</a> 
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="tab=='profile'">
          <div class="row" v-if="counselling">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Name<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="counselling.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined ></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Email<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="counselling.email" outlined readonly></v-text-field>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4">
              <label for="inputText3" class="col-form-label">Age<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="number" v-model="counselling.age" outlined hide-spin-buttons></v-text-field>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-8 col-8">
              <label for="inputText3" class="col-form-label">gender<span class="red--text"><strong>* </strong></span></label>
              <v-autocomplete :items="gender" item-text="text" item-value="value" v-model="counselling.gender" outlined></v-autocomplete>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Native Language<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="counselling.language" outlined hide-spin-buttons></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Country<span class="red--text"><strong>* </strong></span></label>
              <v-autocomplete :items="countries" item-text="text" item-value="value" v-model="counselling.country" @input="getRegions" outlined ></v-autocomplete>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Nationality<span class="red--text"><strong>* </strong></span></label>
              <v-autocomplete :items="countries" item-text="text" item-value="value" v-model="counselling.nationality" outlined ></v-autocomplete>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4">
              <label for="inputText3" class="col-form-label">Country Code<span class="red--text"><strong>* </strong></span></label>
              <v-autocomplete v-model="counselling.country_code" :items="codes" item-text="text" item-value="value" outlined ></v-autocomplete>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-8 col-8">
              <label for="inputText3" class="col-form-label">Mobile<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="counselling.mobile" :error-messages="(mob_error)?mob_msg:null" outlined></v-text-field>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4">
              <label for="inputText3" class="col-form-label">Country Code<span class="red--text"><strong>* </strong></span></label>
              <v-autocomplete v-model="counselling.whatsapp_code" :items="codes" item-text="text" item-value="value" outlined ></v-autocomplete>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-8 col-8">
              <label for="inputText3" class="col-form-label">Whatsapp Number<span class="red--text"><strong>* </strong></span></label>
              <v-text-field type="text" v-model="counselling.whatsapp_no" :error-messages="(mob_error)?mob_msg:null" outlined></v-text-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">I prefer <span class="red--text"><strong>* </strong></span></label>
              <v-select v-model="counselling.counsellor_type" :items="counsellor_type" item-text="text" item-value="value" :rules="[v => !!v || 'Select type']" outlined></v-select>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Choosed counsellor: <span class="red--text">*</span></label>
              <v-autocomplete v-model="counselling.counsellor" :items="counsellors" item-text="text" item-value="value" outlined></v-autocomplete>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">What days work best for you? <span class="red--text"><strong>* </strong></span></label>
              <v-autocomplete v-model="counselling.days" :items="days" item-text="text" item-value="value" outlined multiple chips deletable closable-chips></v-autocomplete>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">What time works best for you?<span class="red--text">*</span></label>
              <v-autocomplete v-model="counselling.timing" :items="timings" item-text="text" item-value="value" outlined multiple chips deletable closable-chips></v-autocomplete>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
              <label for="inputText3" class="col-form-label">Date<span class="red--text">*</span></label>
              <p>{{ counselling.date }}</p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
              <label for="inputText3" class="col-form-label">Time: <span class="red--text">*</span></label>
              <p>{{ counselling.time }}</p>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Payment Screenshot<span class="red--text">*</span></label>
              <img :src="counselling.payment" height="100" width="100"/>
            </div>
          </div>
        </div>
        <div v-if="tab=='info'">
          <div class="row" v-if="counselling">
            <ul>
              <li>
                <h5>Why are you seeking counseling for ? </h5>
                <p><i>{{ counselling.enrollment_info? counselling.enrollment_info: '-' }}</i></p>
              </li>
              <li>
                <h5>What is your major concern? When is the onset ( starting period or any special event) of your problems?</h5>
                <p><i>{{ counselling.problem_info? counselling.problem_info: '-' }}</i></p>
              </li>
              <li>
                <h5>What is your married status? If married, how long you are married?</h5>
                <p><i>{{ counselling.marriage_info? counselling.marriage_info: '-' }}</i></p>
              </li>
              <li>
                <h5>Are you working? Explain your professional life .</h5>
                <p><i>{{ counselling.professional_info? counselling.professional_info: '-' }}</i></p>
              </li>
              <li>
                <h5>Do you have children? If yes, how many? Any concerns on your parenting style ?</h5>
                <p><i>{{ counselling.parenting_info? counselling.parenting_info: '-' }}</i></p>
              </li>
              <li>
                <h5>Do you feel anxious or depressed? Explain physiological symptoms and your feelings related to that.</h5>
                <p><i>{{ counselling.physiological_info? counselling.physiological_info: '-' }}</i></p>
              </li>
              <li>
                <h5>Are you taking any medications? If yes, since when? Any note on that to help us like how do you feel? ( please note that we don't prescribe medications )</h5>
                <p><i>{{ counselling.medications_info? counselling.medications_info: '-' }}</i></p>
              </li>
              <li>
                <h5>Do you have any previous reports? (please attach below) Have you ever attended any therapy or counseling sessions before? If yes, explain .</h5>
                <p><i>{{ counselling.sessions_info? counselling.sessions_info: '-' }}</i></p>
              </li>
              <li>
                <h5>What do you expect from counseling sessions ? Any additional information to help us to help you?</h5>
                <p><i>{{ counselling.additional_info? counselling.additional_info: '-' }}</i></p>
              </li>
            </ul>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label mr-2">Previous Report</label>
              <a :href="counselling.report" target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="inputText3" class="col-form-label">Speak to us your problem</label>
              <audio id="player" :src="counselling.record" controls v-if="counselling.record"></audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'admin-counselling-form-details',
  data(){
    return{
      counselling: null,
      tab: 'profile',
      codes:[],
      mob_error:false,
      mob_msg:'',
      counsellor_type:[
        {value:1,text:'Male counsellor'},
        {value:2,text:'Female counsellor'},
        {value:3,text:'Male or Female'}
      ],
      timings:[
        {value:'Morning',text:'Morning'},
        {value:'Afternoon',text:'Afternoon'},
        {value:'Evening',text:'Evening'}
      ],
      days:[
        {value:'Monday',text:'Monday'},
        {value:'Tuesday',text:'Tuesday'},
        {value:'Wednesday',text:'Wednesday'},
        {value:'Thursday',text:'Thursday'},
        {value:'Friday',text:'Friday'},
        {value:'Saturday',text:'Saturday'},
      ],
      counsellors:[
        {value:1,text:'A member of British Association of Counselling and Psychotherapy $60.00'},
        {value:2,text:'Clinical Psychologist $30.00'},
        {value:3,text:'MALE COUNSELOR WITH ADVANCE COUNSELLING. $25.00'},
        {value:4,text:'COUNSELLOR WITH DIPLOMA IN COUNSELLING, CBT $25.00'},
        {value:5,text:'Certified Counselling Psychology Practitioner and Teenage therapist $20.00'}
      ],
      gender:[
        {value:1,text:'Male'},
        {value:2,text:'Female'},
        {value:3,text:'Other'}
      ],
      countries:[],
    }
  },
  beforeMount(){
    this.getCountries();
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/counselling_form/'+this.$route.params.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      if(res.data.status){
        this.counselling = res.data.data;
        this.counselling.country_code = parseInt(this.counselling.country_code);
        this.counselling.whatsapp_code = parseInt(this.counselling.whatsapp_code);
        this.counselling.counsellor_type = parseInt(this.counselling.counsellor_type);
        this.counselling.counsellor = parseInt(this.counselling.counsellor);
        this.counselling.days = this.counselling.days.split(',');
        this.counselling.timing = this.counselling.timing.split(',')
      }
    });
  },
  methods:{
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode})
        })
      })
    },
    getRegions(){
      this.countries.forEach(country => {
        if(country.value == this.register.country){
          this.counseling.country_code = {value:country.code, text:country.short+ '-' + country.code};
        }
      });
    },
  }
}
</script>

<style scoped>
.switch {
    display: inline-block;
    width: 40px;
    height: 20px;
    transform: translateY(20%);
    position: relative;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
.switch input {
    display: none;
}
.slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    cursor: pointer;
    transition: .4s;
    background: #bdbdbd;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: white;
  transition: .4s;
  border-radius: 20px;
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
input:checked + .slider:before {
  transform: translateX(20px);
}
input:checked+.slider {
    background: #129e32;
}
.btn-outline-light:hover {
    color: #2e2f39;
    background-color: #f0f0f8;
    border-color: #f0f0f8;
}
.btn-outline-light {
    color: #2e2f39;
    background-color: transparent;
    border-color: #ebebed;
}
</style>