<template>
  <div class="dashboard-sidebar offcanvas-collapse" id="sidebar">
    <div class="vendor-user-profile">
      <h3 class="vendor-profile-name">{{admin.name}}</h3>
      <a href="#" class="edit-link">fromyourtutor.com</a>
    </div>
    <div class="dashboard-nav" style="height: 63vh;overflow-y: auto;">
      <ul class="list-unstyled" v-if="isMobile">
        <li><a href="/tutn-auth/dashboard#mob"><span class="dash-nav-icon"><i class="fa fa-th-large"></i></span>Dashboard</a></li>
        <li><a href="/tutn-auth/tutors#mob"><span class="dash-nav-icon"><i class="fa fa-users"></i></span>Tutors</a></li>
        <li><a href="/tutn-auth/students#mob"><span class="dash-nav-icon"><i class="fa fa-users"></i></span>Students</a></li>
        <li><a href="/tutn-auth/counselling#mob"><span class="dash-nav-icon"><i class="fa fa-users"></i></span>Counselling</a></li>
        <li><a href="/tutn-auth/video-courses#mob"><span class="dash-nav-icon"><i class="fa fa-video"></i></span>Video Courses</a></li>
        <li><a href="/tutn-auth/live-classes#mob"><span class="dash-nav-icon"><i class="fas fa-object-group"></i></span>Live Classes</a></li>
        <li><a href="/tutn-auth/shop#mob"><span class="dash-nav-icon"><i class="fa fa-book"></i></span>Shop</a></li>
        <li><a href="/tutn-auth/payments#mob"><span class="dash-nav-icon"><i class='fas fa-dollar-sign'></i></span>Payments</a></li>
        <li><a href="/tutn-auth/gallery#mob"><span class="dash-nav-icon"><i class="fas fa-camera"></i></span>Gallery</a></li>
        <li><a href="/tutn-auth/orders#mob"><span class="dash-nav-icon"><i class='fas fa-cart'></i></span>Orders</a></li>
        <li><a href="/tutn-auth/testimonial#mob"><span class="dash-nav-icon"><i class='fas fa-eye'></i></span>Testimonials</a></li>
        <li><a href="/tutn-auth/sliders#mob"><span class="dash-nav-icon"><i class='fas fa-eye'></i></span>Sliders</a></li>
        <li><a href="/tutn-auth/chat-settings#mob"><span class="dash-nav-icon"><i class="fas fa-comments"></i></span>Chat Pool</a></li>
        <li><a href="/tutn-auth/settings#mob"><span class="dash-nav-icon"><i class="fa fa-cog"></i></span>Primary Settings</a></li>
      </ul>
      <ul class="list-unstyled" v-if="!isMobile">
        <li><a href="/tutn-auth/dashboard"><span class="dash-nav-icon"><i class="fa fa-th-large" aria-hidden="true"></i></span>Dashboard</a></li>
        <li><a href="/tutn-auth/tutors"><span class="dash-nav-icon"><i class="fa fa-users" aria-hidden="true"></i></span>Tutors</a></li>
        <li><a href="/tutn-auth/students"><span class="dash-nav-icon"><i class="fa fa-users" aria-hidden="true"></i></span>Students</a></li>
        <li><a href="/tutn-auth/counselling"><span class="dash-nav-icon"><i class="fa fa-users"></i></span>Counselling</a></li>
        <li><a href="/tutn-auth/video-courses"><span class="dash-nav-icon"><i class="fa fa-video" aria-hidden="true"></i></span>Video Courses</a></li>
        <li><a href="/tutn-auth/live-classes"><span class="dash-nav-icon"><i class='fas fa-object-group'></i></span>Live Classes</a></li>
        <li><a href="/tutn-auth/shop"><span class="dash-nav-icon"><i class="fa fa-book" aria-hidden="true"></i></span>Shop</a></li>
        <li><a href="/tutn-auth/payments"><span class="dash-nav-icon"><i class='fas fa-dollar-sign'></i></span>Payments</a></li>
        <li><a href="/tutn-auth/gallery"><span class="dash-nav-icon"><i class='fas fa-camera'></i></span>Gallery</a></li>
        <li><a href="/tutn-auth/orders"><span class="dash-nav-icon"><i class='fa-solid fa-cart-shopping'></i></span>Orders</a></li>
        <li><a href="/tutn-auth/testimonial"><span class="dash-nav-icon"><i class='fas fa-eye'></i></span>Testimonials</a></li>
        <li><a href="/tutn-auth/sliders"><span class="dash-nav-icon"><i class='fas fa-eye'></i></span>Sliders</a></li>
        <li><a href="/tutn-auth/chat-settings"><span class="dash-nav-icon"><i class="fas fa-comments"></i></span>Chat Pool</a></li>
        <li><a href="/tutn-auth/settings"><span class="dash-nav-icon"><i class="fa fa-cog" aria-hidden="true"></i></span>Primary Settings</a></li>
      </ul>
    </div>
    <span id="mob" v-if="isMobile"></span>
  </div>
</template>

<script>
export default {
  name: "admin-sidebar",
  data(){
    return {
      admin:null,
      isMobile:false,
    }
  },
  beforeMount(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
      this.admin = (this.$cookies.get('tution-admin'))?this.$cookies.get('tution-admin'):null;
  },
}
</script>
<style>
@media (max-width: 520px){
  
  .dashboard-content{
    margin-left: 0px !important;
  }
}
</style>

<style scoped>

.dashboard-sidebar {
    background-color: #fbfbfb;
    box-shadow: 0 0 1px 0 rgb(198 198 198 / 85%);
    margin-bottom: 50px;
    position: fixed;
    bottom: 0px;
    height: 100%;
    width: 260px;
    top: 60px;
    left: 0px;
}
.vendor-user-profile {
    padding: 15px;
    border-bottom: 1px solid #ebebeb;
    line-height: 1;
    display: inline-block;
    width: 100%;
}
.vendor-profile-name {
    margin-bottom: 0px;
    font-size: 16px;
    margin-top: 12px;
}
.edit-link {
    font-size: 12px;
    text-transform: capitalize;
    margin-top: 6px;
    display: block;
    color: #626262;
    text-decoration: none;
}
.dashboard-nav ul {
    margin-bottom: 0px;
    padding: 0px;
}
.dashboard-nav ul li a {
    color: #626262;
    font-size: 14px;
    font-weight: 400;
    display: block;
    line-height: 16px;
    padding: 14px;
    border-bottom: 1px solid #ebebeb;
    text-decoration: none;
}
.dash-nav-icon {
    margin-right: 8px;
    width: 20px;
    display: inline-block;
    text-align: center;
}
.dashboard-nav ul li a:hover {
    color: #ce0d3c;
    background-color: #ffe9e9;
}
@media (max-width: 520px){
  .dashboard-sidebar{
    position: relative;
    width: auto;
  }
  .dashboard-content{
    margin-left: 0px;
  }
  #mob{
    display: block;
    margin-top: 20px;
    height: 10px;
  }
}
</style>