var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-content"},[(_vm.student)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-10 col-md-9 col-sm-12 col-10"},[_c('div',{staticClass:"dashboard-page-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('h3',{staticClass:"dashboard-page-title"},[_vm._v("Hi, "+_vm._s(_vm.student.name))])]),_vm._m(0)]),_c('p',{staticClass:"d-block"},[_vm._v("Here’s your class details.")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"},[_c('div',{staticClass:"card card-summary"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"float-left"},[_c('div',{staticClass:"summary-count"},[_vm._v(_vm._s(_vm.hours)+"Hrs")]),_c('p',[_vm._v("Classes Completed")])]),_vm._m(1)]),_vm._m(2)])]),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"},[_c('div',{staticClass:"card card-summary"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"float-left"},[_c('div',{staticClass:"summary-count"},[_vm._v(_vm._s(_vm.upcoming_class))]),_c('p',[_vm._v("Upcoming Classes")])]),_vm._m(3)]),_vm._m(4)])]),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"},[_c('div',{staticClass:"card card-summary"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"float-left"},[_c('div',{staticClass:"summary-count"},[_vm._v(_vm._s(_vm.fav_tutors))]),_c('p',[_vm._v("Favorite Tutors")])]),_vm._m(5)]),_vm._m(6)])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2"},[_c('a',{staticClass:"btn btn-default",staticStyle:{"font-size":"12px !important","color":"white !important"},attrs:{"href":"/chat-pool","id":"trigger6"}},[_vm._v("Chat Pool")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary-icon"},[_c('i',{staticClass:"icon-051-wedding-arch"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-footer text-center"},[_c('a',{attrs:{"href":"/student/completed-classes"}},[_vm._v("View All")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary-icon"},[_c('i',{staticClass:"icon-021-love-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-footer text-center"},[_c('a',{attrs:{"href":"/student/booked-classes"}},[_vm._v("View All")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary-icon"},[_c('i',{staticClass:"icon-004-chat"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-footer text-center"},[_c('a',{attrs:{"href":"/student/favourite-tutors"}},[_vm._v("View All")])])
}]

export { render, staticRenderFns }