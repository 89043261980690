<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">My Profile</h3>
            <p class="d-block">Change your profile image and information edit and save.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" :class="{'active': tab == 'profile'}" id="v-pills-home-tab" @click="tab='profile'" data-toggle="pill" role="tab" aria-controls="v-pills-home" :aria-selected="tab=='profile'?true:false">Profile</a>
            <a class="nav-link" :class="{'active': tab == 'reset_password'}" id="v-pills-profile-tab" @click="tab='reset_password'" data-toggle="pill" role="tab" aria-controls="v-pills-profile" :aria-selected="tab=='reset_password'?true:false">Password Change</a>
          </div>
        </div>
        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12">
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade" :class="{'active show': tab == 'profile'}" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
              <div class="card">
                <div class="card-header">Profile</div>
                <div class="card-body">
                  <v-form ref="form" lazy-validation v-model="valid">
                    <!-- Form Name -->
                    <div class="profile-upload-img">
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                          <div id="image-preview">
                            <img :src="edit_profile.profile_pic" alt="" v-if="edit_profile.profile_pic" width="100" height="100" style="pointer-events:none;">
                            <img src="../../assets/images/dashboard-profile.jpg" alt="" v-if="!edit_profile.profile_pic" style="pointer-events:none;">
                          </div>
                          <v-file-input  v-model="profile_pic" accept="image/png,image/jpg,image/jpeg,image" placeholder="Choose File" class="upload-profile-input" prepend-icon="mdi-camera" show-size></v-file-input>
                        </div>
                      </div>
                    </div>
                    <div class="personal-form-info">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                          <div class="form-group">
                            <label class="control-label" for="email">Email<span class="red--text"><strong>* </strong></span></label>
                            <v-text-field type="text" name="email" v-model="edit_profile.email" outlined readonly></v-text-field>
                          </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                          <div class="form-group">
                            <label class="control-label" for="name">Name<span class="red--text"><strong>* </strong></span></label>
                            <v-text-field type="text" v-model="edit_profile.name" :rules="[v => !!v || 'Enter name']" name="vendorname" outlined ></v-text-field>
                          </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                          <div class="form-group">
                            <label class="control-label" for="phone">Country<span class="red--text"><strong>* </strong></span></label>
                            <v-autocomplete  v-model="edit_profile.country_id" :items="countries" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" @input="getRegions" outlined></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                          <div class="form-group">
                            <label class="control-label" for="phone">Region</label>
                            <v-autocomplete  v-model="edit_profile.region_id" :items="regions" item-text="text" item-value="value" outlined></v-autocomplete>                          </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                          <div class="form-group">
                            <div class="row">
                              <div class="col-lg-2">
                                <label class="control-label" for="code">Code</label>
                                <v-autocomplete  name="code" :items="codes" item-text="text" item-value="value" @input="setCountry" v-model="edit_profile.country_code" outlined></v-autocomplete>
                              </div>
                              <div class="col-lg-10">
                                <label class="control-label" for="phone">Phone</label>
                                <v-text-field type="text" name="phone" v-model="edit_profile.mobile" outlined></v-text-field>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                          <div class="form-group">
                            <label class="control-label" for="phone">Course</label>
                            <v-autocomplete  v-model="edit_profile.course_id" :items="courses" item-text="text" item-value="value" outlined></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3 float-right">
                          <a v-on:click="toEditProfile" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100 text-white">Edit</a>
                          <!-- <b-button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100"  v-on:click="toEditProfile">Edit</b-button> -->
                        </div>
                      </div>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" :class="{'active show': tab == 'reset_password'}" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
              <div class="card">
                <div class="card-header">Password Change</div>
                <div class="card-body">
                  <v-form class="row" ref="reset_form" v-model="reset_valid" lazy-validation>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="form-group">
                        <label class="control-label" for="currentpassword">Current Password</label>
                        <v-text-field type="password" name="currentpassword" v-model="reset_password.password" :rules="passwordRules"  outlined :error-messages="auth_msg"></v-text-field>
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="form-group">
                        <label class="control-label" for="newpassword">New Password</label>
                        <v-text-field type="password" name="newpassword" v-model="reset_password.new_password" :rules="passwordRules"  outlined ></v-text-field>
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="form-group">
                        <label class="control-label" for="retypepassword">Retype Password</label>
                        <v-text-field type="password" v-model="reset_password.new_password_confirmation" name="retypepassword" :rules="passwordConfirmationRules"  outlined ></v-text-field>
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <button class="btn btn-default" type="submit" variant="primary" v-on:click="submitResetForm">Save Changes</button>
                    </div>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center" v-if="tab == 'profile'">
          <p>Do you really want to update profile?</p>
        </div>		
        <div class="modal-body text-center" v-if="tab == 'reset_password'">
          <p>Do you really want to reset password?</p>
        </div>
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="updateRecord" v-if="!loading && tab == 'profile'">Save</button>
            <button type="button" class="btn btn-danger" v-on:click="resetPassword" v-if="tab == 'reset_password'">Reset</button>
            <button type="button" class="btn btn-danger" disabled v-if="loading">...</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import AWS from 'aws-sdk';
export default {
  name: "profile",
  data(){
    return {
      tab:'profile',
      student: null,
      reset_password:{
        password: null,
        new_password: null,
        new_password_confirmation: null,
      },
      edit_profile:{
        name:null,
        mobile:null,
        country_code:null,
        profile_pic:null,
        country_id:null,
        region_id:null,
        course_id:null,
      },
      countries:[],
      codes:[],
      courses:[],
      regions:[],
      valid:false,
      reset_valid:false,
      loading:false,
      profile_pic:null,
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      passwordConfirmationRules:[
        v => !!v || 'Enter valid password',
        v => this.reset_password.new_password == v || 'Password not match'
      ],
      auth_msg:'',
    }
  },
  beforeMount(){
    let student_token = this.$cookies.get("tution-student").token;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+student_token}}).then(res => {
      this.student = res.data.data.student
      this.setProfile();
    })
    this.getCountries();
    this.getCourses();
  },
  methods:{
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    setProfile(){
      this.edit_profile.name = (this.student.name)?this.student.name:null;
      this.edit_profile.email = (this.student.email)?this.student.email:null;
      this.edit_profile.mobile = (this.student.mobile)?this.student.mobile:null;
      this.edit_profile.phone = (this.student.phone)?this.student.phone:null;
      this.edit_profile.country_id = (this.student.country_id)?this.student.country_id:null;
      this.edit_profile.course_id = (this.student.course_id)?this.student.course_id:null;
      this.edit_profile.region_id = (this.student.region_id)?this.student.region_id:null;
      this.edit_profile.profile_pic = (this.student.profile_pic)?this.student.profile_pic:null;
      this.edit_profile.country_code = (this.student.user.code)?parseInt(this.student.user.code):null;
      this.listRegions(this.student.country_id)
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode});
        })
      })
    },
    getRegions(){
      this.countries.forEach(country => {
        if(country.value == this.edit_profile.country_id){
          this.edit_profile.country_code = country.code;
        }
      });
      this.listRegions(this.edit_profile.country_id);
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    getCourses(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        res.data.Grades.forEach(grade => {
          if(grade.is_active == 'Y'){
            this.courses.push({value:grade.grade_id,text:grade.name});
          }
        })
      });
    },
    setCountry(){
      this.countries.forEach(country => {
        if(country.code == this.edit_profile.country_code){
          this.edit_profile.country_id = country.value;
        }
      });
      this.listRegions(this.edit_profile.country_id);
    },
    toEditProfile(e){
      e.preventDefault();
      this.loading = false;
      this.valid = this.$refs.form.validate();
      if(this.valid){
        this.$refs['confirm-modal'].show();
      }
    },
    updateRecord(e){
      e.preventDefault();
      this.loading = true;
      if(this.profile_pic != null){
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
      
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });

        let s3BucketName = "tution-site"; 
        var file = new Blob([this.profile_pic], { type: "application/image" });
        var extension = this.profile_pic.name.split('.')[this.profile_pic.name.split('.').length-1]
      
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
      var timestamp = Math.floor(Date.now() / 1000);

      if (file.size < 20000000) {
        var s3obj = new AWS.S3({
          params: {
            Bucket: s3BucketName,
            Key:
              'student/profile_pic/'+ current_date+"/"+ timestamp + "."+extension,
            ACL: "public-read",
          },
        });
        var upload = s3obj.upload({ Body: file });
        upload.on("httpUploadProgress", function (evt) {
            // uploadStatus == 0;
            //checkConnetionOffnOn(upload);
            var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
            console.log(per_uploaded);
          })
          .send((err, data) => {
            this.edit_profile.profile_pic = data.Location;
            this.profile_pic = null;
            this.confirmEdit();
          })
      }
      }else{
        this.confirmEdit();
      }
    },
    confirmEdit(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/student/'+this.student.student_id,this.edit_profile,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.status == 1){
          this.$refs['confirm-modal'].hide();
          var cookie_data = this.$cookies.get('tution-student');
          cookie_data.name = this.edit_profile.name;
          this.$cookies.set("tution-student", cookie_data);
          location.reload();
        }
      })
    },
    submitResetForm(e){
      e.preventDefault();
      this.reset_valid = this.$refs.reset_form.validate();
      if(this.reset_valid){
        this.$refs['confirm-modal'].show();
      }
    },
    resetPassword(){
      this.auth_msg = '';
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/reset_password',this.reset_password,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.message == 'Password reset successfully'){
          this.$refs['confirm-modal'].hide();
        }else if(res.data.message == 'invalid current password'){
          this.$refs['confirm-modal'].hide();
          this.auth_msg = 'Invalid current password'
        }else{
          console.log('error');
        }
      })
    }
  }
}
</script>

<style scoped>
.nav-link{
  border-radius: 2px !important;
  font-size: 14px;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.card-body {
    padding: 20px;
}
.profile-upload-img {
    margin-bottom: 30px;
}
#image-preview {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    background-color: #d2d2d2;
    color: #ecf0f1;
    border-radius: 100%;
}
.upload-profile-input {
    position: absolute;
    left: 130px;
    top: 115px;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
</style>