<template>
  <div class="dashboard-content">
    <div class="container pt-7">
      <div class="card">
        <div class="card-header">Change Password</div>
        <div class="card-body">
          <v-form ref="form" lazy-validation v-model="valid">
            <div class="personal-form-info">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="subjects">Current Password</label>
                    <v-text-field type="password" v-model="edit_password.current_password" :rules="[v => !!v || 'Enter valid password']" outlined></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="subjects">New Password</label>
                    <v-text-field type="password" v-model="edit_password.new_password" :rules="passwordRules" outlined></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="subjects">Repeat Password</label>
                    <v-text-field type="password" v-model="edit_password.repeat_password" :rules="passwordConfirmationRules" outlined></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <b-button class="btn btn-default" type="submit" variant="primary" v-on:click.prevent="updatePassword">Update</b-button>
                </div>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Password updated</div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'student-chatroom',
  data(){
    return {
      valid: true,
      edit_password:{
        current_password:null,
        new_password:null,
        repeat_password:null
      },
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      passwordConfirmationRules:[
        v => !!v || 'Enter valid password',
        v => this.edit_password.new_password == v || 'Password not match'
      ]
    }
  },
  methods:{
    openModal: function (type = "") {
      this.open = (type == "")? this.open : type;
      if (this.open == "success") {
        this.$refs["success"].show();
      }
    },
    updatePassword(e){
      e.preventDefault();
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        let tutor_token = this.$cookies.get("tution-tutor")?this.$cookies.get("tution-tutor").token:null;
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/reset_password',{password:this.edit_password.new_password,password_confirmation:this.edit_password.repeat_password},{headers:{"Authorization":'bearer '+tutor_token}}).then(res => {
          if(res.data.status == 1){
            this.openModal("success");
            setTimeout(() => {
              this.$refs["success"].hide();
              this.$router.push('/tutor/settings');
            },2000)
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 1px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
</style>