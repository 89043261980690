<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header mb-0 pb-3">
            <h3 class="dashboard-page-title">Students <a v-on:click="addStudentModal()" class="btn btn-default ml-3" id="trigger6" style="font-size:12px !important;">ADD STUDENT</a></h3>
            <p class="d-block mb-2">View all students joined</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="guestlist-management">
            <div class="card">
              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs pl-0" id="myTab" role="tablist">
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'student-form'}" @click="tab='student-form'" id="allguest-tab" data-toggle="tab" role="tab" aria-controls="allguest">Student Forms</a>
                  </li>
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'students'}" id="confirmguest-tab" @click="tab='students'" data-toggle="tab" role="tab" aria-controls="confirmguest">Students</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" :class="{'active show': tab == 'student-form'}" id="allguest" role="tabpanel" aria-labelledby="allguest-tab">
                  <div class="tab-content" id="guest-filter-content">
                    <div class="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
                      <div class="guest-list-table table-responsive mb-0">
                        <div class="single-guest-tab">
                          <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                            <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                              <thead>
                                <tr role="row">
                                  <th>Sn.</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Course</th>
                                  <th>Country</th>
                                  <th>Actions</th>
                                </tr>
                              </thead> 
                              <tbody v-if="student_forms.length">
                                <tr v-for="(form,index) in student_forms" :key="index">
                                  <td v-if="form">{{index+1}}</td>
                                  <td v-if="form.name">{{form.name}}</td>
                                  <td v-if="form.user.email">{{form.user.email}}</td><td v-else>-</td>
                                  <td v-if="form.subject&&form.subject.subject_name">{{form.subject.subject_name}}</td><td v-else>-</td>
                                  <td v-if="form.country">{{form.country.name}}</td><td v-else>-</td>
                                  <td>
                                    <a :href="'/tutn-auth/student-form/'+form.student_id" class="btn btn-outline-violate btn-xs mr-1">view</a>
                                    <a class="btn btn-outline-pink btn-xs" v-on:click.prevent="disableStudentForm(form.student_id,index)">delete</a>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td class="empty-data text-center" colspan="6" rowspan="4">No Student Froms</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" :class="{'active show': tab == 'students'}" id="allguest" role="tabpanel" aria-labelledby="allguest-tab">
                  <div class="tab-content" id="guest-filter-content">
                    <div class="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
                      <div class="guest-list-table table-responsive mb-0">
                        <div class="single-guest-tab">
                          <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                            <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                              <thead>
                                <tr role="row">
                                  <th>Sn.</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Course</th>
                                  <th>Country</th>
                                  <th>Actions</th>
                                </tr>
                              </thead> 
                              <tbody>
                                <tr v-for="(student,index) in students" :key="student.student_id">
                                  <td v-if="student">{{index+1}}</td>
                                  <td v-if="student.name">{{student.name}}</td>
                                  <td v-if="student.user.email">{{student.user.email}}</td><td v-else>-</td>
                                  <td v-if="student.subject&&student.subject.subject_name">{{student.subject.subject_name}}</td><td v-else>-</td>
                                  <td v-if="student.country">{{student.country.name}}</td><td v-else>-</td>
                                  <!-- <td>
                                    <div class="switch-notification">
                                      <label class="switch">
                                        <input type="checkbox" :id="'activate-profile'+index" v-on:click.prevent="disableStudent(index,student.student_id)" :checked="student.is_active=='Y'?true:false">
                                        <span class="slider"></span>
                                      </label>
                                    </div>
                                  </td> -->
                                  <td>
                                    <a :href="'/tutn-auth/student-view/'+student.student_id" class="btn btn-outline-violate btn-xs mr-1">view</a>
                                    <a class="btn btn-outline-pink btn-xs" v-on:click.prevent="disableStudent(student.student_id,index)">delete</a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="addStudent" ref="student-modal" size="lg" title="Add Student Deatils" hide-footer centered>
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body">
                <v-form ref="form" lazy-validation v-model="valid">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Name<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined ></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Email<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.email" :rules="emailRules" outlined :error-messages="auth_msg"></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Password<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="password" v-model="register.password" :rules="passwordRules" outlined></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Country</label>
                       <v-autocomplete v-model="student.country" :items="countries" item-text="text" item-value="value" @input="getRegions()" searchable outlined clearable></v-autocomplete>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <div class="row">
                        <div class="col-lg-4 pt-0">
                          <label for="inputText3" class="col-form-label">Country code</label>
                          <v-autocomplete v-model="student.code" :items="codes" item-text="text" item-value="value"  @input="getCountry" outlined></v-autocomplete>
                        </div>
                        <div class="col-lg-8 pt-0">
                          <label for="inputText3" class="col-form-label">Mobile</label>
                          <v-text-field type="text" v-model="register.mobile" outlined :error-messages="mob_msg"></v-text-field>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Select gender</label>
                      <v-autocomplete v-model="register.gender" :items="gender" item-text="text" item-value="value" outlined ></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Region</label>
                       <v-autocomplete v-model="register.region" :items="regions" item-text="text" item-value="value" searchable outlined clearable></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Select Courses</label>
                       <v-autocomplete v-model="register.course" :items="courses" item-text="text" item-value="value" searchable outlined clearable></v-autocomplete>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <b-button type="submit" class="btn btn-default" v-if="!loading" v-on:click.prevent="postRegister">Submit</b-button>
                      <b-button type="button"  class="btn btn-default" v-if="loading"><i class="fa fa-spinner fa-spin"></i></b-button>
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p v-if="record.type == 'DELETE'">Do you really want to delete these records?</p>
          <p v-if="record.type == 'ENABLE'">Do you really want to enable these records?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal('confirm-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" v-if="record.type == 'DELETE'" v-on:click="deleteRecord">Delete</button>
            <button type="button" class="btn btn-danger" v-if="record.type == 'ENABLE'" v-on:click="enableRecord">Enable</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">{{message}}</div>
    </b-modal>
    <b-modal id="error" ref="error" size="sm" title=" " body-bg-variant="danger" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">{{message}}</div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "admin-students",
  data(){
    return {
      tab: "student-form",
      students:[],
      student_forms:[],
      countries:[],
      s_country:null,
      activate:{
        status:false
      },
      valid: true,
      can_register:0,
      courses:[],
      codes:[],
      regions:[],
      student:{
        code:null,
        mobile:null,
        country:null
      },
      register: {
        name: null,
        email: null,
        mobile: null,
        gender: null,
        country_id: null,
        region_id: null,
        course_id: null,
        password: null,
        country_code:null,
      },
      auth_error: false,
      auth_msg: null,
      gender:[
        {value:1, text:'Male'},
        {value:2, text:'Female'},
        {value:3, text:'Other'}
      ],
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      mob_error:false,
      mob_msg:'',
      loading:false,
      message:null,
      record:{
        type: null,
        id: null,
        index: null,
        form:null,
      },
    }
  },
  beforeMount(){
    this.getCountries();
    this.getCourse();
    this.getCourses();
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/students',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      res.data.data.forEach((student => {
        if(student.user){
          this.students.push(student);
        }
      }))
    });
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student_forms',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      res.data.data.forEach((form => {
        if(form.user){
          this.student_forms.push(form);
        }
      }))
    });
  },
  methods:{
    hideModal(data){
      this.$refs[data].hide();
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name, code:country.phonecode, short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode})
        })
      })
    },
    setCountry(id){
      this.countries.forEach((country) => {
        if(country.value == id){
          this.s_country = country.text;
        }
      });
      return this.s_country;
    },
    getCourse(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/courses',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Courses.forEach(course => {
          if(course.is_active == "Y"){
            this.courses.push({value:course.course_id,text:course.course_name});
          }
        })
      })
    },
    getCourses(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/courses',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Courses.forEach(course => {
          this.courses.push({value:course.course_id, text:course.course_name});
        })
      })
    },
    disableStudent(id,index){
      this.record.type = 'DELETE';
      this.record.id = id;
      this.record.index = index;
      this.record.form = 'S'
      this.$refs["confirm-modal"].show();
    },
    disableStudentForm(id,index){
      this.record.type = 'DELETE';
      this.record.id = id;
      this.record.index = index;
      this.record.form = 'F'
      this.$refs["confirm-modal"].show();
    },
    deleteRecord(){
      this.activate.status = 'N';
      this.activate.student_id = this.record.id;
      this.activate.type = this.record.form;

      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/student_disable',this.activate,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status){
          if(this.activate.type == 'S'){
            this.students.splice(this.record.index,1);
          }else{
            this.student_forms.splice(this.record.index,1);
          }
          this.$refs["confirm-modal"].hide();
        }
      })
    },
    addStudentModal(){
      this.$refs['student-modal'].show();
    },
    getCountry(){
      this.countries.forEach(country => {
        if(country.code == this.student.code){
          this.student.country = {value:country.value, text:country.text};
        }
      })
      this.register.country_id = this.student.country.value;
      this.listRegions(this.student.country.value);
    },
    getRegions(){
      this.register.country_id = this.student.country;
      this.countries.forEach(country => {
        if(country.value == this.student.country){
          this.student.code = {value:country.code, text:country.short+ '-' + country.code};
        }
      });
      this.listRegions(this.student.country);
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    postRegister(e){
      e.preventDefault();
      this.loading = true;
      this.valid = this.$refs.form.validate();
      this.auth_msg = null;
      this.auth_error = false;
      if(this.valid == true){
        this.register.country_code = (this.student.code||this.register.country_id)?(typeof this.student.code === 'object'?this.student.code.value:this.student.code):null;
        try {
          axios.post(process.env.VUE_APP_TUTION_SITE_API+'/admin/student',this.register,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
            this.loading = false;
            if(res.data.status){
              this.message = res.data.message;
              this.students.unshift(res.data.student);
              this.$refs["student-modal"].hide();
              this.$refs["success"].show();
              setTimeout(() => {
                this.$refs["success"].hide();
              },2000);
            }else{
              this.message = res.data.message;
              this.$refs["error"].show();
              setTimeout(() => {
                this.$refs["error"].hide();
              },2000);
            }
          });
        } catch (error) {
          this.loading = false;
          this.message = "Error"
          this.$refs["error"].show();
          setTimeout(() => {
            this.$refs["error"].hide();
          },2000);
        }
      }
    }
  }
}
</script>
<style scoped>
.rsvp-summary.card {
    box-shadow: none;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.summary-block {
    margin-bottom: 30px;
}
.bg-light-violate {
    background-color: #e1caf1 !important;
    border-color: #e1caf1;
}
.bg-light-green {
    background-color: #cfedad !important;
    border-color: #cfedad;
}
.card-body {
    padding: 20px;
}
.summary-count {
    font-size: 42px;
    color: #252328;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1;
}
.rsvp-summary .summary-text {
    color: #252328;
}
.rsvp-summary-section {
    margin-bottom: 30px;
}
p:last-child {
    margin: 0px;
}
.summary-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #6f6d72;
}
.guest-list-block, .budget-list-block {
    font-size: 18px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0;
}

.card-header-tabs .nav-item {
    font-size: 14px;
}
.nav-tabs .nav-item {
    margin-bottom: 5px;
}
.card-header-tabs .nav-item .nav-link.active {
    color: #0079df;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.guest-list-table table th {
    background-color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252324;
}
table th{
  border-top: none;
}
.guest-list-table .table {
    font-size: 14px;
}
.card-header-tabs .nav-item .nav-link {
    color: #6f6d72;
    font-size: 16px;
    padding: 10px 30px;
}
.guest-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
table{
  color: #5f5f5f;
}
.red--text{
  color: red;
}
.btn{
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
}
.btn-default{
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn-default:hover {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.red--text{
  color: red;
}
</style>