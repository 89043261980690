<template>
  <div class="cart">
    <div class="content">
      <div class="container">
        <div class="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="row" v-if="tutor.salary">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"></div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" v-if="tutor.salary.hourly">
              <div class="pricing-box pricing-standard">
                <h4 class="pricing-name">Hourly</h4>
                <div class="price"><span class="price-sign">$</span>{{tutor.salary.hourly}}</div>
                <div class="price-feature">
                  <ul class="listnone">
                    <li>Choose your hours within a week</li>
                  </ul>
                </div>
                <a :href="'/pricing/hourly-package?hour_price='+tutor.salary.hourly+'&&tutor_id='+tutor.tutor_id" class="btn btn-white">Select Plan</a>
              </div>
            </div>
            <!-- <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" v-if="tutor.salary.monthly">
              <div class="pricing-box pricing-basic">
                <h4 class="pricing-name">Monthly</h4>
                <div class="price"><span class="price-sign">$</span>{{tutor.salary.monthly}}</div>
                <div class="price-feature">
                  <ul class="listnone">
                    <li>1 hour per day</li>
                    <li>6 days</li>
                  </ul>
                </div>
                <a :href="'/pricing/monthly-package?month_price='+tutor.salary.monthly+'&&tutor_id='+tutor.tutor_id" class="btn btn-primary text-white">Select Plan</a>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" v-if="tutor.salary.yearly">
              <div class="pricing-box pricing-premium">
                <h4 class="pricing-name">Yearly</h4>
                <div class="price"><span class="price-sign">$</span>{{tutor.salary.yearly}}</div>
                <div class="price-feature">
                  <ul class="listnone">
                    <li>1 hour per day</li>
                    <li>6 days per week</li>
                    <li>12 Month </li>
                  </ul>
                </div>
                <a :href="'/pricing/yearly-package?year_price='+tutor.salary.yearly+'&&tutor_id='+tutor.tutor_id" class="btn btn-primary text-white">Select Plan</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="space-small">
        <div class="container">
          <div class="row">
            <div class="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10 col-md-12 col-sm-12 col-12">
              <h2 class="text-center" style="margin-bottom:40px;">Trusted By Millions</h2>
              <div class="row">
                <!-- feature -->
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="well-box feature-left">
                    <div class="feature-icon"><img src="../assets/images/secure-payment.png" alt="" style="pointer-events:none;"></div>
                    <div class="feature-content">
                      <h4 class="feature-title">Secure Payment</h4>
                      <p>Your information is protected by secure payment.</p>
                    </div>
                  </div>
                </div>
                <!-- /.feature -->
                <!-- feature -->
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="well-box feature">
                    <div class="">
                      <h4 class="feature-title">We accept all of the following credit cards.</h4>
                      <div class="payment-card" style="margin-top:10px;">
                        <a href="#"><i class="fab fa-cc-visa fa-2x"></i></a>
                        <a href="#"><i class="fab fa-cc-mastercard fa-2x"></i></a>
                        <a href="#"> <i class="fab  fa-cc-paypal fa-2x"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.feature -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      packages:[],
      tutor:[],
    }
  },
  beforeMount(){
    window.scrollTo(0, 0);
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/'+this.$route.query.id).then(res => {
      this.tutor = res.data.data;
    });
  }
}
</script>

<style scoped>
.pricing-box {
    padding: 40px;
    text-align: center;
    border-radius: 4px;
    position: relative;
    margin-bottom: 30px;
}

.pricing-standard {
    background: linear-gradient(to bottom right, #FFA35F, #F95A26);
    color: #fff;
}
.pricing-standard .price, .pricing-standard .pricing-name {
    color: #fff;
}
.pricing-premium {
    background-color: #fff;
    border: 1px solid #ebebeb;
}
.pricing-name {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
}
.pricing-standard .price, .pricing-standard .pricing-name {
    color: #fff;
}

.price {
    font-size: 48px;
    margin-bottom: 20px;
    letter-spacing: -1px;
    line-height: 48px;
    position: relative;
    color: #252328;
}
.price-sign {
    font-size: 20px;
}
.price-feature {
    margin-bottom: 40px;
    min-height: 98px;
}
.listnone {
    list-style: none;
    padding: 0px;
}
.price-feature ul li {
    margin-bottom: 10px;
}
.btn-white {
    color: #252328;
    background-color: #fff;
    border-color: #fff;
}
.pricing-basic {
    background-color: #fff;
    border: 1px solid #e6e5e7;
}
.btn-white:hover {
    color: #fff;
    background-color: #f04040;
    border-color: #f04040;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #282828;
    border-color: #282828;
}
.space-small {
    padding-top: 25px;
    padding-bottom: 50px;
}
.feature-left {
    min-height: 143px;
}

.well-box {
    background-color: #fff;
    border: 1px solid #e6e5e7;
    padding: 26px;
    margin-bottom: 20px;
}
.feature-left .feature-icon {
    float: left;
}
.feature-left .feature-content {
    padding-left: 80px;
}
.feature-left .feature-title {
    margin-bottom: 10px;
}
.feature {
    margin-bottom: 20px;
}
.payment-card a {
    color: #252328 !important;
    -webkit-font-smoothing: antialiased;
}
</style>