<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">My Profile</h3>
            <p class="d-block">Change your profile image and information edit and save.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="card">
            <div class="card-header">Profile</div>
            <div class="card-body">
              <v-form ref="form" lazy-validation v-model="valid">
                <!-- Form Name -->
                <div class="profile-upload-img">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                      <div id="image-preview">
                        <img :src="edit_profile.profile_pic" alt="" v-if="edit_profile.profile_pic" width="100" height="100" style="pointer-events:none;">
                        <img src="../../assets/images/dashboard-profile.jpg" alt="" v-if="!edit_profile.profile_pic" width="100" style="pointer-events:none;">
                      </div>
                      <v-file-input  v-model="profile_pic" accept="image/png,image/jpg,image/jpeg,image" placeholder="Choose File" class="upload-profile-input" prepend-icon="mdi-camera" show-size></v-file-input>
                    </div>
                  </div>
                </div>
                <div class="personal-form-info">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <div class="form-group">
                        <label class="control-label" for="email">Email<span class="red--text"><strong>* </strong></span></label>
                        <v-text-field type="text" name="email" v-model="edit_profile.email" outlined readonly></v-text-field>
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <div class="form-group">
                        <label class="control-label" for="vendorname">Tutor Name<span class="red--text"><strong>* </strong></span></label>
                        <v-text-field type="text" v-model="edit_profile.name" :rules="[v => !!v || 'Enter name']" name="vendorname" outlined ></v-text-field>
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <div class="form-group">
                        <label class="control-label" for="phone">Country<span class="red--text"><strong>* </strong></span></label>
                        <v-autocomplete  v-model="edit_profile.country_id" :items="countries" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" @input="getRegions" outlined></v-autocomplete>
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <div class="form-group">
                        <label class="control-label" for="phone">Region</label>
                        <v-autocomplete  v-model="edit_profile.region_id" :items="regions" item-text="text" item-value="value" outlined></v-autocomplete>            
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-lg-2">
                            <label class="control-label" for="code">Code</label>
                            <v-autocomplete  name="code" :items="codes" item-text="text" item-value="value" @input="setCountry" v-model="edit_profile.country_code" outlined></v-autocomplete>
                          </div>
                          <div class="col-lg-10">
                            <label class="control-label" for="phone">Phone</label>
                            <v-text-field type="text" name="phone" v-model="edit_profile.mobile" outlined></v-text-field>
                          </div>
                        </div>
                     </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <div class="form-group">
                        <label class="control-label">Tell me about yourself</label>
                        <v-textarea v-model="edit_profile.about" outlined></v-textarea>                          
                      </div>
                    </div>
                    <div class="col-md-3 float-right"> 
                      <b-button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100"  v-on:click.prevent="toEditProfile">Save changes</b-button>
                    </div>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to update profile?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="submitResetForm" v-if="!loading">Save</button>
            <button type="button" class="btn btn-danger" disabled v-if="loading">...</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import AWS from "aws-sdk";
export default {
  name: "profile",
  data(){
    return {
      tab:'profile',
      tutor: null,
      reset_password:{
        current_password: null,
        new_password: null,
        confirm_password: null,
      },
      edit_profile:{
        name:null,
        mobile:null,
        country_id:null,
        region_id:null,
        profile_pic:null,
        country_code:null,
        about:null,
        // course_id:null,
      },
      codes:[],
      countries:[],
      regions:[],
      t_country:null,
      t_region:null,
      valid:false,
      loading:false,
      profile_pic:null,
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
    }
  },
  beforeMount(){
    let tutor_token = this.$cookies.get("tution-tutor").token;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+tutor_token}}).then(res => {
      this.tutor = res.data.data;
      this.setProfile();
    });
    this.getCountries();
  },
  methods:{
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    setProfile(){
      if(this.tutor.users){
        console.log(this.tutor.users);
        this.edit_profile.name = (this.tutor.name)?this.tutor.name:null;
        this.edit_profile.email = (this.tutor.users.email)?this.tutor.users.email:null;
        this.edit_profile.about = (this.tutor.about)?this.tutor.about:null;
        this.edit_profile.mobile = (this.tutor.users.mobile)?this.tutor.users.mobile:null;
        this.edit_profile.country_id = (this.tutor.country_id)?this.tutor.country_id:null;
        this.edit_profile.region_id = (this.tutor.region_id)?this.tutor.region_id:null;
        this.edit_profile.country_code = (this.tutor.users.code)?parseInt(this.tutor.users.code):null;
        this.edit_profile.profile_pic = (this.tutor.profile_pic)?this.tutor.profile_pic:null;
      }
      // this.t_country={value:this.tutor.country.id,text:this.tutor.country.name};
      // this.t_region={value:this.tutor.region.id,text:this.tutor.region.name};
      this.listRegions(this.tutor.country_id)
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode});
        })
      })
    },
    getRegions(){
      this.countries.forEach(country => {
        if(country.value == this.edit_profile.country_id){
          this.edit_profile.country_code = country.code;
        }
      });
      this.listRegions(this.edit_profile.country_id);
    },
    toEditProfile(e){
      e.preventDefault();
      this.valid = this.$refs.form.validate();
      if(this.valid){
        this.$refs['confirm-modal'].show();
      }
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    setCountry(){
      this.countries.forEach(country => {
        if(country.code == this.edit_profile.country_code){
          this.edit_profile.country_id = country.value;
        }
      });
      this.listRegions(this.edit_profile.country_id);
    },
    submitResetForm(e){
      e.preventDefault();
      this.loading = true;
      if(this.profile_pic != null){
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
      
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });

        let s3BucketName = "tution-site"; 
        var file = new Blob([this.profile_pic], { type: "application/image" });
        var extension = this.profile_pic.name.split('.')[this.profile_pic.name.split('.').length-1]
      
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
      var timestamp = Math.floor(Date.now() / 1000);

      if (file.size < 20000000) {
        var s3obj = new AWS.S3({
          params: {
            Bucket: s3BucketName,
            Key:
              'tutor/profile_pic/'+ current_date+"/"+ timestamp + "."+extension,
            ACL: "public-read",
          },
        });
        var upload = s3obj.upload({ Body: file });
        upload.on("httpUploadProgress", function (evt) {
            // uploadStatus == 0;
            //checkConnetionOffnOn(upload);
            var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
            console.log(per_uploaded);
          })
          .send((err, data) => {
            this.edit_profile.profile_pic = data.Location;
            this.profile_pic = null;
            this.confirmEdit();
          })
      }
      }else{
        this.confirmEdit();
      }
    },
    confirmEdit(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/tutor/'+this.tutor.tutor_id,this.edit_profile,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-tutor').token)}}).then(res => {
        if(res.data.status == 1){
          this.$refs['confirm-modal'].hide();
          var cookie_data = this.$cookies.get('tution-tutor');
          cookie_data.name = this.edit_profile.name;
          this.$cookies.set("tution-tutor", cookie_data);
          location.reload();
        }
      })
    },
  }
}
</script>

<style scoped>
.nav-link{
  border-radius: 2px !important;
  font-size: 14px;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.card-body {
    padding: 20px;
}
.profile-upload-img {
    margin-bottom: 30px;
}
#image-preview {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    background-color: #d2d2d2;
    color: #ecf0f1;
    border-radius: 100%;
}
.upload-profile-input {
    position: absolute;
    left: 130px;
    top: 115px;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
</style>