<template>
  <div class="nav fixed-top">
    
    <div class="app-header">      
      <b-navbar toggleable="lg" class="header-nav fixed-top p-1">
        
        <div class="container">
          
          <b-navbar-brand href="/" class="header-logo">
            <img src="../assets/images/logo.png" alt="" class="brand-logo" style="pointer-events:none;">
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav class="nav-rend">
            <b-navbar-nav v-if="!student || !tutor">
              <b-nav-item href="/">Home</b-nav-item>
              <b-nav-item href="/tutors">Courses</b-nav-item>
              <b-nav-item href="/study_materials">E-Shop</b-nav-item>
              
              <b-nav-item href="/payment">Payment</b-nav-item>
              <b-nav-item href="/video_course">Video course</b-nav-item>
              <b-nav-item href="/gallery">Gallery</b-nav-item>
              <b-nav-item href="https://forms.fromyourtutor.com/" target="_blank">Counseling</b-nav-item>
              <span class="more">
                More
                <ul>
                  <b-nav-item href="/about-us">About Us</b-nav-item>
                  <b-nav-item href="/testimonials">Testimonials</b-nav-item>
                  <b-nav-item href="/amccmembers">AMCC Members</b-nav-item>
                  <b-nav-item href="/staff">Staff Directory</b-nav-item>
                  <b-nav-item href="/contact-us">Contact Us</b-nav-item>
                  <b-nav-item href="/faq">FAQ</b-nav-item>
                </ul>
              </span>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <!-- <b-nav-item v-if="!student || !tutor"> -->
                <router-link to="/donate" class="nav-item">
                  <div class="header-btn">
                    <a class="btn btn-primary btn-sm text-white bg-green"><img src="../assets/images/help.svg" alt="" width="16"> Donation</a>
                  </div>
                </router-link>
                <router-link to="/login" v-if="!student && !tutor" class="nav-item">
                  <div class="header-btn">
                    <a class="btn btn-primary btn-sm text-white">Student Login</a>
                  </div>
                </router-link>
              
              <div class="user-vendor" v-if="student">
                <div class="dropdown">
                  <a class="dropdown-toggle" id="dropdownMenuButton" @click="dropdown=!dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration:none;">
                    <span class="user-icon">
                      <img src="../assets/images/dashboard-profile.jpg"  alt="" class="rounded-circle mb10" v-if="!student_dp" style="pointer-events:none;height: 50px;width: 50px;">
                      <img :src="student_dp" alt="" class="rounded-circle mb10" v-if="student_dp" style="pointer-events:none;height: 50px;width: 50px;">
                    </span>
                      <span class="user-vendor-name">{{student.name}}</span> 
                  </a>
                  <div class=" dashboard-dropdown-menu dropdown-menu" aria-labelledby="dropdownMenuButton" v-if="dropdown">
                    <a class="dropdown-item" href="/student/dashboard">Dashboard</a>
                    <a class="dropdown-item" href="/cart">Cart</a>
                    <a class="dropdown-item" href="" v-on:click.prevent="studentLogout()">Log Out</a>
                  </div>
                </div>
              </div>
              <b-nav-item-dropdown :text="this.tutor.name" v-if="tutor">
                <template slot="button-content" style="display:flex">
                    <!-- <span class="form-control-feedback material-icons" style="line-height:0.3;">person</span> -->
                    <span class="user-icon">
                      <img src="../assets/images/dashboard-profile.jpg" alt="" height="30px" v-if="!tutor_dp" class="rounded-circle mb10" style="pointer-events:none;">
                      <img :src="tutor_dp" height="50px" alt="" class="rounded-circle mb10" v-if="tutor_dp" style="pointer-events:none;">
                    </span>
                    <span class="user-vendor-name">{{tutor.name}}</span>
                    <!-- <div style="line-height:0.3">{{this.tutor.name}}</div> -->
                </template>
                <b-dropdown-item><div v-on:click.prevent="tutorDashboard()">Dashboard</div></b-dropdown-item>
                <b-dropdown-item><div v-on:click.prevent="getCart()">Cart</div></b-dropdown-item>
                <b-dropdown-item><div v-on:click.prevent="tutorLogout()" class="">Logout</div></b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'Header',
  data(){
    return {
      student:null,
      tutor:null,
      dropdown:false,
      student_dp:null,
      tutor_dp:null,
    }
  },
  beforeMount(){
    this.student = (this.$cookies.get('tution-student'))?this.$cookies.get('tution-student'):null;
    if(this.student){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.student.token}}).then(res => {
        if(res.data.message == 'Student retrieved successfully'){
          this.student_dp = res.data.data.student.profile_pic;
        }
      })
    }
    this.tutor = (this.$cookies.get('tution-tutor'))?this.$cookies.get('tution-tutor'):null;
    if(this.tutor){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+this.tutor.token}}).then(res => {
        if(res.data.message == 'User found'){
          this.tutor_dp = res.data.data.profile_pic;
        }
      })
    }
  },
  methods:{
    studentLogout(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/logout',{headers:{"Authorization":'Bearer '+this.student.token}}).then(res => {
      if(res.data.status == 1){
          this.$cookies.remove("cart-session-token");
          this.$cookies.remove("cart-session-key");
          this.$cookies.remove("tution-student");
          this.$router.replace('/login')
        }
      });
    },
    tutorDashboard(){
      this.$router.push("/tutor/dashboard");
    },
    getCart(){
      this.$router.push("/cart");
    },
    tutorLogout(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/logout',{headers:{"Authorization":'Bearer '+this.tutor.token}}).then(res => {
      if(res.data.status == 1){
          this.$cookies.remove("cart-session-token");
          this.$cookies.remove("cart-session-key");
          this.$cookies.remove("tution-tutor");
          this.$router.replace('/tutor_login')
        }
      });
    }
  }
}
</script>


<style scoped>
.more{
  position: relative;
  margin-top: 8px;
  margin-left: 5px;
  font-size: 14px;
}
.v-application a{
  color: #3c3c3c;
}
.more ul{
  display: none;
  position: absolute;
  background: #fff;
  padding: 15px 0px 0px 0px;
  margin: 0px;
  min-width: 114px;
  right: -10px;
}
.more ul li{
  list-style: none;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}
.navbar-expand-lg .navbar-nav .nav-link{
  position: relative;
}
.more ul li a{
  padding: 5px 15px 5px 15px !important;
  text-align: right;
}
.more::before{
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  background: #fff;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #000;
  right: -9px;
position: absolute;
top: 8px;

}
.more:hover::before{
  rotate: 90deg;

}
.more:hover ul{
  display: block;
}
.header-btn { 
  padding-top: 10px; 
  padding-left: 10px; 
}
.header-btn img{
  width: 16px;
}
.header-logo {
  padding-top: 0px;
  width: 250px;
  padding-right: 15px;
}
.header-logo > img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
.btn-sm{
    padding: 8px 20px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.user-vendor-name{
    position: relative;
    bottom: 3px;
}
.user-vendor a {
    color: #252328 !important;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}
.user-vendor a:hover {
    color: #ce0d3d !important;
    text-decoration: none;
}
.dashboard-dropdown-menu {
    position: absolute !important;
    top: 47px !important;
    left: 40px;
    border-radius: 0px 0px 3px 3px;
    border: 1px solid rgb(235, 235, 235);
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 4px 66px 4px 20px;
    clear: both;
    font-weight: 400;
    color: #626262;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}
.dropdown-toggle::after {
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: 6px;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
/* .navbar-expand-lg {
    position: relative;
    top: 0px;
    z-index: 8;
    width: 100%;
} */
.dropdown-menu {
    display: block;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: #0079df !important;
    background-color: #e1e1e1;
    text-decoration: none;
}.user-icon {
    height: 9px;
    display: inline-block;
    margin-right: 12px;
}
.bg-green{
  background: linear-gradient(to bottom right, #07AEAE, #3CC84A);
  border-color: #07AEAE;
}
@media (max-width: 1400px) {
  .header-logo{
    width: 197px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 14px;
  }
  .v-application ul.navbar-nav{
    padding-left: 5px;
  }
  #app{
    margin-top: 40px;
  }

}
@media (max-width: 991px) {
  .more ul{
    display: block;
    position: relative;
  }
  .more ul li a{
    text-align: left;
  }
}

</style>