<template>
  <div class="dashboard-content">
    <div class="container pt-7">
      <div class="card">
        <div class="card-header">Earnings</div>
        <div class="card-body">
          <h5>Total earnings : ${{earnings.total}}</h5>
          <h5>Pending payout : ${{earnings.pending}}</h5>
          <div class="py-5">
            <a href="" class="btn btn-outline-violate btn-xs mr10" v-on:click.prevent="requestWithdraw()" v-if="earnings.pending>0">Request Withdraw</a>
          </div>
        </div>
      </div>
      <h4>Payout Transaction History</h4>
      <div class="card request-list-table table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>payment Status</th>
            </tr>
          </thead>
          <tbody v-if="transactions.length">
            <tr v-for="(transaction,index) in transactions" v-bind:key="index">
              <td>{{transaction.date}}</td>
              <td>{{transaction.amount}}</td>
              <td :class="(transaction.status == 1)?'text-success':'text-danger'">{{(transaction.status==1)?'Paid':'Pending'}}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td></td>
              <td class="empty-data">No payment history</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Payment request sent to admin.</div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name:'student-chatroom',
  data(){
    return {
      earnings:{
        total:0,
        pending:0,
      },
      request:{
        amount:null,
        currency:null
      },
      // transactions:[
      //   {date:'12 March 2021', amount:100, status:0},
      //   {date:'10 April 2021', amount:100, status:1},
      //   {date:'1 Feb 2021', amount:100, status:1},
      //   {date:'5 Jan 2021', amount:100, status:1},
      // ]
      transactions:[]
    }
  },
  beforeMount(){
    let tutor_token = this.$cookies.get("tution-tutor").token;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutorearnings',{headers:{"Authorization":'bearer '+tutor_token}}).then(res => {
      if(res.data.message == 'Earning retrieved successfully '){
        this.earnings.total = res.data.data.totalEarning;
        this.earnings.pending = res.data.data.pendingEarning;
        this.transactions = res.data.data.history;
        if(this.earnings.pending){
          this.request.amount = this.earnings.pending;
          this.request.currency = '$'
        }
      }
    })
  },
  methods:{
    requestWithdraw(){
      let tutor_token = this.$cookies.get("tution-tutor").token;
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'requestpayment',this.request,{headers:{"Authorization":'bearer '+tutor_token}}).then(res => {
        if(res.data.message == 'Tutor payment request submitting successfully '){
          this.openModal("success");
          setTimeout(() => {
            this.$refs["success"].hide();
          },2000)
        }
      })
    }
  }
}
</script>
<style scoped>
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.card-body{
  padding: 20px !important;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.btn-outline-violate{
  color: #9047fb;
}
.btn-outline-violate:hover{
  color: #fbfbfb;
}
.table {
    font-size: 14px;
    margin-bottom:0px;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    color: #6f6d72;
}
.request-list-table table th {
    background-color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252328;
}
.table thead th {
    vertical-align: bottom;
}
.table td, .table th {
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
.request-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
</style>