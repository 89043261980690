<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <p>Fromyourtutor does not discriminate on the basis of race, color, national and ethnic origin in administration of its educational policies, admissions policies, and other school administered programs.</p>
        </div>
      </div>
    </div>
    <div class="social-media-section">
      <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12">
              <h5 class="text-white mb-0 mt-2">Would you like to connect with us</h5>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 text-right">
              <div class="social-icons">

                <a href="https://www.instagram.com/fromyourtutor/" target="_blank" class="icon-square"><i class="fab fa-instagram"></i></a>
                <a href="https://t.me/fromyourtutor" target="_blank" class="icon-square"><i class="fab fa-telegram"></i></a>
                <a href="https://www.facebook.com/FromyourtutorCom-102939542177603/" target="_blank" class="icon-square"><i class="fab fa-facebook-f"></i></a>
                <a href="https://twitter.com/fromyourstutor"  target="_blank" class="icon-square"><i class="fab fa-twitter"></i> </a>
                <a href="https://www.youtube.com/@alkhairacademy_sos" class="icon-square"><i class="fab fa-youtube"></i></a>
                
              </div>
            </div>
        </div>
        </div>
    </div>
    <div class="footer-section">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <!-- footer-widget -->
            <div class="footer-widget">
              <a href="#"><img src="../assets/images/footer-logo.png" alt="" class="mb-8" style="pointer-events:none;"></a>
              <p class="mb10">We are an educational support service platform dedicated to upholding moral values and ensuring that all students receive their full rights upon them.
<br><a href="/about-us">Read more..</a><br>
<a href="/about-us"><img src="../assets/images/iso.svg" alt=""  style="width:100px; margin-top: 10px;"> <img src="../assets/images/accredition.png" alt=""  style="width:190px; margin-top: 10px; margin-left: 20px;"><img src="../assets/images/wfmh.png" alt=""  style="width:110px; margin-top: 10px; margin-left: 20px;"></a></p>
            </div>
          </div>
          <!-- /.footer-widget -->
          <!-- footer-widget -->
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
            <div class="footer-widget">
              <h3 class="widget-title">
                Contact Address
              </h3>
              <!-- <p class="d-flex"><i class="fas fa-fw fa-map-marker-alt text-default pr-3 pt-1"></i>Karakurissi , Mannarkkad, Palakkad, Kerala, India</p> -->
              <p class="mb0 d-flex"><i class="fas fa-fw fa-phone-volume text-default pr-3 pt-1"></i>+44 7984 559199<br> Director : +1(246)8283460</p>
              <p class="mb0 d-flex"><i class="fas fa-fw fa-envelope text-default pr-3 pt-1 "></i>admin@fromyourtutor.com</p>
            </div>
          </div>
          <!-- /.footer-widget -->
          <!-- footer-widget -->
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="footer-widget">
              <h3 class="widget-title">
                About Company
              </h3>
              <ul class="listnone">
                <li><a href="/about-us">About us</a></li>
                <li><a href="/contact-us">Contact us</a></li>
                <li><a href="/amccmembers">AMCC Members</a></li>
                <li><a href="/staff">Staff Directory</a></li>
                <li><a href="/terms">Terms &amp; Conditions</a></li>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/refund-policy">Refund Policy</a></li>
                <li><a href="/faq">FAQ</a></li>
                
              </ul>
            </div>
          </div>
          <!-- /.footer-widget -->
          <!-- /.footer-widget -->
          <!-- <div class="col-xl-3 col-lg-3 col-md-12 col-sm-6 col-12">
            <div class="footer-widget newsletter-block">
              <h3 class="widget-title">
                Subscribe to newsletter
              </h3>
              <form>
                <div class="form-group" style="margin-bottom:1rem;">
                  <label for="email" class="sr-only"></label>
                  <input type="email" class="form-control" id="email" aria-describedby="email" placeholder="Enter Email Address">
                </div>
              </form>
              <a href="#" class="btn btn-default btn-primary text-white">Subscribe</a>
            </div>
          </div> -->
          <!-- /.footer-widget -->
        </div>
      </div>
    </div>
    <a href="https://wa.me/+12468283460" target="_blank" class="whatsappw"><img src="../assets/images/WhatsAppButtonGreenLarge.svg" alt="" width="100" ></a>
    <div class="copyright-footer">
      <div class="container pt-0 pb-0">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right">
            <p>© {{ new Date().getFullYear() }} fromyourtutor.com All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App-footer'
}
</script>

<style scoped>
.whatsappw{
  display: block;
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 1000;
}
.whatsappw img{
  width: 210px;
}
.social-media-section{
    background-color: #323232;
    padding-bottom: 15px;
}
.social-media-section a.icon-square{
  color: #fff;
  background: #ce0d3d;
}
.footer-section{
  background-color: #1b1b1b;
    padding-top: 50px;
    padding-bottom: 40px;
    color: #98969b;
    font-size: 14px;
}
.footer-widget {
    margin-bottom: 40px;
}
.footer-widget .widget-title {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-bottom: 0px;
    padding: 0px;
}
.copyright-footer{
    background-color: #323232;
    color: #98969b;
    font-size: 12px;
}
.listnone {
    list-style: none;
    padding: 0px;
}
.footer-widget ul li a {
    color: #98969b;
    line-height: 28px;
    text-decoration: none;
}
.footer-widget ul li a:hover {
    color: #0079df;
}
.footer-widget.newsletter-block .form-control {
    background-color: #323232;
    border: transparent;
    color: #817f85;
}
.footer-widget .widget-title[data-v-40ab164b] {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-bottom: 0px;
    padding: 0px;
}
.form-control {
    display: block;
    width: 100%;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #252328;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cfced0;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
p:last-child {
    margin: 0px;
}
.icon-square {
    text-align: center;
    background-color: #302d34;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    display: inline-block;
    color: #7f7c7c;
    padding: 11px 12px;
    line-height: 1.3;
    font-size: 14px;
    margin-left:5px;
}
.icon-square:hover {
    background-color: #0079df;
    color: #fff;
}
.text-default {
    color: #0079df !important;
}
.space-medium {
    padding-top: 80px;
    padding-bottom: 80px;
}
</style>