<template>
  <v-app :class="{'mt-0': $route.meta.margin == true}">
    <NotificationBox/>
    <div v-if="$route.meta.adminHeader||$route.meta.studentHeader||$route.meta.tutorHeader">
      <AdminHeader v-if="$route.meta.adminHeader"/>
      <StudentHeader v-if="$route.meta.studentHeader"/>
      <TutorHeader v-if="$route.meta.tutorHeader"/>
      <div class="container-fluid page-body-wrapper">
        <AdminSidebar v-if="$route.meta.adminSidebar"/>
        <StudentSidebar v-if="$route.meta.studentSidebar"/>
        <TutorSidebar v-if="$route.meta.tutorSidebar"/>
        <router-view/>
      </div>
    </div>
    <div id="app" v-else-if="$route.path != '/login' && $route.path != '/register' && $route.path != '/student-registration' && $route.path != '/tutor_login' && $route.path != '/admin' && $route.path != '/payment' &&$route.path != '/donate' &&  $route.path != '/chat-pool'">
      <Header :student="student" :tutor="tutor" :admin="admin"/>
      <Subheader v-if="this.$route.meta.subhead"/>
      <router-view/>
      <Footer/>
    </div>
    <router-view v-else/>
  </v-app>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Subheader from '@/components/Sub-Header'
import AdminHeader from '@/components/admin/Header.vue'
import AdminSidebar from '@/components/admin/Sidebar.vue'
import StudentHeader from '@/components/student/Header.vue'
import StudentSidebar from '@/components/student/Sidebar.vue'
import TutorHeader from '@/components/tutor/Header.vue'
import TutorSidebar from '@/components/tutor/Sidebar.vue'
import axios from 'axios'
import NotificationBox from '@/components/dist/Notification.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Subheader,
    AdminHeader,
    AdminSidebar, 
    StudentHeader,
    StudentSidebar,
    TutorHeader,
    TutorSidebar,
    NotificationBox
  },
  data(){
    return {
      subHead: false,
      student:null,
      tutor:null,
      admin:null,
      token:null,
      remember_token:null,
    }
  },
  beforeMount(){
    this.student = this.$cookies.get("tution-student")?this.$cookies.get("tution-student"):null;
    this.tutor = this.$cookies.get("tution-tutor")?this.$cookies.get("tution-tutor"):null;
    this.admin = this.$cookies.get("tution-admin")?this.$cookies.get("tution-admin"):null;
  },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt();
  },
  watch:{
    $route (){
      this.student = this.$cookies.get("tution-student")?this.$cookies.get("tution-student"):null;
      this.tutor = this.$cookies.get("tution-tutor")?this.$cookies.get("tution-tutor"):null;
      this.admin = this.$cookies.get("tution-admin")?this.$cookies.get("tution-admin"):null;
    }
  },
  async mounted() {
    window.addEventListener('keyup', function(e) {
      if (e.key == 'PrintScreen') {
        navigator.clipboard.writeText('');
        alert('Screenshots disabled!');
        return false;
      }
    });
    window.addEventListener('keydown', (e) => {
      if(e.ctrlKey && e.key == 'p') {
          alert('This section is not allowed to print or export to PDF');
          e.cancelBubble = true;
          e.preventDefault();
          e.stopImmediatePropagation();
      }
    });
    window.addEventListener('drag', (e) => {
      console.log(e);
    });

    await this.$OneSignal.getUserId().then(userId=>{
      this.remember_token = userId;
    });

    if(this.student || this.tutor || this.admin){
      this.token = this.student?this.$cookies.get("tution-student").token:(this.tutor?this.$cookies.get("tution-tutor").token:(this.admin?this.$cookies.get("tution-admin").token:null));
      console.log(this.$OneSignal);

      this.$OneSignal.getNotificationPermission(res=>{
        if(res == 'granted'){
          let data = {'remember_token':this.remember_token};
          axios.post(process.env.VUE_APP_TUTION_SITE_API+'/update/token',data,{headers:{"Authorization":'Bearer '+this.token}}).then(res=>{
            console.log('jhsdgfjhdsg',res);
          })
        }else{
          this.$OneSignal.showNativePrompt(function(isSubscribed) {
            console.log("The user's subscription state is now:", isSubscribed);
            if(isSubscribed){
              let data = {'remember_token':this.remember_token};
              axios.post(process.env.VUE_APP_TUTION_SITE_API+'/update/token',data,{headers:{"Authorization":'Bearer '+this.token}}).then(res=>{
                console.log('jhsdgfjhdsg',res);
              })
            }
          })
        }
      })
    }else{
          console.log('messaging');
    }
  },
  methods:{
    stopPrntScr(){
      var inpFld = document.createElement("input");
          inpFld.setAttribute("value", ".");
          inpFld.setAttribute("width", "0");
          inpFld.style.height = "0px";
          inpFld.style.width = "0px";
          inpFld.style.border = "0px";
          document.body.appendChild(inpFld);
          inpFld.select();
          document.execCommand("copy");
          inpFld.remove(inpFld);
    },
    AccessClipboardData() {
          try {
              window.clipboardData.setData('text', "Access   Restricted");
          } catch (err) {
            console.log(err);
          }
    }
  }
}
</script>

<style>
#app {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 44px;
}
h1, h2, h3, h4, h5, h6 {
    color: #252328;
    margin: 0px 0px 15px 0px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}
img{
  width: auto;
}
body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #6f6d72;
    background: #f6f5f7 !important;
    /* -webkit-font-smoothing: antialiased; */
    text-rendering: optimizeLegibility;
    font-weight: 400;
}
.theme--light.v-application {
    background: #f6f5f7 !important;
    color: #6f6d72 !important;
}
@media (max-width: 1400px) {
  #app {
    margin-top: 40px;
  }
}
</style>
