<template>
  <div class="study_materials">
    <div class="filter-form">
      <div class="container">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12" style="padding-top:19px !important;">
              <v-autocomplete v-model="filter_subject" :items="subjects" item-text="text" item-value="value" placeholder="Select Subject" clearable outlined></v-autocomplete>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
              <button class="btn btn-default btn-block w-100" v-on:click.prevent="searchSubject()">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="certification">
              <p><i class="fa fa-quote-left"></i> Al-hamdu lillah I can access it and I'm working
                my way through each one, very impressed so
                far, *may Allah make these resources of great
                benefit*.<i class="fa fa-quote-right"></i> </p>
                <p><i class="fa fa-quote-left"></i> Allahumma Bärik I found the content to be
                excellent and very well thought out and put
                together with the focus being to nurture
                children upon the true fitrah! Best of all it is
                all correct and authentic. May Allah bless
                all those involved for their efforts and
                hardwork. in shã Alläh I will continue to
                look through the rest as you upload them
                to the drive and provide any feedback. <i class="fa fa-quote-right"></i> </p>
                <p>Abu Innaya Seif hafidhahullah 's comments for our Kg Islamic studies Curriculum</p>
            </div>
          </div>
        </div>
        <div class="row" v-if="books.length&&!loading">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" v-for="book in books" :key="book.id">
            <div class="vendor-thumbnail">
              <div class="vendor-img zoomimg" style="height:250px;">
                <a :href="'/study_materials/'+book.title+'?id='+book.id"> 
                  <img :src="book.thumbnail.split(' ')[0]" alt="" class="img-fluid" v-if="book.thumbnail" style="pointer-events:none;">
                  <img src="../assets/images/book.jpg" alt="" class="img-fluid" v-if="!book.thumbnail" style="pointer-events:none;">
                </a>
              </div>
              <div class="vendor-content">
                <h2 class="vendor-title" v-if="book.price.startsWith('$')">{{book.price}}</h2>
                <h2 class="vendor-title" v-if="!book.price.startsWith('$')">${{book.price}}</h2>
                <h3 class="vendor-title">{{book.title.slice(0,20)}}</h3>
                <p class="vendor-address">Type: {{book.type}} | Format: {{book.format}}</p>
                <p><a href="" @click.prevent="addToCart(book)" class="btn btn-success btn-sm" v-if="!book.cart">Add to Cart</a></p>
                <p>
                  <a href="" class="btn btn-secondary text-white btn-sm" v-if="book.cart">  Added  </a>
                  <a href="/cart" class="btn btn-success btn-sm ml-1" v-if="book.cart">Go to cart</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else-if="!books.length&&!loading">
          <div class="col-lg-12 col-md-12 text-center mb-15 empty-data">
            No materials available
          </div>
        </div>
        <div class="dashboard-vendor-list" style="margin-top:75px;text-align:center;" v-else>
          <pulseLoader :loading="loading" color="#d51439" size="100"></pulseLoader>
        </div>
        <div class="row" v-if="isLoadmore">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="pagination justify-content-center">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item active"><a class="page-link" v-on:click.prevent="loadmore()">Load More</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="text-center shopCopyright">Copyright 2021 Fromyourtutor.com. All Rights Reserved <br>
              No part of this may be reproduced in any language,
              printed in any form or any electronic or mechanical
              means including but not limited to photocopying,
              recording, or otherwise, without specific verbal or
              written consent from Fromyourtutor.Com
              <br>
              Contact us at:
              info@fromyourtutor.com
              admin@fromyourtutor.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import pulseLoader from '@/components/dist/Loader';
export default {
  name: "studyMaterials",
  components:{
    pulseLoader
  },
  data(){
    return {
      subjects: [],
      limit: 8 ,
      books:[],
      items:[],
      filter_subject:null,
      isLoadmore:true,
      page:1,
      user_id:null,
      loading:true,
    }
  },
  async beforeMount(){
    if(this.$cookies.get('cart-session-key')&&this.$cookies.get('cart-session-token')){
      console.log('');
    }else{
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/cart',{},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.Message == 'A new cart have been created for you!'){
          this.$cookies.set('cart-session-key',res.data.cartKey);
          this.$cookies.set('cart-session-token',res.data.cartToken);
        }
      })
    }

    if(this.$cookies.get('cart-session-key')&&this.$cookies.get('cart-session-token')){
      let cartKey = this.$cookies.get('cart-session-key');
      let cartToken = this.$cookies.get('cart-session-token');
        if(this.$cookies.get('tution-student')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-student').token}}).then(res => {
            if(res.data.message == 'Student retrieved successfully'){
              let student = res.data.data.student;
              this.user_id = student.user.user_id;
            }
          });
        }else if(this.$cookies.get('tution-tutor')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-tutor').token}}).then(res => {
            if(res.data.message == 'User found'){
              let tutor = res.data.data.users;
              this.user_id = tutor.user_id;
            }
          });
        }
        
      await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/getcart',{cartKey:cartKey,cartToken:cartToken,user_id:this.user_id},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message == 'Product listed successfully'){
          res.data.Items.forEach(item => {
            if(item.is_active == 'Y'){
              this.items.push(item)
            }
          });
        }
      });
    }else{
      this.items = [];
    }
    this.getMaterials();
    this.getSubjects();
  },
  methods:{
    getSubjects(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          if(subject.is_active == 'Y'){
            this.subjects.push({value:subject.subject_id, text:subject.subject_name});
          }
        })
      })
    },
    getMaterials(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/materials',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        this.loading = false;
        if(res.data.products.data.length > 0){
          res.data.products.data.forEach(product => {
            if(product.is_active == 'Y' && product.uploaded_file){
              let flag = false;
              if(this.items.length > 0){
                this.items.forEach((item,index) => {
                  if(product.product_id == item.product_id){
                    flag = true;
                    this.items.splice(index,1)
                  }
                })
                if(flag == true){
                    this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:true})
                }else{
                    this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
                }
              }else{
                this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
              }
            }
          })
        }else{
          this.books = [];
        }

        if(res.data.products.total<=20){
          this.isLoadmore = false;
        }else{
          this.isLoadmore = true
        }
      });
    },
    searchSubject(){
      this.books = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/materials?subject='+this.filter_subject,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.products.data.length > 0 ){
          res.data.products.data.forEach(product => {
            if(product.is_active == 'Y' && product.uploaded_file){
              let flag = false;
              if(this.items.length > 0){
                this.items.forEach(item => {
                  if(product.product_id == item.product_id){
                    flag = true;
                  }
                })
                if(flag == true){
                    this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:true})
                }else{
                    this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
                }
              }else{
                this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
              }
            }
          })
        }else{
          this.books = [];
        }
      })
    },
    loadmore(){
      this.limit += this.limit;
      // this.books = [];
      this.page++;
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/materials?page='+this.page,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.products.data.length > 0 ){
          res.data.products.data.forEach(product => {
            if(product.is_active == 'Y'){
              if(this.items.length > 0){
                this.items.forEach(item => {
                  if(product.product_id == item.productID){
                    this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:true})
                  }else{
                    this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
                  }
                })     
              }else{
                this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
              }   
            }
          })
        }
        if(res.data.products.total<=this.page*20){
          this.isLoadmore = false;
        }else{
          this.isLoadmore = true;
        }
      });
    },
    addToCart(product){
        let cartKey = this.$cookies.get('cart-session-key');
        let cartToken = this.$cookies.get('cart-session-token');

        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/addcarts',{productID:product.id,quantity:1,cartKey:cartKey,cartId:cartToken,user:this.user_id,item_type:2},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.message == 'Product added to Cart  successfully' || res.data.message == 'The Cart was updated with the given product information successfully'){
            this.$router.push('/cart');
          }
        })
      }
      // else{
      //   // this.$cookies.set("previousURL", {location: window.location.href, path:'/study_materials'});
      //   // this.$router.push('/login');
      //   this.$router.replace({name:'student-login', params:{return:true}});
      // }
    // }
  }
}
</script>

<style scoped>
button.btn {
    font-size: 13px;
    height: 50px;
    font-weight: 400;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.zoomimg {
    position: relative;
    overflow: hidden;
}
.vendor-content {
    padding: 16px 16px 12px 16px;
    margin-bottom: 6px;
}
a {
    color: #252328;
    -webkit-font-smoothing: antialiased;
}
.vendor-img img {
    width: 100%;
}
.zoomimg img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.vendor-title {
    font-size: 20px;
    margin: 0px 0px 5px 0px;
}
.vendor-address {
    font-size: 12px;
    font-weight: 400;
    color: #6f6d72;
    line-height: 18px;
}
p {
    margin: 0px 0px 20px 0px;
    -webkit-font-smoothing: antialiased;
}
.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 1.5;
}
.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.zoomimg:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>