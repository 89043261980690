<template>
  <section class="register-section">
    <div class="register-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <v-form class="md-float-material form-material nregform" ref="form" lazy-validation v-model="valid">
              <div class="auth-box card">
                <div class="card-block">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="text-center" style="margin-bottom:15px !important;"><a href="/"><img src="../../assets/images/logo.png" style="margin-bottom: 5px;"></a><br>Student Register </h5>
                    </div>
                  </div> 
                </div>
              </div>
              <div class="auth-box card">
                <div class="card-block">
                  <div class="name">
                    <v-text-field type="text" v-model="register.name" label="Name" :rules="[v => !!v || 'Enter valid name']"><template #label>Name<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                  <div class="email">
                    <v-text-field type="text" v-model="register.email" :rules="emailRules" :error-messages="(auth_error)?auth_msg:null" @keydown.space.prevent><template #label>Student Gmail<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                  <div class="gender">
                    <v-autocomplete  v-model="register.gender" label="Gender" :items="gender" item-text="text" item-value="value"></v-autocomplete>
                  </div>
                  <div class="age">
                    <v-text-field type="text" v-model="register.age" label="Student Age"></v-text-field>
                  </div>
                </div>
              </div>
              <div class="auth-box card">
                <div class="card-block">
                  <div class="email">
                    <v-text-field type="text" v-model="register.parent_email" label="Parent Gmail"></v-text-field>
                  </div>
                  <div class="parentname">
                    <v-text-field type="text" v-model="register.parent_name" label="Mother's Name and Father's Name"></v-text-field>
                  </div>
                  <div class="course">
                    <v-autocomplete  v-model="register.course_id" label="Course" :items="courses" item-text="text" item-value="value"></v-autocomplete>
                  </div>
                  <div class="enrollment-info">
                    <label for="textarea" class="col-form-label">Course enrollment details for which you are registering ( time/days/time zone) *</label>
                    <v-textarea type="text" v-model="register.enrollment_info"></v-textarea>
                  </div>
                  <div class="fee-info">
                    <label for="textarea" class="col-form-label">Enter the Fees for a month you are supposed to pay or already paid for the course:</label>
                    <v-text-field type="text" v-model="register.fees"></v-text-field>
                  </div>
                </div>
              </div>
              <div class="auth-box card">
                <div class="card-block">
                  <div class="country">
                    <v-autocomplete  v-model="student.country" :items="countries" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" @input="getRegions"><template #label>Country<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                  </div>
                   <div class="region">
                    <v-autocomplete  v-model="register.region_id" label="Region" :items="regions" item-text="text" item-value="value"></v-autocomplete>
                  </div>
                  <div class="mobile">
                    <div class="row">
                      <div class="col-lg-12 pb-0">
                        <label for="textarea" class="col-form-label p-0">Mobile Number</label>
                      </div>
                    </div>
                    <div class="row mt-0">
                      <div class="col-lg-5"><v-autocomplete v-model="register.country_code" label="Country code" :items="codes" item-text="text" item-value="value"  @input="getCountry"></v-autocomplete></div>
                      <div class="col-lg-6"><v-text-field type="number" v-model="register.mobile" label="Mobile" :error-messages="(mob_error)?mob_msg:null"></v-text-field></div>
                    </div>
                  </div>
                  <div class="mobile">
                    <div class="row">
                      <div class="col-lg-12 pb-0">
                        <label for="textarea" class="col-form-label">Whatsapp Number</label>
                      </div>
                    </div>
                    <div class="row mt-0">
                      <div class="col-lg-5"><v-autocomplete v-model="register.code" label="Country code" :items="codes" item-text="text" item-value="value"></v-autocomplete></div>
                      <div class="col-lg-6"><v-text-field type="number" v-model="register.whatsapp_no" label="Whatsapp Num" :error-messages="(mob_error)?mob_msg:null"></v-text-field></div>
                    </div>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">Address</label>
                    <v-textarea type="text" v-model="register.address"></v-textarea>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <p style="color:red"><i><a href="https://tution-site.s3.ap-south-1.amazonaws.com/test/rules-of-academy.pdf" target="_blank">Please <u>download</u> and read the rules of the academy</a></i></p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <p>Have you done your payment <span style="color:red">*</span></p>
                      <v-radio-group v-model="register.payment_status" :rules="[v => !!v || 'Select option']" >
                          <v-radio label="Yes" value="Yes"></v-radio>
                          <v-radio label="No" value="No"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div class="row" v-if="register.payment_status != null">
                    <div class="col-lg-12" v-if="register.payment_status == 'No'">
                      <p>Please do the payment  <span style="color:red">*</span></p>
                      <div style="border-radius: 5px; padding:0px 10px 10px 10px; background: rgb(238, 238, 238) none repeat scroll 0% 0%;">
                        <div class="row">
                          <div class="col-md-6" style="width:222px">PayPal Directly:</div>
                          <div class="col-md-6">
                            <a href="https://www.paypal.com/paypalme/fromyourtutor21" target="_blank" class="btn btn-primary btn-sm text-white" style="padding:3px 10px">Click to Pay Now</a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6" style="width:222px">Payment from India:</div>
                          <div class="col-md-6">
                            <a href="https://test.instamojo.com/@fromyourtutor/l47a636c527fb4376ac6a778bc1e28b73/" target="_blank" class="btn btn-primary btn-sm text-white" style="padding:3px 10px">Click to Pay Now</a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6" style="width:222px">Fromyourtutor's Website:</div>
                          <div class="col-md-6">
                            <a href="https://fromyourtutor.com/payment" target="_blank" class="btn btn-primary btn-sm text-white" style="padding:3px 10px">Click to Pay Now</a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6" style="width:222px">For Direct Bank Transfer:</div>
                          <div class="col-md-6">
                            contact  <a href="mailto:admin@fromyourtutor.com">admin@fromyourtutor.com</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="register.payment_status != null">
                    <div class="col-lg-12">
                      <p>Payment done via  <span style="color:red">*</span></p>
                      <v-radio-group v-model="register.payment" :rules="[v => !!v || 'Select payment method']">
                        <v-radio label="PayPal Directly" :value="1"></v-radio>
                        <v-radio label="Fromyourtutor's Website" :value="2"></v-radio>
                        <v-radio label="Bank Account" :value="3"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12"> 
                      <div class="reciept">
                        <label for="formFile" class="form-label">Submit your fees screenshot here <span style="color:red">*</span></label>
                        <v-file-input accept="image/*" v-model="register.uploaded_screenshot" :rules="[v => !!v || 'Upload screenshot']" label="" placeholder="Upload"></v-file-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12"> 
                      <b-button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-if="!loading"  v-on:click.prevent="submitRegisterForm">Submit Application</b-button>
                      <b-button type="button"  class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-if="loading"><i class="fa fa-spinner fa-spin"></i></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Registration successful</div>
    </b-modal>
  </section>
</template>
<script src="https://js.instamojo.com/v1/checkout.js"></script>
<script>
  function onButtonClick() {
    Instamojo.open('http://www.instamojo.com/@ayushya');
  }
</script>  
<script>
import axios from "axios";
import AWS from "aws-sdk";
export default {
  name:'student-register-form',
  data(){
    return{
      valid: true,
      can_register:0,
      countries: [],
      courses:[],
      codes:[],
      regions:[],
      student:{
        code:null,
        mobile:null,
        country:null
      },
      register: {
        name: null,
        email: null,
        mobile: null,
        gender: null,
        country_id: null,
        region_id: null,
        course_id: null,
        country_code:null,
        parent_email: null,
        parent_name:null,
        age:null,
        details:null,
        fees:null,
        code:null,
        whatsapp_no:null,
        address:null,
        payment:null,
        screenshot:null,
        uploaded_screenshot:null,
        payment_status:null,
      },
      auth_error: false,
      auth_msg: null,
      gender:[
        {value:1, text:'Male'},
        {value:2, text:'Female'},
        {value:3, text:'Other'}
      ],
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      passwordConfirmationRules:[
        v => !!v || 'Enter valid password',
        v => this.register.password == v || 'Password not match'
      ],
      mob_error:false,
      mob_msg:'',
      loading:false,
      email_data : {
        name: null,
        country:null,
        course:null
      }
    }
  },
  beforeMount(){
    this.getCountries();
    this.getCourses();
  },
  methods: {
    openModal: function (type = "") {
      this.open = (type == "")? this.open : type;
      if (this.open == "success") {
        this.$refs["success"].show();
      }
    },
    getCourses(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          if(subject.is_active == 'Y'){
            this.courses.push({value:subject.subject_id,text:subject.subject_name});
          }
        })
      });
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode})
        })
      })
    },
    getRegions(){
      this.register.country_id = this.student.country;
      this.countries.forEach(country => {
        if(country.value == this.student.country){
          this.register.code = {value:country.code, text:country.short+ '-' + country.code};
          this.register.country_code = {value:country.code, text:country.short+ '-' + country.code};
        }
      });
      this.listRegions(this.student.country);
    },
    getCountry(){
      this.countries.forEach(country => {
        if(country.code == this.student.code){
          this.student.country = {value:country.value, text:country.text};
        }
      })
      this.register.country_id = this.student.country.value;
      this.listRegions(this.student.country.value);
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    submitRegisterForm(e){
      e.preventDefault();
      this.loading = true;
      this.valid = this.$refs.form.validate();
      this.auth_msg = null;
      this.auth_error = false;
      if(this.valid == true){
        this.register.country_code = typeof this.register.country_code === 'object'?this.register.country_code.value:this.register.country_code;
        this.register.code = typeof this.register.code === 'object'?this.register.code.value:this.register.code;
        this.email_data.name = this.register.name;
        this.countries.forEach(country=>{
          if(country.value === this.register.country_id){
            this.email_data.country = country.text;
          }
        });
        if(this.register.course_id){
          this.courses.forEach(course=>{
            if(course.value === this.register.course_id){
              this.email_data.course = course.text;
            }
          })
        }

        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });
        let s3BucketName = "tution-site"; 
        var screenshot = new Blob([this.register.uploaded_screenshot], { type: "application/img" });
        var extension = this.register.uploaded_screenshot.name.split('.')[this.register.uploaded_screenshot.name.split('.').length-1]
        var d = new Date();
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
        var timestamp = Math.floor(Date.now() / 1000);

        // if (ceritificate.size < 20000000) {
        var s3obj = new AWS.S3({
          params: {
            Bucket: s3BucketName,
            Key:
              'student/payment/'+ current_date+"/"+this.register.name + timestamp + "."+extension,
            ACL: "public-read",
          },
        });
        var upload = s3obj.upload({ Body: screenshot });
        upload.on("httpUploadProgress", function (evt) {
          // uploadStatus == 0;
          //checkConnetionOffnOn(upload);
          var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
          console.log(per_uploaded);55/9
        })
        .send((err, data) => {
          this.register.screenshot = data.Location;
          var self = this;
          axios.post(process.env.VUE_APP_TUTION_SITE_API+'/register/student',this.register,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
            if(res.data.status != "error"){
              this.openModal("success");
                  var email_params = {
                    Destination: { /* required */
                      // CcAddresses: [
                      //   self.student.user.email,
                      //   /* more items */
                      // ],
                      ToAddresses: [
                        // this.register.email,
                        'admin@fromyourtutor.com'
                        /* more items */
                      ]
                    },
                    Message: { /* required */
                      Body: { /* required */
                        Html: {
                          Charset: "UTF-8",
                          Data: '<table style="width: 600px; margin: 0 auto;" width="600"><tr><td style="text-align: center;"><br><img src="https://tution-site.s3.ap-south-1.amazonaws.com/test/logoalkhair.png" alt="logo" width="270"><br></td></tr><tr><td><br><table cellpadding="10" style="background:#fff; border-radius: 30px; width:560px; margin:0 auto; "><tr><td style="font-family: arial; font-size:18px; text-align: center; color: #086e00;"><br>New student registered</td></tr><tr><td style="font-family: arial; font-size:20px; text-align: center; color: #000;">Student Name: <b>'+self.email_data.name+'</b><br>Subject: <b>'+((self.email_data.course)?self.email_data.course:'Not Mentioned')+'</b><br>Country: <b>'+self.email_data.country+'</b></td></tr><tr><td style="font-family: arial; font-size:16px; text-align: center; color: #000; line-height: 28px;"><a href="https://fromyourtutor.com/tutn-auth/students" target="_blank" style="color: #fff; padding: 15px; font-size:16px; background:#ce004b; border-radius:5px; text-decoration: none;"><strong>View Full Details</strong></a><br><br></td></tr><tr><td style="font-family: arial; font-size:16px; text-align: center; color: #000; line-height: 28px;"><br></td></tr></table></td></tr></table>'
                        },
                        // Text: {
                        //   Charset: "UTF-8",
                        //   Data: 'Your order has been successfully placed.',
                        // }
                      },
                      Subject: {
                        Charset: 'UTF-8',
                        Data: 'New Student Registered'
                      }
                      },
                    Source: 'info@fromyourtutor.com', /* required */
                    ReplyToAddresses: [
                      'info@fromyourtutor.com',
                      /* more items */
                    ],
                  };

                  // Create the promise and SES service object
                  var sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(email_params).promise();

                  // Handle promise's fulfilled/rejected states
                  sendPromise.then(
                    function(data) {
                      if(data.MessageId){
                        console.log('Email send');
                      }
                    }).catch(
                    function(err) {
                      console.error(err, err.stack);
                  });
              setTimeout(() => {
                this.$refs["success"].hide();
                this.$router.push('/login');
              },2000)
            }else{
              this.loading = false;
            }
            // let student = { token: res.access.token, name:this.register.name}
            // this.$cookies.set("tution-student",student);
            // location.reload();
            // this.$router.push('/student/dashboard');
          }).catch((err) => {
            this.loading = false;
            if(err.response){
              if(err.response.data.email){
                this.auth_error = true;
                this.auth_msg = 'Email is already registered with us'
              }
              if(err.response.data.mobile){
                this.mob_error = true;
                this.mob_msg = 'Mobile is already registered with us'
              }
            }else{
              console.log(err);
            }
          });
        });
      }else{
        this.loading = false;
      }
    }
  } 
}
</script>
<style>
.nregform .theme--light.v-input textarea{
    border:solid 1px #ebeeed;
    min-height: 38px;
    display: block;
    padding: 10px;
}

</style>
<style scoped>
.register-section{
    background: url(../../assets/images/couple-bg-img.jpg)no-repeat;
    background-size: cover;
    height: 100%;
}
.register-block {
    margin: 30px auto;
    min-height: 93.6vh;
}
.register-block .auth-box {
    margin: 20px auto 0;
    max-width: 850px !important;
}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
}
.card .card-block {
    padding: 1.25rem;
}
h5 {
    font-size: 16px;
    line-height: 26px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn:hover {
  color: #ffa25f;
}
.btn-lg {
    font-size: 14px;
    line-height: 1.3333333;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
a:active, a:hover {
    outline: 0;
    text-decoration: none;
}
a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
a {
    color: #252328;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}
</style>