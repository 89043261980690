<template>
  <div class="dashboard-content">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
                <div class="dashboard-page-header">
                    <h3 class="dashboard-page-title">Hi, {{admin.name}}.</h3>
                    <p class="d-block">Here’s your complete details.</p>
                </div>
            </div>
        </div>
        <div class="row" v-if="status">

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="card card-summary">
                    <div class="card-body">
                        <div class="float-left">
                        <div class="summary-count">{{(status.live_classes)?status.live_classes:0}}</div>
                        <p><span class="badge-dot badge-danger"></span> Live Classes</p>

                    </div>
                      <div class="summary-icon"><i class="icon-004-chat"></i></div>
                    </div>
                    <div class="card-footer text-center"><a href="/tutn-auth/live-classes">View All</a></div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="card card-summary">
                    <div class="card-body">
                        <div class="float-left">
                        <div class="summary-count">{{(status.students)?status.students:0}}</div>
                        <p>Students Joined</p>
                    </div>
                          <div class="summary-icon"><i class="icon-051-wedding-arch"></i></div>

                    </div>
                      <div class="card-footer text-center"><a href="/tutn-auth/students">View All</a></div>
                    
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="card card-summary">
                    <div class="card-body">
                        <div class="float-left">
                        <div class="summary-count">{{(status.tutors)?status.tutors:0}}</div>
                        <p>Tutors</p>
                    </div>
                          <div class="summary-icon"><i class="icon-021-love-1"></i></div>
                    </div>
                    <div class="card-footer text-center"><a href="/tutn-auth/tutors">View All</a></div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="card card-summary">
                    <div class="card-body">
                        <div class="float-left">
                        <div class="summary-count">{{(status.complete_classes)?status.complete_classes:0}}</div>
                        <p>Completed Classes</p>

                    </div>
                      <div class="summary-icon"><i class="icon-004-chat"></i></div>
                    </div>
                    <!-- <div class="card-footer text-center"><a href="#">View All</a></div> -->
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="card card-summary">
                    <div class="card-body">
                        <div class="float-left">
                        <div class="summary-count">{{(status.collected)?status.collected:0}}</div>
                        <p>Collected</p>

                    </div>
                      <div class="summary-icon"><i class="icon-004-chat"></i></div>
                    </div>
                    <!-- <div class="card-footer text-center"><a href="#">View All</a></div> -->
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="card card-summary">
                    <div class="card-body">
                        <div class="float-left">
                        <div class="summary-count">{{(status.sales)?status.sales:0}}</div>
                        <p>Sales</p>

                    </div>
                      <div class="summary-icon"><i class="icon-004-chat"></i></div>
                    </div>
                    <!-- <div class="card-footer text-center"><a href="#">View All</a></div> -->
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: "admin-dashboard",
  data(){
    return {
      admin:null,
      status : null
    }
  },
  beforeMount(){
      this.admin = (this.$cookies.get('tution-admin'))?this.$cookies.get('tution-admin'):null;
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/admin_dashboard',{headers:{"Authorization":'bearer '+this.admin.token}}).then(res => {
        this.status = res.data.data;
      })
  },
}
</script>
<style scoped>
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card-body {
    padding: 20px;
}
.summary-count {
    font-size: 42px;
    color: #252328;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1;
}
.badge-dot {
    border-radius: 100%;
    width: 7px;
    height: 7px;
    padding: 5px;
    display: inline-block;
    margin-right: 3px;
}
.badge-danger {
    background-color: #dc3645;
}
.summary-icon {
    font-size: 50px;
    color: #0079df;
    display: inline-block;
    float: right;
    line-height: 1.5;
}
.card-footer {
    background: #fff;
    border-top: 1px solid #e6e5e7;
    font-size: 14px;
    padding: .75rem 1.25rem;
}
.card-footer a{
  text-decoration: none;
  color: #000;
}
</style>